// CONSTANTS
export const Constants = {
  UPDATE_DATALAYER: 'UPDATE_DATALAYER_GTM',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.UPDATE_DATALAYER
  payload: Record<string, any>
}
// ACTION CALLS
export const Actions = {
  UPDATE_DATALAYER: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_DATALAYER,
    payload,
  }),
}
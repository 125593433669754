// CONSTANTS
export const Constants = {
  NAVIGATION_CUSTOM_SEARCH: 'NAVIGATION_CUSTOM_SEARCH_FLAG',
  NAVIGATION_SET: 'NAVIGATION_SET',
  NAVIGATION_SUBMIT_SEARCH: 'NAVIGATION_SUBMIT_SEARCH_QUERY',
  NAVIGATION_UPDATE_SEARCH: 'NAVIGATION_UPDATE_SEARCH_QUERY',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.NAVIGATION_SET
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.NAVIGATION_CUSTOM_SEARCH
      payload: boolean
    }
  | {
      type: typeof Constants.NAVIGATION_SUBMIT_SEARCH
    }
  | {
      type: typeof Constants.NAVIGATION_UPDATE_SEARCH
      payload: Record<string, any>
    }
// ACTION CALLS
export const Actions = {
  NAVIGATION_CUSTOM_SEARCH: (payload: boolean): ActionTypes => ({
    type: Constants.NAVIGATION_CUSTOM_SEARCH,
    payload,
  }),
  NAVIGATION_SET: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.NAVIGATION_SET,
    payload,
  }),
  NAVIGATION_SUBMIT_SEARCH: (): ActionTypes => ({
    type: Constants.NAVIGATION_SUBMIT_SEARCH,
  }),
  NAVIGATION_UPDATE_SEARCH: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.NAVIGATION_UPDATE_SEARCH,
    payload,
  }),
}
import React, { FC } from 'react'
import SALink from 'components/SALink'

import MobileConnector, { ConnectorProps as MobileConnectorProps } from 'connectors/Screen'

const ArtAdvisoryCallout: FC<MobileConnectorProps> = ({ isMobileScreenWidth }) => {
  const imageBase = 'https://d3t95n9c6zzriw.cloudfront.net/homepage/2020/aa/Hybrid-Homepage-RW-Prog'

  return (
    <div data-type='artAdvisoryWrapper'>
      <div data-type='art-advisory-content'>
        <picture data-type='image'>
          <source
            type='image/jpg'
            loading='lazy'
            media='(max-width:980px)'
            srcSet={`${imageBase}-small.jpg`}
          />
          <img
            className='lazyload'
            loading='lazy'
            width='922'
            height='693'
            data-src={`${imageBase}-large.jpg`}
            alt='Rebecca Wilson'
            itemProp='image'
          />
        </picture>
        <noscript>
          <img
            width='922'
            height='693'
            src={`${imageBase}-large.jpg`}
            alt='Rebecca Wilson'
            itemProp='image'
          />
        </noscript>
        <div data-type='callout'>
          {isMobileScreenWidth ? (
            <>
              <h3 className='defaultTypographyH3' data-type='mobile-pre-cta'>
                Find Art You Love
              </h3>
              <SALink href='https://www.saatchiart.com/artadvisory'>
                <p className='defaultTypographyCtaSmallOrMedium' data-type='callout-cta-text'>
                  WORK WITH AN ART ADVISOR
                </p>
              </SALink>
            </>
          ) : (
            <>
              <h3 className='defaultTypographyH3' data-type='desk-header'>
                Find Art You Love
              </h3>
              <div data-type='callout-info-container'>
                <p className='defaultTypographySmall' data-type='copy'>
                  “At Saatchi Art, we make it our mission to help you discover and buy from the best
                  emerging artists around the world. Whether you’re looking to discover a new
                  artist, add a statement piece to your home, or commemorate an important life
                  event, Saatchi Art is your portal to thousands of original works by today’s top
                  artists.”
                </p>
                <img
                  data-type='signature'
                  width='220'
                  height='40'
                  src='https://d3t95n9c6zzriw.cloudfront.net/homepage/2020/aa/w-sig-black.png'
                  alt="Rebecca Wilson's Signature"
                  loading='lazy'
                />
                <h6 className='defaultTypographyH6' data-type='credit'>
                  Chief Curator & VP, Art Advisory
                </h6>
                <SALink href='https://www.saatchiart.com/artadvisory'>
                  <p className='defaultTypographyCtaSmallOrMedium' data-type='callout-cta-text'>
                    WORK WITH AN ART ADVISOR
                  </p>
                </SALink>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileConnector(ArtAdvisoryCallout)

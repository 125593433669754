// CONSTANTS
export const Constants = {
  ADD_ARTWORK_TO_COLLECTION: 'ADD_ARTWORK_TO_COLLECTION',
  CREATE_COLLECTION: 'CREATE_COLLECTION',
  FETCH_COLLECTION_TOOLTIP: 'FETCH_COLLECTION_TOOLTIP',
  FETCH_COLLECTIONS_BY_ARTWORKID_TOOLTIP: 'FETCH_COLLECTIONS_BY_ARTWORKID_TOOLTIP',
  SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK: 'SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK',
  SET_COLLECTION_TOOLTIP: 'SET_COLLECTION_TOOLTIP',
  SET_COLLECTIONS_BY_ARTWORKID: 'SET_COLLECTIONS_BY_ARTWORKID',
  SET_COLLECTIONS: 'SET_COLLECTIONS',
  UPDATE_COLLECTIONS: 'UPDATE_COLLECTIONS',
  UPDATE_COLLECTION_TOOLTIP_SEARCH: 'UPDATE_COLLECTION_TOOLTIP_SEARCH',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.ADD_ARTWORK_TO_COLLECTION
      payload: number
    }
  | {
      type: typeof Constants.CREATE_COLLECTION
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.FETCH_COLLECTION_TOOLTIP
    }
  | {
      type: typeof Constants.FETCH_COLLECTIONS_BY_ARTWORKID_TOOLTIP
    }
  | {
      type: typeof Constants.SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK
      payload: number
    }
  | {
      type: typeof Constants.SET_COLLECTION_TOOLTIP
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_COLLECTIONS_BY_ARTWORKID
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_COLLECTIONS
      payload: Array<Record<string, any>>
    }
  | {
      type: typeof Constants.UPDATE_COLLECTIONS
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_COLLECTION_TOOLTIP_SEARCH
      payload: string
    }
// ACTION CALLS
export const Actions = {
  CREATE_COLLECTION: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.CREATE_COLLECTION,
    payload,
  }),
  ADD_ARTWORK_TO_COLLECTION: (payload: number): ActionTypes => ({
    type: Constants.ADD_ARTWORK_TO_COLLECTION,
    payload,
  }),
  FETCH_COLLECTIONS_BY_ARTWORKID_TOOLTIP: (): ActionTypes => ({
    type: Constants.FETCH_COLLECTIONS_BY_ARTWORKID_TOOLTIP,
  }),
  FETCH_COLLECTION_TOOLTIP: (): ActionTypes => ({
    type: Constants.FETCH_COLLECTION_TOOLTIP,
  }),
  SET_COLLECTION_TOOLTIP: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_COLLECTION_TOOLTIP,
    payload,
  }),
  SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK: (payload: number): ActionTypes => ({
    type: Constants.SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK,
    payload,
  }),
  SET_COLLECTIONS_BY_ARTWORKID: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_COLLECTIONS_BY_ARTWORKID,
    payload,
  }),
  SET_COLLECTIONS: (payload: Array<Record<string, any>>): ActionTypes => ({
    type: Constants.SET_COLLECTIONS,
    payload,
  }),
  UPDATE_COLLECTIONS: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_COLLECTIONS,
    payload,
  }),
  UPDATE_COLLECTION_TOOLTIP_SEARCH: (payload: string): ActionTypes => ({
    type: Constants.UPDATE_COLLECTION_TOOLTIP_SEARCH,
    payload,
  }),
}
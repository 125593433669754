// CONSTANTS
export const Constants = {
  DOWNLOAD_JSON_FILE: 'DOWNLOAD_JSON_FILE_CMS',
  IMPORT_JSON_FILE: 'IMPORT_JSON_FILE_CMS',
  FETCH_HOMEPAGE_ERROR: 'FETCH_HOMEPAGE_ERROR_CMS',
  FETCH_HOMEPAGE_SERVER: 'FETCH_HOMEPAGE_SERVER_CMS',
  POST_HOMEPAGE_SERVER: 'POST_HOMEPAGE_SERVER_CMS',
  SET_HOMEPAGE_LOCAL: 'SET_HOMEPAGE_LOCAL_CMS',
  SET_HOMEPAGE_SERVER: 'SET_HOMEPAGE_SERVER_CMS',
  UPDATE_HOMEPAGE_LOCAL: 'UPDATE_HOMEPAGE_LOCAL_CMS',
  FETCH_PROMOS_ERROR: 'FETCH_PROMOS_ERROR_CMS',
  FETCH_PROMOS_SERVER: 'FETCH_PROMOS_SERVER_CMS',
  POST_PROMOS_SERVER: 'POST_PROMOS_SERVER_CMS',
  SET_PROMOS_LOCAL: 'SET_PROMOS_LOCAL_CMS',
  SET_PROMOS_SERVER: 'SET_PROMOS_SERVER_CMS',
  FETCH_SHOWS_ERROR: 'FETCH_SHOWS_ERROR_CMS',
  FETCH_SHOWS_SERVER: 'FETCH_SHOWS_SERVER_CMS',
  PUT_SHOWS_SERVER: 'PUT_SHOWS_SERVER_CMS',
  SET_SHOWS_LOCAL: 'SET_SHOWS_LOCAL_CMS',
  SET_SHOWS_SERVER: 'SET_SHOWS_SERVER_CMS',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.DOWNLOAD_JSON_FILE
    }
  | {
      type: typeof Constants.IMPORT_JSON_FILE
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.FETCH_HOMEPAGE_ERROR
    }
  | {
      type: typeof Constants.FETCH_HOMEPAGE_SERVER
    }
  | {
      type: typeof Constants.POST_HOMEPAGE_SERVER
      payload: string
    }
  | {
      type: typeof Constants.SET_HOMEPAGE_LOCAL
      payload: string
    }
  | {
      type: typeof Constants.SET_HOMEPAGE_SERVER
      payload: string
    }
  | {
      type: typeof Constants.UPDATE_HOMEPAGE_LOCAL
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.FETCH_PROMOS_ERROR
    }
  | {
      type: typeof Constants.FETCH_PROMOS_SERVER
    }
  | {
      type: typeof Constants.POST_PROMOS_SERVER
      payload: string
    }
  | {
      type: typeof Constants.SET_PROMOS_LOCAL
      payload: string
    }
  | {
      type: typeof Constants.SET_PROMOS_SERVER
      payload: string
    }
  | {
      type: typeof Constants.FETCH_SHOWS_ERROR
    }
  | {
      type: typeof Constants.FETCH_SHOWS_SERVER
    }
  | {
      type: typeof Constants.PUT_SHOWS_SERVER
      payload: string
    }
  | {
      type: typeof Constants.SET_SHOWS_LOCAL
      payload: string
    }
  | {
      type: typeof Constants.SET_SHOWS_SERVER
      payload: string
    }
// ACTION CALLS
export const Actions = {
  DOWNLOAD_JSON_FILE: (): ActionTypes => ({
    type: Constants.DOWNLOAD_JSON_FILE,
  }),
  IMPORT_JSON_FILE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.IMPORT_JSON_FILE,
    payload,
  }),
  FETCH_HOMEPAGE_ERROR: (): ActionTypes => ({
    type: Constants.FETCH_HOMEPAGE_ERROR,
  }),
  FETCH_HOMEPAGE_SERVER: (): ActionTypes => ({
    type: Constants.FETCH_HOMEPAGE_SERVER,
  }),
  POST_HOMEPAGE_SERVER: (payload: string): ActionTypes => ({
    type: Constants.POST_HOMEPAGE_SERVER,
    payload,
  }),
  SET_HOMEPAGE_LOCAL: (payload: string): ActionTypes => ({
    type: Constants.SET_HOMEPAGE_LOCAL,
    payload,
  }),
  SET_HOMEPAGE_SERVER: (payload: string): ActionTypes => ({
    type: Constants.SET_HOMEPAGE_SERVER,
    payload,
  }),
  UPDATE_HOMEPAGE_LOCAL: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_HOMEPAGE_LOCAL,
    payload,
  }),
  FETCH_PROMOS_ERROR: (): ActionTypes => ({
    type: Constants.FETCH_PROMOS_ERROR,
  }),
  FETCH_PROMOS_SERVER: (): ActionTypes => ({
    type: Constants.FETCH_PROMOS_SERVER,
  }),
  POST_PROMOS_SERVER: (payload: string): ActionTypes => ({
    type: Constants.POST_PROMOS_SERVER,
    payload,
  }),
  SET_PROMOS_LOCAL: (payload: string): ActionTypes => ({
    type: Constants.SET_PROMOS_LOCAL,
    payload,
  }),
  SET_PROMOS_SERVER: (payload: string): ActionTypes => ({
    type: Constants.SET_PROMOS_SERVER,
    payload,
  }),
  FETCH_SHOWS_ERROR: (): ActionTypes => ({
    type: Constants.FETCH_SHOWS_ERROR,
  }),
  FETCH_SHOWS_SERVER: (): ActionTypes => ({
    type: Constants.FETCH_SHOWS_SERVER,
  }),
  PUT_SHOWS_SERVER: (payload: string): ActionTypes => ({
    type: Constants.PUT_SHOWS_SERVER,
    payload,
  }),
  SET_SHOWS_LOCAL: (payload: string): ActionTypes => ({
    type: Constants.SET_SHOWS_LOCAL,
    payload,
  }),
  SET_SHOWS_SERVER: (payload: string): ActionTypes => ({
    type: Constants.SET_SHOWS_SERVER,
    payload,
  }),
}
import React, { FC, useMemo } from 'react'
import { useCarousel } from 'hooks/carousel'
import Carousel from 'components/Carousel'
import valueProps from './data'
import { Dots } from '../styles'
import MobileConnector, { ConnectorProps as MobileConnectorProps } from 'connectors/Screen'

const ValueProps: FC<MobileConnectorProps> = ({ isMobileScreenWidth }) => {
  const { next, prev, index, setIndex } = useCarousel(valueProps.length)

  const desktopCarouselStyles = useMemo(
    () => ({
      display: 'flex',
      gap: '64px',
      padding: '0px 64px',
    }),
    []
  )

  const mobileCarouselStyles = useMemo(
    () => ({
      display: 'flex',
      width: '300%',
    }),
    []
  )

  return (
    <div data-type='valuePropWrapperMobile'>
      <Carousel
        onNext={next}
        onPrev={prev}
        setIndex={setIndex}
        index={index}
        carouselName='valuePropsCarousel'
        columns={1}
        carouselStyles={isMobileScreenWidth ? mobileCarouselStyles : desktopCarouselStyles}
      >
        {valueProps.map((valueProp, key) => (
          <div key={key} data-type='valuePropItem'>
            {valueProp.icon}
            <div data-type='value-prop-text-container'>
              <h5 className='defaultTypographyH5' data-type='value-prop-item-title'>
                {valueProp.title}
              </h5>
              <p className='defaultTypographyXSmall' data-type='value-prop-item-description'>
                {valueProp.description}
              </p>
            </div>
          </div>
        ))}
      </Carousel>
      <Dots
        data-type='valuePropsDots'
        index={index}
        length={valueProps.length}
        setIndex={setIndex}
      />
    </div>
  )
}

export default MobileConnector(ValueProps)

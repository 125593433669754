// CONSTANTS
export const Constants = {
  CLIENT_COMPLETE: 'CLIENT_SETUP_COMPLETE',
  CLIENT_SETUP: 'CLIENT_SETUP_START',
  NEW_CLIENT_COMPLETE: 'NEW_CLIENT_SETUP_COMPLETE',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CLIENT_COMPLETE
      payload: boolean
    }
  | {
      type: typeof Constants.CLIENT_SETUP
    }
  | {
      type: typeof Constants.NEW_CLIENT_COMPLETE
      payload: Record<string, any>
    }
// ACTION CALLS
export const Actions = {
  CLIENT_COMPLETE: (): ActionTypes => ({
    type: Constants.CLIENT_COMPLETE,
    payload: true,
  }),
  CLIENT_SETUP: (): ActionTypes => ({
    type: Constants.CLIENT_SETUP,
  }),
  NEW_CLIENT_COMPLETE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.NEW_CLIENT_COMPLETE,
    payload,
  }),
}
import type { Screen } from 'types/Screen'
import 'types/Screen'
import type { ActionTypes as Action } from 'actions/screen'
import { Constants } from 'actions/screen'
export const defaultState = {
  width: 0,
  height: 0,
  isReady: false,
}
type State = Screen
export default function page(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case Constants.UPDATE_SCREEN_SIZE:
      return { ...state, isReady: true, ...action.payload }

    case Constants.SCREEN_SIZE_READY:
      return { ...state, isReady: action.payload }

    default:
      return state
  }
}
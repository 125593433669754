import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { GeoLocation, GeoLocationMetaData } from 'selectors/geoLocation'
import { getGeoLocation, getGeoLocationMetaData } from 'selectors/geoLocation'

export type ConnectorProps = {
  geoLocation: GeoLocation
  geoLocationMetaData: GeoLocationMetaData
}

const mapStateToProps = () => createStructuredSelector({
  geoLocation: getGeoLocation,
  geoLocationMetaData: getGeoLocationMetaData,
})

const connector = connect(mapStateToProps)

export default connector

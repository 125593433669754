import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getIsIOSWebview, getPageIsReady } from 'selectors/page'
import { getSiteContext } from 'selectors/siteContext'
const mapStateToProps = createStructuredSelector({
  iOSWebview: getIsIOSWebview,
  isReady: getPageIsReady,
  siteContext: getSiteContext,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  iOSWebview: boolean
  isReady: boolean
  siteContext: string
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect
import React from 'react'
import SALink from 'components/SALink'
import { convertLocaleToDefaultCurrency } from 'components/FormatPrice/helpers'
import { ConnectorProps as LocaleConnectorProps } from 'connectors/Locale'
import LocaleConnector from 'connectors/Locale'
import LocaleCurrencySymbol from 'components/FormatPrice/LocaleCurrencySymbol'
import prices from './data'

export default LocaleConnector(
  (props: LocaleConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { selectedCurrency } = props

    return (
      <div data-type='shop-by-price-container'>
        <div data-type='shop-by-price'>
          <h3 className='defaultTypographyH3' data-type='shop-price-title'>
            Shop by Price
          </h3>
          <div data-type='shop-row'>
            {prices.map((price, index) => {
              const { min, max } = price
              const minPrice = min > 0 ? convertLocaleToDefaultCurrency(min, selectedCurrency) : min
              const maxPrice = convertLocaleToDefaultCurrency(max, selectedCurrency)

              return (
                <SALink
                  href={`/all?price=${minPrice}-${maxPrice}`}
                  key={index}
                  data-type='price-cta'
                >
                  {/* If min is > 0, show the price with currency symbol. Otherwise show 'UNDER'. */}
                  {min > 0 ? (
                    <p className='defaultTypographyCtaSmallOrMedium'>
                      <LocaleCurrencySymbol {...{ selectedCurrency }} />
                      {min} -
                    </p>
                  ) : (
                    <p className='defaultTypographyCtaSmallOrMedium'>UNDER</p>
                  )}
                  <p className='defaultTypographyCtaSmallOrMedium' style={{ paddingLeft: '5px' }}>
                    <LocaleCurrencySymbol {...{ selectedCurrency }} />
                    {max}
                  </p>
                </SALink>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
)

import React, { PureComponent } from 'react'
import _debounce from 'lodash/debounce'
import styled from 'styled-components'
import { space, display } from 'styled-system'
import CaretLeft from 'svg/caret-left.svg'
import CaretRight from 'svg/caret-right.svg'
const LRControl = styled.button`
  ${display};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease opacity;
  will-change: transform, opacity;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  :hover {
    transform: translateY(-50%) scale(1.1);
  }
  :focus {
    outline: none;
  }
  opacity: ${(props) => (props.visible === false ? 0 : 1)};
  pointer-events: ${(props) => (props.visible === false ? 'none' : 'auto')};
`
LRControl.defaultProps = {
  display: ['none', 'none', 'block'],
}
const ICON_STYLE = `
  height: 40px;
  width: auto;
`
const NextIcon = styled(CaretRight)`
  ${ICON_STYLE};
`
const PrevIcon = styled(CaretLeft)`
  ${ICON_STYLE};
`
export const Next = styled(LRControl)`
  right: 0;
`
export const Prev = styled(LRControl)`
  left: 0;
`
export const CarouselItemContainer = styled.div`
  ${space};
  margin: 0 auto;
  overflow: hidden;
`
export const CarouselItemContainerInner = styled.div`
  will-change: transform;
  white-space: nowrap;
  ${(props) => props.transition && 'transition: 0.2s ease transform'};
  > * {
    user-select: none;
    display: inline-block;
    vertical-align: middle;
  }
`
export const CarouselContainer = styled.div`
  position: relative;
  touch-action: pan-x pan-y;
  ${CarouselItemContainerInner} > * {
    ${(props) => props.columns === 1 && 'width: 100%;'};
    ${(props) =>
      props.columns === 6 &&
      `
      width: 14%;
      margin-right: 3.2%;
      &:last-child {
        margin-right: 0;
      }
    `};
  }
  ${(props) => props.dragging && '> * {pointer-events: none;}'};
`
let requestIdleCallback
let Hammer
type Props = {
  index: number
  children: React.ReactElement<React.ComponentProps<any>, any>[]
  onNext: (...args: Array<any>) => any
  onPrev: (...args: Array<any>) => any
  columns?: number
  controls?: boolean
  transition?: boolean
  draggable?: boolean
  setIndex: (index: number) => void
  limitIndex?: boolean
  // Limit index to prevent going offscreen,
  timer?: boolean
  timerDuration?: number
  carouselStyles?: Record<string, any>
}
type State = {
  indexOffset: number
  manualOffset: number
  dragging: boolean
  shouldPreventNext: boolean
  carouselStyles?: Record<string, any>
}
const Defaults = {
  controls: false,
  transition: true,
  draggable: true,
  setIndex: (index: number) => undefined,

  columns: 0,
  // 0 indicates no controlled spacing between items,
  limitIndex: false,
  interval: null,
}
export default class extends PureComponent<Props, State> {
  static defaultProps = Defaults

  constructor(props: Props) {
    super(props)
    this.state = {
      indexOffset: 0,
      manualOffset: 0,
      dragging: false,
      shouldPreventNext: true,
      carouselStyles: {}
    }
  }

  interval: (...args: Array<any>) => any = null

  componentDidMount() {
    const { timer, timerDuration } = this.props

    requestIdleCallback = window.requestIdleCallback || ((fn) => setTimeout(fn(), 0))

    // computeShouldPreventNext requires up-to-date element widths.
    // Ensure new offset widths have been calculated before calling
    // computeShouldPreventNext
    this.cacheOffsetWidths().then(this.computeShouldPreventNext)
    this.computeIndexOffset()
    this.observeDOMMutations()
    window.addEventListener('resize', this.handleResize)

    if (this.props.draggable && this.props.children.length > 1) {
      Hammer = require('hammerjs') // Throws Errors - Requires Window to be available

      this.initializeTouch()
    }

    // If timer prop is set (true),
    // increment carousel using onNext,
    // with an interval of timerDuration or 5 seconds
    if (timer) {
      this.interval = window.setInterval(() => {
        this.props.onNext()
      }, timerDuration || 5000) // Defaults to 5 second interval
    }

    this.setState(() => ({
      carouselStyles: this.props.carouselStyles
    }))
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.carouselStyles) !== JSON.stringify(this.props.carouselStyles)) {
      this.setState(() => ({
        carouselStyles: this.props.carouselStyles
      }))
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    this.computeIndexOffset(nextProps)

    if (nextProps.index !== this.props.index) {
      this.computeShouldPreventNext()
    }

    if (nextProps.draggable !== this.props.draggable) {
      const method = nextProps.draggable ? 'initializeTouch' : 'destroyTouch'
      const self: Record<string, any> = this
      self[method]()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.destroyTouch()

    if (this.DOMObserver) {
      this.DOMObserver.disconnect()
    }

    // Clear onNext timer
    window.clearInterval(this.interval)
  }

  getTotalOffsetWidthByIndex = (index: number = this.props.index) => {
    let offset = 0

    for (let i = 0; i < index; i++) {
      offset += this.offsetWidths[i]
    }

    return offset
  }
  computeShouldPreventNext = () => {
    const func = () => {}

    requestIdleCallback(() => {
      if (!this.props.limitIndex) {
        return func
      }

      const scrollContainer = this.itemsOuter

      if (!scrollContainer) {
        return func
      }

      // Left most (visible) carousel item offset
      const currentOffset = this.getTotalOffsetWidthByIndex(this.state.indexOffset)
      // Index of last item in carousel
      const maxIndex = React.Children.count(this.props.children) - 1
      // Get the distance (in pixels) of the right edge of the last carousel item
      // from the start of the carousel.
      const lastItemRightDistance =
        this.getTotalOffsetWidthByIndex(maxIndex) + this.offsetWidths[maxIndex]
      // Get the current distance (in pixels) of the right most visible point in
      // the carousel.
      const containerRightDistance = currentOffset + scrollContainer.clientWidth
      // If the right most edge of last carousel item is in view (its right most edge
      // is closer than the visible container's right most edge), indicate that a
      // next action should be prevented.
      const shouldPreventNext = lastItemRightDistance <= containerRightDistance
      this.setState(() => ({
        shouldPreventNext,
      }))
      return func
    })
  }
  DOMObserver: MutationObserver | null | undefined = null
  observeDOMMutations = () => {
    if (!window.MutationObserver || !this.itemsInner) {
      return
    }

    if (this.DOMObserver) {
      this.DOMObserver.disconnect()
    }

    const cacheOffsetsOnChildChange = _debounce((mutations: Array<MutationRecord>) => {
      const childChanged = mutations.some(
        (mutation: MutationRecord) => mutation.target !== this.itemsInner
      )

      if (childChanged) {
        this.cacheOffsetWidths()
      }
    }, 500)

    this.DOMObserver = new window.MutationObserver(cacheOffsetsOnChildChange)
    this.DOMObserver.observe(this.itemsInner, {
      childList: false,
      attributes: true,
      characterData: false,
      subtree: true,
      attributeFilter: ['style'],
    })
  }
  initializeTouch = () => {
    this.hammer = new Hammer(this.itemsWrapper, {
      recognizers: [
        [
          Hammer.Pan,
          {
            direction: Hammer.DIRECTION_HORIZONTAL,
          },
        ],
      ],
    })
    this.hammer.on('panstart', this.handlePanStart)
    this.hammer.on('panmove', this.handlePan)
    this.hammer.on('panend', this.handlePanEnd)
  }
  destroyTouch = () => {
    if (this.hammer) {
      this.hammer.destroy()
    }
  }
  handlePanStart = () => {
    this.setState({
      dragging: true,
    })
  }
  handlePan = (e: Record<string, any>) => {
    this.setState({
      manualOffset: e.deltaX,
    })
  }
  handlePanEnd = (e: Record<string, any>) => {
    const totalDistance = e.deltaX
    // Cap offsetPush
    const offsetPushMax = 180
    let offsetPush = this.offsetWidths[this.state.indexOffset] / 2

    if (offsetPush > offsetPushMax) {
      offsetPush = offsetPushMax
    }

    const indexDistance = -Math.round(totalDistance / offsetPush)
    let nextIndex = this.props.index + indexDistance

    if (this.props.children && nextIndex > this.props.children.length - 1) {
      nextIndex = this.props.children.length - 1
    } else if (nextIndex < 0) {
      nextIndex = 0
    }

    if (this.props.setIndex) {
      this.props.setIndex(nextIndex)
    }

    this.setState({
      dragging: false,
      manualOffset: 0,
      indexOffset: nextIndex,
    })
  }
  offsetWidths: Array<number> = []
  cacheOffsetWidths = (): Promise<any> =>
    new Promise((resolve) => {
      requestIdleCallback(() => {
        if (!this.itemsInner) {
          return () => {}
        }

        const oldOffsetWidths = [...this.offsetWidths]
        const offsetWidths = []
        const { children } = this.itemsInner

        for (let i = 0; i < children.length; i++) {
          const {
            width,
            paddingLeft,
            paddingRight,
            marginLeft,
            marginRight,
          } = window.getComputedStyle(children[i])
          offsetWidths.push(
            parseFloat(width) +
              parseFloat(paddingLeft) +
              parseFloat(paddingRight) +
              parseFloat(marginLeft) +
              parseFloat(marginRight)
          )
        }

        const changed =
          oldOffsetWidths.length !== offsetWidths.length ||
          oldOffsetWidths.some((item, index) => item !== offsetWidths[index])

        if (changed) {
          this.offsetWidths = offsetWidths.reverse() // Reverse Order
        }

        resolve()
        return () => {}
      })
    })
  handleResize = _debounce(() => {
    // computeShouldPreventNext requires up-to-date element widths.
    // Ensure new offset widths have been calculated before calling
    // computeShouldPreventNext
    this.cacheOffsetWidths().then(this.computeShouldPreventNext)
  }, 300)
  computeIndexOffset = (props: Props = this.props) => {
    const { children, index, columns = Defaults.columns } = props
    const length = React.Children.count(children)

    // No need to move if number of items is less than
    // number of columns
    if (length <= columns) {
      return
    }

    if (columns <= 1) {
      this.setState({
        indexOffset: index,
      })
      return
    }

    // Try to position selected index in center of list
    const half = Math.floor(columns / 2)
    const firstHalfIndex = half - 1
    const targetOffset = index - firstHalfIndex
    const maxOffset = length - half * 2
    let indexOffset = 0

    if (targetOffset >= maxOffset) {
      // Don't move scroller past last element
      indexOffset = maxOffset
    } else if (targetOffset > 0) {
      // Don't allow negative index offsets
      indexOffset = targetOffset
    }

    this.setState({
      indexOffset,
    })
  }
  hammer: Hammer
  itemsInner: HTMLElement | null | undefined = null
  itemsOuter: HTMLElement | null | undefined = null
  itemsWrapper: HTMLElement | null | undefined = null
  props: Props

  render() {
    const {
      children,
      onPrev,
      onNext,
      columns,
      controls,
      transition,
      index,
      limitIndex,
    } = this.props
    const { dragging, indexOffset, manualOffset, shouldPreventNext, carouselStyles } = this.state
    const aggregateOffset = -(this.getTotalOffsetWidthByIndex(indexOffset) - manualOffset)
    return (
      <CarouselContainer
        columns={columns}
        dragging={dragging}
        ref={(ref) => {
          this.itemsWrapper = ref
        }}
      >
        {controls && (
          <Prev visible={!limitIndex || index > 0} onClick={onPrev} aria-label='previous image'>
            <PrevIcon />
          </Prev>
        )}
        <CarouselItemContainer
          ref={(ref) => {
            this.itemsOuter = ref
          }}
        >
          <CarouselItemContainerInner
            transition={transition && !dragging}
            style={{
              transform: `translateX(${aggregateOffset}px)`,
              ...carouselStyles
            }}
            ref={(ref) => {
              this.itemsInner = ref
            }}
          >
            {children}
          </CarouselItemContainerInner>
        </CarouselItemContainer>
        {controls && (
          <Next
            visible={!limitIndex || !shouldPreventNext}
            onClick={onNext}
            aria-label='next image'
          >
            <NextIcon />
          </Next>
        )}
      </CarouselContainer>
    )
  }
}
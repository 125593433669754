// CONSTANTS
export const Constants = {
  IS_READY: 'PAGE_IS_READY',
  SET: 'SET_PAGE',
  SET_DATA: 'SET_PAGE_DATA',
  SET_PARAMS: 'SET_PAGE_PARAMS',
  SET_PATH: 'SET_PAGE_PATH',
  RESET: 'RESET_PAGE_PARAMS_AND_DATA',
  RESET_DATA: 'RESET_PAGE_DATA',
  RESET_PARAMS: 'RESET_PAGE_PARAMS',
}
// ACTION TYPES
export type FlowTypes =
  | {
      type: typeof Constants.IS_READY
      payload: boolean
    }
  | {
      type: typeof Constants.SET
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_DATA
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_PARAMS
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_PATH
      payload: string
    }
  | {
      type: typeof Constants.RESET
    }
  | {
      type: typeof Constants.RESET_DATA
    }
  | {
      type: typeof Constants.RESET_PARAMS
    }
// ACTION CALLS
export const Actions = {
  IS_READY: (payload: boolean): FlowTypes => ({
    type: Constants.IS_READY,
    payload,
  }),
  SET: (payload: Record<string, any>): FlowTypes => ({
    type: Constants.SET,
    payload,
  }),
  SET_DATA: (payload: Record<string, any>): FlowTypes => ({
    type: Constants.SET_DATA,
    payload,
  }),
  SET_PARAMS: (payload: Record<string, any>): FlowTypes => ({
    type: Constants.SET_PARAMS,
    payload,
  }),
  SET_PATH: (payload: string): FlowTypes => ({
    type: Constants.SET_PATH,
    payload,
  }),
  RESET: (): FlowTypes => ({
    type: Constants.RESET,
  }),
  RESET_DATA: (): FlowTypes => ({
    type: Constants.RESET_DATA,
  }),
  RESET_PARAMS: (): FlowTypes => ({
    type: Constants.RESET_PARAMS,
  }),
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*

  Having Problems with shared TIMER so rebuilding and sample of implementation
  - Basically we would have race conditions, so you need to instantiate and call function to have UNIQUE instance

  You can look at `apps/test/page/components/Debounce` for example

  OR

  ```
  import Debounce from 'lib/debounce'

  // Initialize
  const debounce = Debounce()

  // Call
  debounce(() => console.log('task 1'), 3000)
  ```
*/
const uniqueId = () => Math.random().toString(36).substring(2, 12);
const Debounce = () => {
    let timer;
    let uid;
    if (!uid) {
        uid = uniqueId();
    }
    // Debounce || SetTimer Callback
    const timerDuration = 500; // .5 second
    const clearTimer = () => {
        // console.log(`clear`)
        clearTimeout(timer);
        timer = null;
    };
    const debounce = (callback, duration) => {
        clearTimer();
        timer = setTimeout(() => {
            callback(); // console.log('debounce', { uid, callback, duration })
        }, duration || timerDuration);
    };
    return debounce;
};
exports.default = Debounce;

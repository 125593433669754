"use strict";
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
let timer;
const DURATION = 3000; // 3 seconds
const MAX_EVENTS = 25;
class DataLayer {
    constructor() {
        this.clearImpressionEvents = (events) => {
            // We will just grab items IDs and remove them from events fired
            const ids = events.map(({ id }) => id);
            // Remove Existing Items from ARRAY
            this.impressions = this.impressions.filter((event) => !ids.includes(event.id));
            // If we still have TOO MANY items fire off again
            if (this.impressions.length > MAX_EVENTS) {
                this.sendImpressionEvents();
            }
        };
        // Normally you will setup/call function once EPICS for USER are setup -> 'NEW_CLIENT_SETUP_COMPLETE'
        this.setCurrency = (currency) => {
            this.currency = currency;
            this.setupCloseListener();
        };
        this.sendImpressionEvents = () => __awaiter(this, void 0, void 0, function* () {
            if (window && window.dataLayer) {
                // Do NOT block page or send EMPTY events
                if (!this.impressions.length) {
                    return;
                }
                const cloneImpressions = [...this.impressions.slice(0, MAX_EVENTS)]; // we do NOT want to mutate or touch ORIGINAL source
                const currentImpressionEvents = {
                    currencyCode: this.currency,
                    impressions: cloneImpressions,
                };
                // Send Events
                window.dataLayer.push({
                    event: 'impressions',
                    ecommerce: currentImpressionEvents,
                });
                this.clearImpressionEvents(cloneImpressions);
            }
        });
        this.addImpressionEventOld = (event) => {
            clearTimeout(timer);
            this.impressions.push(event);
            timer = setTimeout(this.sendImpressionEvents, DURATION);
            if (this.impressions.length >= MAX_EVENTS) {
                this.sendImpressionEvents();
            }
        };
        // We want a WAY to fire off events when USER closes/leaves page
        this.setupCloseListener = () => {
            if (!this.onCloseHandler) {
                window.addEventListener('beforeunload', () => __awaiter(this, void 0, void 0, function* () {
                    yield this.sendImpressionEvents();
                }));
                this.onCloseHandler = true;
            }
        };
        this.currency = 'USD';
        this.impressions = [];
        this.onCloseHandler = false;
    }
}
const singleton = new DataLayer();
exports.default = singleton;

import type { ActionTypes as Action } from '../../apps/ops/shipment/actions'
import { Constants } from '../../apps/ops/shipment/actions'
export const defaultState = {
  shipment: {
    local: '',
    server: '',
    confirmedShipment: '',
  },
}
type State = Record<string, any>
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_SHIPMENT_LOCAL: {
      const { shipment } = state
      return { ...state, shipment: { ...shipment, local: action.payload } }
    }

    case Constants.SET_SHIPMENT_AFTER_CONFIRMATION: {
      const { shipment } = state
      return { ...state, shipment: { ...shipment, confirmedShipment: action.payload } }
    }

    case Constants.SET_SHIPMENT_SERVER:
      return {
        ...state,
        shipment: {
          server: action.payload,
          local: action.payload,
        },
      }

    case Constants.SWITCH_SHIPMENT_DESTINATION: {
      const { shipment } = state
      // Don't Pass Payload
      // Turn back into an Object, grab keys, switch, merge back into state
      const jsonState = JSON.parse(shipment.local)
      const {
        originName,
        originCompany,
        originEmail,
        originPhone,
        originStreet1,
        originStreet2,
        originCity,
        originState,
        originZip,
        originCountry,
        destinationName,
        destinationCompany,
        destinationEmail,
        destinationPhone,
        destinationStreet1,
        destinationStreet2,
        destinationCity,
        destinationState,
        destinationZip,
        destinationCountry,
      } = jsonState
      const newState = {
        originName: destinationName,
        originCompany: destinationCompany,
        originEmail: destinationEmail,
        originPhone: destinationPhone,
        originStreet1: destinationStreet1,
        originStreet2: destinationStreet2,
        originCountry: destinationCountry,
        originCity: destinationCity,
        originState: destinationState,
        originZip: destinationZip,
        destinationName: originName,
        destinationCompany: originCompany,
        destinationEmail: originEmail,
        destinationPhone: originPhone,
        destinationStreet1: originStreet1,
        destinationStreet2: originStreet2,
        destinationCountry: originCountry,
        destinationCity: originCity,
        destinationState: originState,
        destinationZip: originZip,
      }
      return {
        ...state,
        shipment: { ...shipment, local: JSON.stringify({ ...jsonState, ...newState }) },
      }
    }

    default:
      return state
  }
}
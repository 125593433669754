import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getScreenWidth } from 'selectors/screen'
import { getHeroItems } from '../selectors'
import type { HeroCarouselItem } from '../types'
const mapStateToProps = createStructuredSelector({
  heroItems: getHeroItems,
  screenWidth: getScreenWidth,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  heroItems: Array<HeroCarouselItem>
  screenWidth: number
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect
import { $Shape } from 'utility-types'
import type { LocaleCurrency } from 'types/Shared/Locale'
import 'types/Shared/Locale'
import { defaultLocale, defaultCurrencyRate } from 'lib/i18'
export function formatPrice(
  price: number,
  localeCurrency?: $Shape<LocaleCurrency>,
  decimals?: boolean
): string {
  try {
    const { currencyCode, locale } = localeCurrency || defaultCurrencyRate
    // convert to locale e.g. € 3.300.000,00
    const formattedPrice = price.toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: Number.isInteger(price) && !decimals ? 0 : 2, // 7.70 => 7.7 so we need to set minimum
    })

    // Special Case for AUSTRALIAN Dollars - Node is appending A$ to Format
    if (currencyCode === 'AUD') {
      return `A${formattedPrice.replace(/[A-Z]/gi, '')}`
    }

    if (currencyCode === 'CAD') {
      return `C${formattedPrice.replace(/[A-Z]/gi, '')}`
    }

    if (currencyCode === 'NZD') {
      return `NZ${formattedPrice.replace(/[A-Z]/gi, '')}`
    }

    if (currencyCode === 'SGD') {
      return `S${formattedPrice.replace(/[A-Z]/gi, '')}`
    }

    if (currencyCode === 'CHF') {
      return `₣${formattedPrice.replace(/[A-Z,$]/gi, '')}`
    }

    return formattedPrice
  } catch (error) {
    // If JS doesn't know the format (currencyCode value is unknown)
    // If so, log the error and display the price in USD.
    console.error('Error (formatPrice)', {
      error,
    })
    return formatPrice(price, defaultCurrencyRate)
  }
}
// £67 => 67 / 0.79 = $85
export function convertLocaleToDefaultCurrency(
  price: number,
  localeCurrency?: $Shape<LocaleCurrency>,
  precise?: boolean
): number {
  try {
    const { exchangeRate, locale } = localeCurrency || defaultCurrencyRate

    if (locale === defaultLocale) {
      return price
    }

    // Example: 1000 / 1.32 = 757.57575757
    if (precise) {
      // set to pennies Ex: 757.57575757 => 757.57
      return Number((price / exchangeRate).toFixed(2))
    }

    // set to whole dollar Ex: 757.57575757 => 758
    return Math.round(price / exchangeRate)
  } catch (error) {
    // If JS doesn't know the format (locale value is unknown)
    // If so, log the error and display the price in USD.
    console.error('Error (convertLocaleToDefaultCurrency)', {
      error,
    })
    return price
  }
}
// $85 => 85 / 0.79 = £67
export function convertDefaultToLocaleCurrency(
  price: number,
  localeCurrency?: $Shape<LocaleCurrency>,
  precise?: boolean
): number {
  try {
    const { exchangeRate, locale } = localeCurrency || defaultCurrencyRate

    if (locale === defaultLocale) {
      return price
    }

    // Example: 758 * 1.32 = 1000.560000001
    if (precise) {
      // set to pennies Ex: 1000.560000001 => 1000.56
      return Number((price * exchangeRate).toFixed(2))
    }

    // set to whole dollar Ex: 1000.560000001 => 1001
    return Math.round(price * exchangeRate)
  } catch (error) {
    // If JS doesn't know the format (locale value is unknown)
    // If so, log the error and display the price in USD.
    console.error('Error (convertDefaultToLocaleCurrency)', {
      error,
    })
    return price
  }
}
export function getSingleCharacterCurrencySymbol(localeCurrency?: $Shape<LocaleCurrency>): string {
  try {
    const formatted = formatPrice(Number(), localeCurrency || defaultCurrencyRate).replace(
      /[\d|(.)]/g,
      ''
    )
    return formatted.trim().slice(-1)
  } catch (error) {
    // If JS doesn't know the format (locale value is unknown)
    // If so, log the error and display the price in USD.
    console.error('Error (getCurrencySymbol)', {
      error,
    })
    return '$'
  }
}
export function getCurrencySymbol(localeCurrency?: $Shape<LocaleCurrency>): string {
  try {
    return formatPrice(Number(), localeCurrency || defaultCurrencyRate).replace(/[\d|(.)]/g, '')
  } catch (error) {
    // If JS doesn't know the format (locale value is unknown)
    // If so, log the error and display the price in USD.
    console.error('Error (getCurrencySymbol)', {
      error,
    })
    return '$'
  }
}
// REASON => 770 => 7.70 => Number(7.70) => 7.7
// So we need to check if has decimals and toFixed(2) the amount
export const setDecimal = (value: number): number | string =>
  Number.isInteger(value) ? value : value.toFixed(2)
export const convertFromCentsToDollars = (price: number): number => Number(price / 100)
export const convertFromCentsToDollarsString = (price: number): string =>
  Number(price / 100).toFixed(2)
export { defaultLocale, defaultCurrencyRate }
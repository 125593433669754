// CONSTANTS
export const Constants = {
  SET_LOCATION: 'SET_LOCATION',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_LOCATION
  payload: string
}
// ACTION CALLS
export const Actions = {
  SET_LOCATION: (payload: string): ActionTypes => ({
    type: Constants.SET_LOCATION,
    payload,
  }),
}
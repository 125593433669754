import { createSelector } from 'reselect'
import type { User } from 'types/User'
import 'types/User'
import type { Cart } from 'types/User/Cart'
import 'types/User/Cart'
import { getUser } from './helpers'
export const getCart = createSelector([getUser], (user: User): Cart => user.cart || {})
export const getCartCount = createSelector([getCart], (cart: Cart): number => cart.count || 0)
export const getCartDetails = createSelector(
  [getCart],
  (cart: Cart): Record<string, any> => cart.details || {}
)
export const getCartQuickView = createSelector(
  [getCart],
  (cart: Cart): Record<string, any> => cart.quickView || {}
)
export const getCartShowQuickView = createSelector(
  [getCartQuickView],
  ({ show }): boolean => show || false
)
export const getCartQuickViewProductSku = createSelector(
  [getCartQuickView],
  ({ productSku }): string => productSku || ''
)
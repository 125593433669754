import type { Screen } from 'types/Screen'
import 'types/Screen'
// CONSTANTS
export const Constants = {
  UPDATE_SCREEN_SIZE: 'UPDATE_SCREEN_SIZE_APP',
  SCREEN_SIZE_READY: 'SCREEN_SIZE_READY_APP',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.UPDATE_SCREEN_SIZE
      payload: Screen
    }
  | {
      type: typeof Constants.SCREEN_SIZE_READY
      payload: boolean
    }
// ACTION CALLS
export const Actions = {
  UPDATE_SCREEN_SIZE: (payload: Screen): ActionTypes => ({
    type: Constants.UPDATE_SCREEN_SIZE,
    payload,
  }),
  SCREEN_SIZE_READY: (payload: boolean): ActionTypes => ({
    type: Constants.SCREEN_SIZE_READY,
    payload,
  }),
}
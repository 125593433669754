import React from 'react'
import useTimer from './hooks/useTimer'

type Props = {
  endTimestamp: number // 1657339200
}

const Component = (props: Props) => {
  const timer = useTimer(props)
  const { days, expired, hours, minutes, seconds, stopTimer } = timer

  if (expired) {
    return null
  }

  if (hours > 35) {
    stopTimer()
    return <span data-type='countdown'>{days + 1} Days Left</span>
  }

  return (
    <span data-type='countdown'>
      {hours ? <span data-type='hours'>{hours}</span> : ''}
      <span data-type='minutes'>{minutes ? `0${minutes}`.slice(-2) : '00'}</span>
      <span data-type='seconds'>{seconds ? `0${seconds}`.slice(-2) : '00'}</span>
    </span>
  )
}

export default Component

// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import SALink from 'components/SALink'
import DotsIndexIndicator from 'components/DotsIndexIndicator'
import {
  CarouselContainer,
  Prev,
  Next,
  CarouselItemContainer,
  CarouselItemContainerInner,
} from 'components/Carousel/Hero/styles'
import Checkmark from 'svg/checkmark.svg'
// import { Row } from 'styles/account'
export const MAX_WIDTH_NARROW = 1362
export const MIN_WIDTH_NARROW = 484
export const TABLET_WIDTH = 1200
export const MOBILE_WIDTH = 999
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Title = styled.h2`
  color: ${(props) => props.theme.colors.default};
  margin: 0 auto;
  font-weight: normal;
  font-size: 36px;
`
export const Description = styled.p`
  color: ${(props) => props.theme.colors.default};
  margin: 0;
`
export const CarouselItem = styled.div`
  width: ${(props) => props.width};
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    flex-direction: column;
  }
  /* Start Hero Carousel  */
  [data-type='carouselWrapper'] {
    display: flex;
    width: 100%;
    position: relative;
    align-items: flex-start;
    &[data-display='mobile'] {
      flex-direction: column;
    }

    ${Prev} {
      z-index: 1;
      margin-left: 15px;
    }
    ${Next} {
      margin-right: 15px;
    }
    ${Prev}, ${Next} {
      path {
        stroke: white;
      }
    }
    ${CarouselContainer} {
      width: 100%;
      @media (max-width: ${MOBILE_WIDTH}px) {
        height: 100%;
      }
    }
    ${CarouselItemContainer} {
      height: 640px;
      @media (max-width: ${MOBILE_WIDTH}px) {
        height: 100%;
        display: flex;
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    ${CarouselItemContainerInner} {
      height: 600px;
      @media (max-width: ${MOBILE_WIDTH}px) {
        height: 100%;
      }
    }
    [data-type='carouselItem'] {
      height: 600px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        @media (max-width: ${MOBILE_WIDTH}px) {
          height: 240px;
        }
      }
      @media (max-width: ${MOBILE_WIDTH}px) {
        position: relative;
        height: 385px;
        width: 100%;
        flex-shrink: 0;
        scroll-snap-align: start;
      }
      [data-type='hero-copy-holder'] {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 90px;
        width: 100%;
        align-items: center;
        * {
          color: ${(props) => props.theme.colors.white};
        }
        h1 {
          margin: 0;
        }
        [data-type='hero-title'],
        [data-type='hero-description'] {
          @media (max-width: ${MOBILE_WIDTH}px) {
            color: ${(props) => props.theme.colors.default};
            white-space: break-spaces;
            text-align: center;
          }
        }
        [data-type='hero-description'] {
          margin: 0;
          @media (max-width: ${MOBILE_WIDTH}px) {
            padding: 0px 16px;
          }
        }
        @media (max-width: ${MOBILE_WIDTH}px) {
          bottom: auto;
          top: 304px;
        }
      }
      [data-type='cta-wrapper'] {
        position: relative;
        display: flex;
        align-self: center;
        justify-self: center;
        justify-content: center;
        margin-top: -35px;
        height: 60px;
        z-index: 9999;
        @media (max-width: ${MOBILE_WIDTH}px) {
          top: 0px;
          bottom: auto;
        }
        [data-type='cta'] {
          font-weight: bold;
          width: 220px;
          height: 60px;
          background-color: ${(props) => props.theme.colors.default};
          color: ${(props) => props.theme.colors.white};
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
  /* End Hero Carousel - Start Top Tiles  */
  [data-type='TopTilesContainer'] {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    @media (max-width: ${MOBILE_WIDTH}px) {
      justify-content: flex-start;
      margin: 0px;
      overflow-x: auto;
    }
    [data-type='scroll'] {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @media (max-width: ${MOBILE_WIDTH}px) {
        min-width: 980px;
        min-height: 400px;
        margin-bottom: 0;
      }
      @media (max-width: ${MIN_WIDTH_NARROW}px) {
        min-width: 553px;
        min-height: 320px;
        margin-bottom: 0;
      }
    }
    [data-type='topTilesTile'] {
      width: 400px;
      flex-direction: column;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      display: flex;
      a {
        color: ${(props) => props.theme.colors.lightGray};
        text-decoration: none;
        transition: 0.2s ease color;
        &:hover {
          color: ${(props) => props.theme.colors.default};
        }
      }
      img {
        width: 100%;
        height: auto;
        display: inline-block;
        background: ${(props) => props.theme.colors.backgroundGray};
      }
      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 300px;
      }
      @media (max-width: ${MIN_WIDTH_NARROW}px) {
        width: 175px;
        margin-right: 14px;
      }
      [data-type='topTilesCollectionInfo'] {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 16px;
        gap: 16px;
        [data-type='tile-description'] {
          color: ${(props) => props.theme.colors.default};
          margin: 0;
          text-decoration: underline;
          text-transform: uppercase;
          margin-top: 8px;
          font-weight: bold;
          letter-spacing: 0.7px;
        }
        @media (max-width: ${MOBILE_WIDTH}px) {
          margin-top: 8px;
          text-align: start;
        }
      }
    }
  }
  /* End Top Tiles  - Start Shop By Category  */
  [data-type='shop-by-category-container'] {
    margin: 100px 0px;
    @media (max-width: ${MOBILE_WIDTH}px) {
      margin: 40px 0px;
    }
  }
  [data-type='shop-by-category'] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 24px 0px;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    [data-type='shop-category-title'] {
      min-width: 260px;
      @media (max-width: ${MOBILE_WIDTH}px) {
        min-width: unset;
        margin-bottom: 24px;
      }
    }
    [data-type='category-cta'] {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 20px;
      text-decoration: none;
      width: 100%;
      min-width: 100px;
      color: ${(props) => props.theme.colors.default};
      border: solid 1px ${(props) => props.theme.colors.default};
      background: ${(props) => props.theme.colors.white};
      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
        margin-left: 0;
      }
    }
    [data-type='shop-row'] {
      display: flex;
      width: 100%;
      gap: 16px;
      @media (max-width: ${MOBILE_WIDTH}px) {
        display: grid;
        grid-row-end: span 1;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        grid-column-gap: 12px;
        grid-row-gap: 24px;
      }
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      margin: 0px 0px 40px 0px;
      flex-direction: column;
      gap: 0px;
    }
  }
  /* End Shop By Category  - Start Large Carousel */
  [data-type='productCarouselWrapper'] {
    margin-bottom: 100px;
    content-visibility: auto;
    contain-intrinsic-size: 1059px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 980px;
    position: relative;
    border: none;
    display: block;
    @media (max-width: ${MOBILE_WIDTH}px) {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      height: 500px;
    }
    [data-type='featured-collection-title'] {
      margin-bottom: 40px;
      @media (max-width: ${MOBILE_WIDTH}px) {
        margin-bottom: 24px;
      }
    }
    button {
      display: none;
      @media (min-width: ${MOBILE_WIDTH}px) {
        display: block;
        z-index: 5;
        top: 35%;
        height: 60px;
        width: 36px;
        transform: none;
        background-color: rgba(255, 255, 255, 0.6);
        svg {
          height: 40px;
        }
        &:hover {
          transform: none;
        }
        &:first-child {
          margin-left: 20px;
        }
        &:last-child {
          margin-right: 20px;
        }
      }
    }
    @media (min-width: ${MOBILE_WIDTH}px) {
      width: 100%;
      max-width: 1360px;
      position: relative;
      border: none;
      div > div > div {
        transform: none;
        transition: opacity 500ms ease-in-out 0s;
      }
    }
    [data-type='carousel-item-container'] {
      height: unset;
      @media (max-width: ${MOBILE_WIDTH}px) {
        display: flex;
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      [data-type='carousel-item-container-inner'] {
        height: unset;
      }
    }
    [data-type='slide'] {
      height: 530px;
      width: 100%;
      flex-wrap: wrap;
      opacity: 1;
      position: relative;
      display: inline-flex;

      @media (min-width: ${MOBILE_WIDTH}px) {
        height: 850px;
        width: 100%;
        &[data-status='slideActive'] {
          opacity: 1;
          transition: opacity 500ms ease-in-out 0s;
        }
        &[data-status='slideInactive'] {
          opacity: 0.5;
          transition: opacity 500ms ease-in-out 0s;
        }
        + [data-type='slide'] {
          margin-left: 9%;
        }
      }
      @media (max-width: ${MOBILE_WIDTH}px) {
        scroll-snap-align: start;
        height: 410px;
      }

      [data-type='pane'] {
        width: 100%;
        height: 320px;
        [data-type='pane-image'] {
          height: 85%;
          width: 100%;
          object-fit: cover;
          object-position: 0 65%;
          @media (min-width: ${MOBILE_WIDTH}px) {
            height: 700px;
          }
        }
        [data-type='pane-content'] {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 16px;
          @media (max-width: ${MOBILE_WIDTH}px) {
            padding-top: 16px;
            align-items: start;
          }
        }
        [data-type='pane-description'] {
          margin: 8px 0px 16px;
          @media (max-width: ${MOBILE_WIDTH}px) {
            margin: 0px 0px 8px 0px;
          }
        }
        a {
          font-size: 14px;
          color: #333;
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: bold;
        }
        @media (min-width: ${MOBILE_WIDTH}px) {
          height: 850px;
          width: 48.5%;
        }
      }
      [data-type='pane']:last-child {
        margin-left: 2.95%;
      }
    }
  }
  /* End Large Carousel  - Start Small Carousels */
  [data-type='carousel-small-container'] {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
  [data-type='small-carousel'] {
    text-align: start;
    width: 100%;
    [data-type='carousel-header'] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      [data-type='section-link'] {
        min-width: 150px;
        text-align: end;
      }
      @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-bottom: 24px;
        [data-type='section-link'] {
          text-align: start;
        }
      }
      a {
        font-weight: bold;
        text-decoration: underline;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.default};
        display: flex;
        align-self: flex-start;
        @media (max-width: ${MOBILE_WIDTH}px) {
          margin: 7px 0 5px;
        }
      }
    }
    [data-type='products'] {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @media (max-width: ${MOBILE_WIDTH}px) {
        overflow-x: auto;
        padding: 0 0 20px;
      }
      [data-type='scroll'] {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: ${MOBILE_WIDTH}px) {
          justify-content: flex-start;
          min-width: 980px;
        }
      }
    }
  }
  /* End Small Carousels  - Start Shop By Price */
  [data-type='shop-by-price'] {
    content-visibility: auto;
    contain-intrinsic-size: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 65px auto 60px;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    [data-type='shop-price-title'] {
      min-width: 200px;
      @media (max-width: ${TABLET_WIDTH}px) {
        min-width: unset;
        margin-bottom: 24px;
      }
    }
    [data-type='price-cta'] {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 20px;
      text-decoration: none;
      width: 100%;
      min-width: 100px;
      color: ${(props) => props.theme.colors.default};
      border: solid 1px ${(props) => props.theme.colors.default};
      background: ${(props) => props.theme.colors.white};
      @media (max-width: ${TABLET_WIDTH}px) {
        width: 100%;
        margin-left: 0;
        padding: 12px 20px;
      }
    }
    [data-type='shop-row'] {
      display: flex;
      width: 100%;
      gap: 16px;
      @media (max-width: ${TABLET_WIDTH}px) {
        width: 100%;
        max-width: 310px;
        flex-direction: column;
        gap: 16px;
      }
    }
    @media (max-width: ${TABLET_WIDTH}px) {
      margin: 0px;
      flex-direction: column;
      gap: 0px;
    }
  }
  [data-type='shop-by-price-container'] {
    margin: 100px 0px;
    @media (max-width: ${TABLET_WIDTH}px) {
      margin: 40px 0px;
    }
  }
  /* End Shop By Price  - Start Art Advisory */
  [data-type='artAdvisoryWrapper'] {
    padding: 40px 57px;
    margin: 40px 0px;
    background: #f9f9f9;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${MOBILE_WIDTH}px) {
      padding: 0px;
    }

    [data-type='art-advisory-content'] {
      max-width: 1440px;
      position: relative;
      display: flex;
      flex-direction: row;
      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 100%;
        flex-direction: column;
      }

      img {
        width: 100%;
        height: auto;
        @media (max-width: ${MOBILE_WIDTH}px) {
          width: 100%;
          padding: 40px 16px 16px 16px;
        }
      }
      [data-type='signature'] {
        height: 44px;
        width: 220px;
        @media (max-width: ${MOBILE_WIDTH}px) {
          display: none;
        }
      }
      [data-type='callout'] {
        width: 50%;
        background: ${(props) => props.theme.colors.backgroundGrayLight};
        color: ${(props) => props.theme.colors.default};
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: ${MOBILE_WIDTH}px) {
          width: 100%;
          margin: 0px 0px 40px 0px;
          align-items: center;
          gap: 8px;
          a {
            color: ${(props) => props.theme.colors.default};
            display: flex;
            background: transparent;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
        [data-type='desk-header'] {
          margin: 0 0 20px 0;
          @media (max-width: ${MOBILE_WIDTH}px) {
            display: none;
          }
        }
        [data-type='callout-info-container'] {
          display: flex;
          flex-direction: column;
          gap: 24px;
          [data-type='mobile-pre-cta'] {
            @media (max-width: ${MOBILE_WIDTH}px) {
              display: block;
            }
          }
          @media (max-width: ${MOBILE_WIDTH}px) {
            width: 100%;
            padding: 0;
          }
          [data-type='callout-cta-text'] {
            @media (max-width: ${MOBILE_WIDTH}px) {
              text-transform: uppercase;
            }
          }
          [data-type='credit'] {
            line-height: 22px;
          }
          a {
            color: ${(props) => props.theme.colors.default};
            display: flex;
            background: transparent;
            text-transform: uppercase;
            cursor: pointer;
            @media (max-width: ${MOBILE_WIDTH}px) {
              width: 100%;
              padding: 8px 0 0 0;
              margin: 0 0 40px;
            }
          }
        }
      }
    }
  }
  /* End Art Advisory  - Start Featured Stories*/
  [data-type='featured-stories'] {
    content-visibility: auto;
    contain-intrinsic-size: 439px;
    width: 100%;
    margin: 100px 0px;
    [data-type='featured-stories-header'] {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 40px;
      [data-type='view-all'] {
        text-transform: capitalize;
      }
      @media (max-width: ${MOBILE_WIDTH}px) {
        align-items: flex-start;
        margin-bottom: 24px;
      }
      a {
        color: ${(props) => props.theme.colors.default};
        cursor: pointer;
      }
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      justify-content: flex-start;
      margin: 40px 0px;
      overflow-x: auto;
    }
    [data-section='stories'] {
      display: flex;
      @media (max-width: ${MOBILE_WIDTH}px) {
        overflow-x: auto;
      }
      [data-type='scroll'] {
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (max-width: ${MOBILE_WIDTH}px) {
          justify-content: flex-start;
          min-width: 980px;
          margin-bottom: 24px;
        }
        @media (max-width: ${MIN_WIDTH_NARROW}px) {
          min-width: 553px;
          min-height: 220px;
          margin-bottom: 0;
        }
      }
    }
    [data-type='story'] {
      width: 400px;
      flex-direction: column;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      display: flex;
      a {
        color: ${(props) => props.theme.colors.lightGray};
        text-decoration: none;
        transition: 0.2s ease color;
        &:hover {
          color: ${(props) => props.theme.colors.default};
        }
      }
      img {
        width: 100%;
        height: auto;
        display: inline-block;
        background: ${(props) => props.theme.colors.backgroundGray};
      }
      @media (max-width: ${MOBILE_WIDTH}px) {
        width: 300px;
      }
      @media (max-width: ${MIN_WIDTH_NARROW}px) {
        width: 175px;
        margin-right: 14px;
      }
      [data-type='featuredStoriesStoryInfo'] {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        @media (max-width: ${MOBILE_WIDTH}px) {
          margin-top: 8px;
          gap: 8px;
          text-align: start;
        }
        [data-type='story-description'] {
          color: ${(props) => props.theme.colors.default};
          margin: 8px 0 0 0;
          text-decoration: underline;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 0.7px;
        }
      }
    }
  }
  /* End Featured Stories - Start Value Props */
  [data-type='valuePropWrapper'] {
    content-visibility: auto;
    contain-intrinsic-size: 175px;
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    [data-type='valuePropItem'] {
      ${Title} {
        margin: 0;
        @media (max-width: ${MAX_WIDTH_NARROW}px) {
          white-space: break-spaces;
          margin-bottom: 6px;
        }
        @media (max-width: ${MIN_WIDTH_NARROW}px) {
          margin: 0 0 10px 0;
        }
      }
      ${Row} {
        justify-content: center;
        align-items: center;
        @media (max-width: ${MOBILE_WIDTH}px) {
          flex-direction: column;
        }
      }
      ${Description} {
        max-width: 390px;
        margin: 0 auto;
        font-size: ${(props) => props.theme.fontSizes[1]}px;
        word-break: break-word;
        white-space: normal;
        line-height: 1.5;
      }
    }
  }
  [data-type='valuePropWrapperMobile'] {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 40px 0px;
    background-color: #333333;
    h5,
    p {
      color: #fff;
    }
    ${CarouselItemContainerInner} {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @media (max-width: ${MOBILE_WIDTH}px) {
        display: block;
      }
      > * {
        width: 32%;
        @media (max-width: ${MOBILE_WIDTH}px) {
          width: 100%;
        }
      }
      @media (min-width: ${MOBILE_WIDTH}px) {
        transform: translateX(0px) !important; /* Stop draggability for desktop */
      }
    }
    [data-type='valuePropItem'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      [data-type='value-prop-text-container'] {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
      [data-type='value-prop-item-title'] {
        margin: 0;
        text-align: center;
        @media (max-width: ${MAX_WIDTH_NARROW}px) {
          white-space: break-spaces;
        }
        @media (max-width: ${MIN_WIDTH_NARROW}px) {
          margin: 0 0 10px 0;
        }
      }
      [data-type='value-prop-item-description'] {
        text-align: center;
        max-width: 390px;
        margin: 8px auto 0px;
        word-break: break-word;
        white-space: normal;
      }
      @media (min-width: ${MOBILE_WIDTH}px) {
        vertical-align: top;
        width: 33%;
      }
    }
  }
`
export const Dots = styled(DotsIndexIndicator)`
  &[data-type='heroDots'] {
    position: absolute;
    bottom: 25px;
    right: 40px;
    transform: translateX(-50%);
    [data-type='navDot'] {
      border: none;
      background-color: rgba(255, 255, 255, 0.5);
      ::after {
        background-color: ${(props) => props.theme.colors.white};
      }
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      bottom: auto;
      right: 50%;
      margin-right: -40px;
      top: 150px;
    }
  }
  &[data-type='valuePropsDots'] {
    display: none;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    div {
      border-color: #fff;
      background-color: #fff;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      display: block;
    }
  }
  &[data-name='productCarousel'] {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    [data-type='navDot'] {
      border: 1px solid #666;
      background-color: rgba(255, 255, 255, 0.5);
      ::after {
        background-color: ${(props) => props.theme.colors.default};
      }
    }
    @media (min-width: ${MOBILE_WIDTH}px) {
      display: none;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      position: absolute;
      bottom: 25px;
      left: 50%;
      right: unset;
      top: unset;
    }
  }
`
export const OpacityOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;
  opacity: 0.2;
  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`

export const TextCallout = styled.div`
  width: 62%;
  margin-top: 80px;
  text-align: center;
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 50%;
    margin-top: 0px;
    padding: 24px 0px;
  }
  @media (max-width: 722px) {
    width: 75%;
  }
`
export const A = styled(SALink)`
  text-decoration: none;
  display: inline-block;
  color: ${(props) => props.theme.colors.default};
`
export const SectionTitle = styled.h1`
  color: ${(props) => props.theme.colors.default};
  font-weight: normal;
  font-size: 24px;
  text-align: left;
`
export const SectionTitleLarge = styled.h1`
  color: ${(props) => props.theme.colors.default};
  font-weight: normal;
  font-size: 40px;
  text-align: left;
`
export const VerticalLine = styled.div`
  margin: 70px auto 30px;
  width: 2px;
  height: 40px;
  border-left: 1px solid ${(props) => props.theme.colors.default};
  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`
export const Separator = styled.div`
  display: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  width: 100%;
  margin-top: 20px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    display: block;
  }
`
export const NarrowWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0px 52px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 0px 16px;
  }
  @media (min-width: 1544px) {
    padding: 0px;
  }
`
export const TrustPilotWrapper = styled(Row)`
  max-width: 100%;
  width: 100%;
  display: block;
  margin: 40px auto 0;
`
export const WhiteCheck = styled(Checkmark)`
  path {
    stroke: #ffffff;
  }
  width: 40px;
  height: 50px;
  margin-left: -20px;
  @media (max-width: ${MOBILE_WIDTH}px) {
    margin-left: 0;
  }
`
// home shortcuts sub-menu styles
export const ShortcutsSubMenu = styled.div`
  width: 100%;
  margin: 48px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  button {
    border: solid 1px #999999;
    background: transparent;
    padding: 14px 20px;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    a {
      text-decoration: none;
      color: #333;
    }
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    margin: 12px;
  }
  @media (max-width: 768px) {
    gap: 8px;
    margin: 16px 0px;
    order: -1;
    button {
      padding: 12px 8px;
    }
    button:first-child {
      margin-left: 10px;
    }
    button:last-child {
      margin-right: 10px;
    }
    overflow-x: scroll;
    flex-wrap: nowrap;
    justify-content: flex-start;
    white-space: nowrap;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const RandomItem = styled.div`
  display: flex;
  padding: 0;
  background: #ff0099;
  border: 1px dashed #000;
  width: ${(props) => props.width || `300px`};
  height: 100%;
  &[data-type='pink'] {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: #ffffff;
      font-size: 48px;
    }
    a,
    a:hover {
      text-decoration: underline;
      color: #ffffff;
      line-height: 1.5em;
    }
  }
`
export const ClickItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  cursor: pointer;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #eee;
    color: #000;
    transition: 0.2s ease background;
    + div {
      margin-left: 30px;
    }
    &[data-selected] {
      background: #000;
      color: #eee;
    }
  }
`
export const HorizontalContainer = styled.div`
  display: flex;
  height: 240px;
  will-change: transform;
  ${(props) =>
    props.isDragging &&
    `
    opacity: 0.5;
    > div {
      background: green;
    }
  `}

  ${(props) => !props.isDragging && 'transition: 0.2s ease transform'};

  ${RandomItem} + ${RandomItem} {
    margin-left: ${(props) => props.padding || 0}px;
  }
  &[data-type='horizontal'] {
    height: 240px;
  }
`

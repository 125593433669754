import React from 'react'
import SALink from 'components/SALink'
import ArtworkItem from './CarouselSmall/Artwork'
import CarouselConnector, {
  ConnectorProps as CarouselConnectorProps,
} from '../../connectors/Carousels'

const CarouselSmallBottom = (
  props: CarouselConnectorProps
): React.ReactElement<React.ComponentProps<any>, any> | null | undefined => {
  const { carouselDataMultiple } = props

  if (carouselDataMultiple.length < 2) {
    return null
  }

  const { artworks, collectionUrl, linkText, title } = carouselDataMultiple[1]

  return (
    <div data-type='small-carousel'>
      <div data-type='carousel-header'>
        <h3 className='defaultTypographyH3' data-type='section-title'>
          {title}
        </h3>
        <div>
          <SALink href={collectionUrl}>
            <p className='defaultTypographyCtaSmallOrMedium' data-type='section-link'>
              {linkText}
            </p>
          </SALink>
        </div>
      </div>
      <div data-type='products'>
        <div data-type='scroll'>
          {artworks.map((artworkData, key) => (
            <ArtworkItem
              key={key}
              {...{
                collectionUrl,
                ...artworkData,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CarouselConnector(CarouselSmallBottom)

import type { ActionTypes as Action } from 'actions/shared/mobileTraffic'
import { Constants } from 'actions/shared/mobileTraffic'
export const defaultState = false
type State = boolean
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_MOBILE_TRAFFIC:
      return action.payload

    default:
      return state
  }
}
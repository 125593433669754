// CONSTANTS
export const Constants = {
  APPLY_PROMO: 'APPLY_PROMO_CODE_TO_CART',
  GET_CART: 'GET_CART_COUNT',
  SET_CART: 'SET_CART_COUNT',
  SHOW_CART: 'SHOW_CART_QUICK_VIEW',
  SHOW_CART_ITEM: 'SHOW_CART_QUICK_VIEW_ITEM',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.APPLY_PROMO
    }
  | {
      type: typeof Constants.GET_CART
    }
  | {
      type: typeof Constants.SET_CART
      payload: number
    }
  | {
      type: typeof Constants.SHOW_CART
      payload: boolean
    }
  | {
      type: typeof Constants.SHOW_CART_ITEM
      payload: string
    }
// ACTION CALLS
export const Actions = {
  APPLY_PROMO: (): ActionTypes => ({
    type: Constants.APPLY_PROMO,
  }),
  GET_CART: (): ActionTypes => ({
    type: Constants.GET_CART,
  }),
  SET_CART: (payload: number): ActionTypes => ({
    type: Constants.SET_CART,
    payload,
  }),
  SHOW_CART: (payload: boolean): ActionTypes => ({
    type: Constants.SHOW_CART,
    payload,
  }),
  SHOW_CART_ITEM: (payload: string): ActionTypes => ({
    type: Constants.SHOW_CART_ITEM,
    payload,
  }),
}
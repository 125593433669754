import { FlowTypes as Action } from 'actions/page'
import { Constants } from 'actions/page'
import { Page } from 'types/Page'
import 'types/Page'
export const defaultState = {
  data: {},
  isReady: true,
  name: '',
  params: {},
  path: '/',
}

type State = Page

export default function page(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case Constants.IS_READY:
      return { ...state, isReady: action.payload || false }

    case Constants.SET:
      return { ...(state || {}), ...(action.payload || {}) }

    case Constants.SET_DATA:
      return { ...(state || {}), data: { ...(state ? state.data : {}), ...(action.payload || {}) } }

    case Constants.SET_PARAMS:
      return {
        ...(state || {}),
        params: { ...(state ? state.params : {}), ...(action.payload || {}) },
      }

    case Constants.SET_PATH:
      return { ...(state || {}), path: action.payload || '' }

    case Constants.RESET:
      return { ...(state || {}), params: {}, data: {} }

    case Constants.RESET_DATA:
      return { ...(state || {}), data: {} }

    case Constants.RESET_PARAMS:
      return { ...(state || {}), params: {} }

    default:
      return state
  }
}

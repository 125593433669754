import type { SiteContext } from 'types/SiteContext'
import 'types/SiteContext'
// CONSTANTS
export const Constants = {
  SET_SITE_CONTEXT: 'SET_SITE_CONTEXT',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_SITE_CONTEXT
  payload: SiteContext
}
// ACTION CALLS
export const Actions = {
  SET_SITE_CONTEXT: (payload: SiteContext): ActionTypes => ({
    type: Constants.SET_SITE_CONTEXT,
    payload,
  }),
}
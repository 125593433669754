import type { ActionTypes as Action } from 'actions/shared/modal'
import { Constants } from 'actions/shared/modal'
import type { Modal } from 'types/Shared/Modal'
import 'types/Shared/Modal'
export const defaultState = {
  isVisible: false,
  name: '', // NAME of component/modal
}
type State = Modal
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.CLOSE_MODAL:
      return { ...state, isVisible: false }

    case Constants.OPEN_MODAL:
      return { ...state, isVisible: true, name: action.payload }

    case Constants.RESET_MODAL:
      return { ...state, isVisible: false, name: '' }

    default:
      return state
  }
}
import React, { useEffect } from 'react'
import debounce from 'lib/debounce'
import {
  CarouselContainer,
  CarouselItemContainer,
  CarouselItemContainerInner,
  NextIcon,
  Next,
  PrevIcon,
  Prev,
} from './styles'
type Props = {
  index: number
  children: React.ReactElement<React.ComponentProps<any>, any>[]
  onNext: (...args: Array<any>) => any
  onPrev: (...args: Array<any>) => any
  controls?: boolean
  transition?: boolean
  setIndex: (index: number) => void
  limitIndex?: boolean
  // Limit index to prevent going offscreen,
  screenWidth: number
  indexOffset?: number
  manualOffset?: number
  dragging?: boolean
  shouldPreventNext?: boolean
  carouselName?: string
}
const Defaults = {
  controls: false,
  transition: true,
  setIndex: (index: number) => undefined,
   
  limitIndex: false,
  interval: null,
  indexOffset: 0,
  manualOffset: 0,
  dragging: false,
  shouldPreventNext: true,
  carouselName: 'hero',
}
export default (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    children,
    onPrev,
    onNext,
    controls,
    transition,
    index,
    limitIndex,
    screenWidth,
    indexOffset,
    manualOffset,
    dragging,
    shouldPreventNext,
    carouselName,
  } = { ...Defaults, ...props }
  // Adjust maxwidth for scroll gutter.
  const canvasWidth = screenWidth < 500 || screenWidth > 1440 ? screenWidth : screenWidth - 15
  const maxWidth = screenWidth < 1440 ? canvasWidth : 1440
  const mobileSlideDebounce = debounce()
  const mobileTouchDebounce = debounce()
   
  let itemsInner: HTMLElement | null | undefined = null
   
  let itemsOuter: HTMLElement | null | undefined = null
   
  let itemsWrapper: HTMLElement | null | undefined = null

  // const offsetWidths: Array<number> = []
  const getTotalOffsetWidthByIndex = (offset) => {
    return (maxWidth - offset) * index
  }

  const aggregateOffset = -(getTotalOffsetWidthByIndex(indexOffset) - manualOffset)
  useEffect(() => {
    if (screenWidth <= 780) {
      mobileSlideDebounce(() => {
        const getTotalOffsetWidthBySwipeIndex = (offset) => {
          return (maxWidth - offset) * index
        }

        const mobileAggregateOffset = -(getTotalOffsetWidthBySwipeIndex(indexOffset) - manualOffset)
        // If index changes in mobile swipe mode, push a transformX to flip the slideh
        const carouselItemContainerInner = document.querySelector(
          `[data-name=${carouselName}] > div > [data-type='carouselItemContainerInner']`
        )

        if (carouselItemContainerInner !== null) {
          carouselItemContainerInner.setAttribute(
            'style',
            `transform: translateX(${mobileAggregateOffset}px);`
          )
        }

        // After the slide has set, replace the transform with margin to allow swiping again
        if (carouselItemContainerInner !== null) {
          carouselItemContainerInner.setAttribute(
            'style',
            `margin-left:${mobileAggregateOffset}px;`
          )

          // Set up touch events for mobile, etc
          // Not used now,stubbing in for future use
          const handleTouch = () => {
            mobileTouchDebounce(() => {
              carouselItemContainerInner.setAttribute('style', `margin-left:unset`)
            }, 200)
          }

          carouselItemContainerInner.addEventListener('touchstart', handleTouch)
          carouselItemContainerInner.addEventListener('mousedown', handleTouch)
        }
      }, 200)
    }
  }, [index])
  return (
    <CarouselContainer
      data-type='hero-carousel'
      data-name={carouselName}
      dragging={dragging}
      ref={(ref) => {
        itemsWrapper = ref
      }}
    >
      {controls && (
        <Prev visible={!limitIndex || index > 0} onClick={onPrev} aria-label='previous image'>
          <PrevIcon />
        </Prev>
      )}
      <CarouselItemContainer
        data-type='carousel-item-container'
        ref={(ref) => {
          itemsOuter = ref
        }}
      >
        <CarouselItemContainerInner
          data-type='carousel-item-container-inner'
          transition={transition && !dragging} // Don't flip slides on nav click on mobile
          style={
            screenWidth > 780
              ? {
                  transform: `translateX(${aggregateOffset}px)`,
                }
              : null
          }
          ref={(ref) => {
            itemsInner = ref
          }}
        >
          {children}
        </CarouselItemContainerInner>
      </CarouselItemContainer>
      {controls && (
        <Next visible={!limitIndex || !shouldPreventNext} onClick={onNext} aria-label='next image'>
          <NextIcon />
        </Next>
      )}
    </CarouselContainer>
  )
}
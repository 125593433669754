import React from 'react'
import SALink from 'components/SALink'
import { LocaleCurrency } from 'components/FormatPrice'
import { ArtworkItem, ArtworkInfo } from './styles'

import classNames from 'classnames'
import { capitalizeString } from 'lib/helpers'

const Artwork = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    artistName,
    artistProfileUrl,
    artworkTitle,
    artworkUrl,
    listPrice,
    thumbnailImageBase,
  } = props
  return (
    <ArtworkItem>
      <SALink href={`${artworkUrl}`} title={artworkTitle}>
        <picture data-type='image'>
          <img
            className='lazyload'
            loading='lazy'
            width='207'
            height='207'
            data-src={`${thumbnailImageBase}-25.jpg`}
            alt={`${artworkTitle}`}
            itemProp='image'
          />
        </picture>
        <noscript>
          <img
            width='207'
            height='207'
            src={`${thumbnailImageBase}-25.jpg`}
            alt={artworkTitle}
            itemProp='image'
          />
        </noscript>
      </SALink>

      <ArtworkInfo>
        <SALink data-type='artworkTitle' href={artworkUrl} alt={artworkTitle}>
          <h6 className={classNames('defaultTypographyH6', 'defaultTextTruncate')}>
            {capitalizeString(artworkTitle)}
          </h6>
        </SALink>
        <SALink data-type='artistName' href={artistProfileUrl} alt={`${artistName} Profile`}>
          <p className={classNames('defaultTypographyXSmall', 'defaultTextTruncate')}>
            {artistName}
          </p>
        </SALink>
        <p className='defaultTypographyXSmall' data-type='artworkPrice'>
          {/* Show price if available */}
          {listPrice ? <LocaleCurrency price={listPrice / 100} /> : ''}
        </p>
      </ArtworkInfo>
    </ArtworkItem>
  )
}

export default Artwork

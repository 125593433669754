import { combineReducers } from 'redux'
import abTest, { defaultState as abTestState } from './abTest'
import account, { defaultState as accountState } from './account'
import cart, { defaultState as cartState } from './cart'
import collection, { defaultState as collectionState } from './collection'
import cookie, { defaultState as cookieState } from './cookie'
import favorite, { defaultState as favoriteState } from './favorite'
import follow, { defaultState as followState } from './follow'
import session, { defaultState as sessionState } from './session'
export const defaultState = {
  abTest: abTestState,
  account: accountState,
  cart: cartState,
  collection: collectionState,
  cookie: cookieState,
  favorite: favoriteState,
  follow: followState,
  session: sessionState,
}
export { abTest, cart, collection, favorite, follow, session }
export default combineReducers({
  abTest,
  account,
  cart,
  collection,
  cookie,
  favorite,
  follow,
  session,
})
import React from 'react'
import styled from 'styled-components'
import SALink from 'components/SALink'
import { Serif } from 'components/Serif'
import Div from 'components/Div'
import { LocaleCurrency } from 'components/FormatPrice'
import type { TransformedArtwork, ImageProps } from 'types/Artwork'
const ArtworkInfo = styled(Div)`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${(props) => props.theme.fontSizes[4]}px;
`
const Title = styled.a`
  display: block;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.default};
  :hover {
    color: ${(props) => props.theme.colors.black};
  }
`
const ArtistName = styled.a`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  line-height: ${(props) => props.theme.fontSizes[4]}px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.default};
  display: block;
  :hover {
    color: ${(props) => props.theme.colors.black};
  }
`
const Price = styled(Serif)`
  line-height: ${(props) => props.theme.fontSizes[4]}px;
`
const AltText = styled(Serif)`
  color: ${(props) => props.theme.colors.lightGray};
  margin-top: ${(props) => props.theme.space[3]}px;
  a {
    color: ${(props) => props.theme.colors.lightGray};
    :hover {
      color: ${(props) => props.theme.colors.default};
    }
    text-decoration: none;
  }
`
type Props = TransformedArtwork & ImageProps

// Renders a carousel of additional artworks by the artist.
const BasicArtworkText = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    artistName,
    clickArtwork,
    isSeoValid,
    minPrintPrice,
    originalStatus,
    price,
    productPage,
    productUrl,
    profileUrl,
    title,
  } = props
  return (
    <ArtworkInfo mt={1}>
      <Title
        href={productPage}
        onClick={clickArtwork}
        onKeyUp={clickArtwork}
        rel={isSeoValid ? null : 'nofollow'}
        title={`${title || ''} by ${artistName || ''}`}
      >
        {title}
      </Title>
      <ArtistName
        href={profileUrl}
        rel={isSeoValid ? null : 'nofollow'}
        title={`${artistName || ''} profile`}
      >
        {artistName}
      </ArtistName>
      <div>
        <Price>
          {/* Show price if available */}
          {price &&
            (originalStatus !== 'sold' ? (
              <LocaleCurrency price={price} />
            ) : (
              <AltText>Sold</AltText>
            ))}
        </Price>

        {/* Show min print price if available */}
        {minPrintPrice && (
          <AltText>
            <SALink
              href={productUrl}
              onClick={clickArtwork}
              onKeyUp={clickArtwork}
              rel={isSeoValid ? null : 'nofollow'}
              title={`Prints of ${title || ''}`}
            >
              Prints from <LocaleCurrency price={minPrintPrice} />
            </SALink>
          </AltText>
        )}
      </div>
    </ArtworkInfo>
  )
}

export default BasicArtworkText
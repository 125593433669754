import type { ActionTypes as Action } from 'actions/user/cookie'
import { Constants } from 'actions/user/cookie'
import type { Cookie } from 'types/User/Cookie'
import 'types/User/Cookie'
export const defaultState = {}
type State = Cookie
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_COOKIE:
      return { ...(state || {}), ...(action.payload || {}) }

    default:
      return state
  }
}
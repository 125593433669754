import type { ActionTypes as Action } from 'actions/shared/locale'
import { Constants } from 'actions/shared/locale'
import type { Locale } from 'types/Shared/Locale'
import 'types/Shared/Locale'
export const defaultState = {
  availableCountries: {},
  availableCurrencies: {},
  city: '',
  countryCode: '',
  countryConflict: false,
  hide: false,
  regionCode: '', // selectedCurrency: null,
  // selectedLocale: null,
}
type State = Locale

const Reducer = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_CURRENCY:
      return { ...state, selectedCurrency: action.payload, selectedLocale: action.payload.locale }

    case Constants.CHANGE_LOCALE:
      return { ...state, selectedLocale: action.payload }

    case Constants.HIDE_CURRENCY:
      return { ...state, hide: true }

    case Constants.UPDATE:
      return { ...state, ...(action.payload || {}) }

    default:
      return state
  }
}

export default Reducer
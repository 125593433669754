type isMobileTraffic = boolean
// CONSTANTS
export const Constants = {
  SET_MOBILE_TRAFFIC: 'SET_MOBILE_TRAFFIC',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_MOBILE_TRAFFIC
  payload: isMobileTraffic
}
// ACTION CALLS
export const Actions = {
  SET_MOBILE_TRAFFIC: (payload: isMobileTraffic): ActionTypes => ({
    type: Constants.SET_MOBILE_TRAFFIC,
    payload,
  }),
}
type iOSWebview = boolean
// CONSTANTS
export const Constants = {
  SET: 'SET_IOS_WEBVIEW',
}
// ACTION TYPES
export type FlowTypes = {
  type: typeof Constants.SET
  payload: iOSWebview
}
// ACTION CALLS
export const Actions = {
  SET: (payload: iOSWebview): FlowTypes => ({
    type: Constants.SET,
    payload,
  }),
}
import type { ActionTypes as Action } from 'actions/ops/curate'
import { Constants } from 'actions/ops/curate'
export const defaultState = {}
type State = Record<string, any>
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_CURATE_DATA:
      return { ...state, ...(action.payload || {}) }

    case Constants.CLEAR_CURATION_BOOKMARK:
      return { ...state, bookmark: {} }

    case Constants.CLEAR_CURATION_HISTORY:
      return { ...state, curationHistory: {} }

    case Constants.SET_CURATION_BOOKMARK:
      return { ...state, bookmark: action.payload }

    case Constants.SET_CURATORS:
      return { ...state, curators: action.payload || [] }

    case Constants.SET_CURATION_HISTORY:
      return { ...state, curationHistory: action.payload }

    case Constants.UPDATE_ARTWORK_CURATION_STATUS: {
      const status = state.status || {}
      const { artworkId, direction } = action.payload
      return { ...state, status: { ...status, [artworkId]: direction } }
    }

    case Constants.UPDATING_CURATION_ACTION:
      return { ...state, isUpdating: action.payload || false }

    case Constants.UPDATING_CURATION_ARTWORK_ACTION: {
      const { artworkId, actionType, complete } = action.payload
      const updatingArtworks = { ...state.updatingArtworks }

      // Remove || Add Action to Track/Disable functionality
      if (complete) {
        delete updatingArtworks[artworkId]
      } else {
        updatingArtworks[artworkId] = actionType
      }

      return { ...state, updatingArtworks }
    }

    case Constants.SET_ARTWORK_ID:
      return { ...state, artworkId: action.payload || 0 }

    default:
      return state
  }
}
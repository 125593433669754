import { createSelector } from 'reselect'
import type { Session } from 'types/User/Session'
import 'types/User/Session'
import type { User } from 'types/User'
import 'types/User'
import { getPage } from 'selectors/page'
import { getUser } from './helpers'
export const getSession = createSelector(
  [getUser],
  (user: User): Record<string, any> => user.session || {}
)
export const getPromos = createSelector(
  [getSession],
  (session: Session): Record<string, any> => session && session.promos
)
export const getPromoCampaigns = createSelector(
  [getSession, getPromos],
  (session, promos): Array<Record<string, any>> => session?.promos?.items || []
)
export const getPromoCta = createSelector(
  [getSession, getPromos],
  (session, promos): Record<string, any> => session?.promos?.cta || {}
)
export const getPagePath = createSelector([getPage], (page): string => page.path || '')
import { createSelector } from 'reselect'
import parse from 'lib/url/parse'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import { getShared } from './helpers'
export const getLocation = createSelector(
  [getShared],
  (shared: Shared): string => shared.location || ''
)
export const getPath = createSelector(
  [getLocation],
  (location): string => parse(location).pathname || ''
)
export const getQuery = createSelector(
  [getLocation],
  (location): Record<string, any> => parse(location).query || {}
)
export const getPathQuery = createSelector(
  [getLocation],
  (location): string => parse(location).path || ''
)
export const getSocialLink = createSelector(
  [getLocation],
  (location): string => `https://${parse(location).hostname}${parse(location).path}`
)
// for seo purposes we don't want browse urls with too many segments indexed
export const checkPathLength = (length: number = 3): boolean =>
  createSelector([getPath], (path) => path.split('/').filter(Boolean).length > length)
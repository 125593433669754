// CONSTANTS
export const Constants = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
  RESET_MODAL: 'RESET_MODAL',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CLOSE_MODAL
    }
  | {
      type: typeof Constants.OPEN_MODAL
      payload: string
    }
  | {
      type: typeof Constants.RESET_MODAL
    }
// ACTION CALLS
export const Actions = {
  CLOSE_MODAL: (): ActionTypes => ({
    type: Constants.CLOSE_MODAL,
  }),
  OPEN_MODAL: (payload: string): ActionTypes => ({
    type: Constants.OPEN_MODAL,
    payload,
  }),
  RESET_MODAL: (): ActionTypes => ({
    type: Constants.RESET_MODAL,
  }),
}

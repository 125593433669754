import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as CartActions } from 'actions/user/cart'
import { getCartCount, getCartQuickView } from 'selectors/user/cart'
const mapStateToProps = createStructuredSelector({
  cartCount: getCartCount,
  quickView: getCartQuickView,
})

const mapDispatchToProps = (dispatch) => ({
  applyPromo: () => dispatch([CartActions.APPLY_PROMO()]),
  showCart: (show: boolean) => dispatch([CartActions.SHOW_CART(show)]),
  showCartItem: (sku: string) => dispatch([CartActions.SHOW_CART_ITEM(sku)]),
})

export type ConnectorProps = {
  applyPromo: (...args: Array<any>) => any
  cartCount: number
  quickView: Record<string, any>
  showCart: (...args: Array<any>) => any
  showCartItem: (...args: Array<any>) => any
}
export default connect(mapStateToProps, mapDispatchToProps)
import type { ActionTypes as Action } from 'actions/siteContext'
import { Constants } from 'actions/siteContext'
import type { SiteContext } from 'types/SiteContext'
import 'types/SiteContext'
export const defaultState = 'saatchiart'
type State = SiteContext
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_SITE_CONTEXT:
      return action.payload

    default:
      return state
  }
}
import React from 'react'
import SALink from 'components/SALink'
import TopTilesConnector, {
  ConnectorProps as TopTilesConnectorProps,
} from '../../connectors/TopTiles'

const TopTiles = (
  props: TopTilesConnectorProps
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { topTilesItems } = props

  return (
    <div data-type='TopTilesContainer'>
      <div data-type='scroll'>
        {topTilesItems.map((tileData, key) => {
          const { jpgLarge, jpgSmall, linkText, linkUrl, title } = tileData
          return (
            <div data-type='topTilesTile' key={key} index={key}>
              <SALink href={linkUrl} title={title}>
                <picture data-type='image'>
                  <source
                    type='image/jpg'
                    loading='lazy'
                    media='(max-width:980px)'
                    srcSet={jpgSmall}
                  />
                  <img
                    className='lazyload'
                    width='400'
                    height='400'
                    loading='lazy'
                    data-src={jpgLarge}
                    alt={title}
                    itemProp='image'
                  />
                </picture>
                <noscript>
                  <img width='400' height='400' src={jpgLarge} alt={title} itemProp='image' />
                </noscript>
              </SALink>

              <div data-type='topTilesCollectionInfo'>
                <h6 className='defaultTypographyH6' data-type='tile-title'>
                  {title}
                </h6>
                <SALink href={linkUrl} alt={`${title} Collection`}>
                  <p className='defaultTypographyCtaSmallOrMedium' data-type='tile-description'>
                    {linkText}
                  </p>
                </SALink>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TopTilesConnector(TopTiles)

import type { FavoriteItems, FavoriteMessage, SelectedItem } from 'types/User/Favorite'
// CONSTANTS
export const Constants = {
  CLEAR_MESSAGE: 'CLEAR_FAVORITE_MESSAGE',
  FETCH_COMPLETE: 'FETCH_FAVORITES_COMPLETE',
  FETCH: 'FETCH_FAVORITES',
  SELECT_ARTWORK: 'SELECT_FAVORITE_ARTWORK',
  SHOW_MESSAGE: 'SHOW_FAVORITE_MESSAGE',
  UPDATE_COMPLETE: 'UPDATE_FAVORITES_COMPLETE',
  UPDATE: 'UPDATE_FAVORITES',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CLEAR_MESSAGE
    }
  | {
      type: typeof Constants.FETCH
    }
  | {
      type: typeof Constants.FETCH_COMPLETE
      payload: FavoriteItems
    }
  | {
      type: typeof Constants.SELECT_ARTWORK
      payload: SelectedItem
    }
  | {
      type: typeof Constants.SHOW_MESSAGE
      payload: FavoriteMessage
    }
  | {
      type: typeof Constants.UPDATE_COMPLETE
      payload: FavoriteMessage
    }
  | {
      type: typeof Constants.UPDATE
      payload: FavoriteMessage
    }
// ACTION CALLS
export const Actions = {
  CLEAR_MESSAGE: (): ActionTypes => ({
    type: Constants.CLEAR_MESSAGE,
  }),
  FETCH: (): ActionTypes => ({
    type: Constants.FETCH,
  }),
  FETCH_COMPLETE: (payload: FavoriteItems): ActionTypes => ({
    type: Constants.FETCH_COMPLETE,
    payload,
  }),
  SELECT_ARTWORK: (payload: SelectedItem): ActionTypes => ({
    type: Constants.SELECT_ARTWORK,
    payload,
  }),
  SHOW_MESSAGE: (payload: FavoriteMessage): ActionTypes => ({
    type: Constants.SHOW_MESSAGE,
    payload,
  }),
  UPDATE: (payload: FavoriteMessage): ActionTypes => ({
    type: Constants.UPDATE,
    payload,
  }),
  UPDATE_COMPLETE: (payload: FavoriteMessage): ActionTypes => ({
    type: Constants.UPDATE_COMPLETE,
    payload,
  }),
}
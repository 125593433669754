import type { ActionTypes as Action } from 'actions/shared/client'
import { Constants } from 'actions/shared/client'
import type { Client } from 'types/Shared/Client'
import 'types/Shared/Client'
export const defaultState = {
  setup: false,
}
type State = Client
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.CLIENT_COMPLETE:
      return { ...state, setup: action.payload || false }

    default:
      return state
  }
}
import { $Shape } from 'utility-types'
import type { FlowTypes as Action } from 'actions/algolia'
import { Constants } from 'actions/algolia'
import type { AlgoliaState } from 'types/Algolia'
import 'types/Algolia'
const { ALGOLIA_APPLICATION_ID, ALGOLIA_ENV, ALGOLIA_SEARCH_API, APP_ENV } = process.env
const applicationId: string = ALGOLIA_APPLICATION_ID || ''
const searchApi: string = ALGOLIA_SEARCH_API || ''
const algoliaEnv: string = ALGOLIA_ENV || APP_ENV || 'production'
const searchIndice: string = `${algoliaEnv}_all_artworks`
type State = $Shape<AlgoliaState>
export default function cart(
  state: State = {
    applicationId,
    searchApi,
    searchIndice,
  },
  action: Action
): State {
  switch (action.type) {
    case Constants.UPDATE:
      return { ...state, ...(action.payload || {}) }

    default:
      return state
  }
}
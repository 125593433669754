import React from 'react'
import type { ConnectorProps } from 'connectors/UserPreferences'
import UserPreferencesConnector from 'connectors/UserPreferences'
import { convertDefaultToLocaleCurrency, formatPrice } from './helpers'
type Props = ConnectorProps & {
  price: number
  decimals: true
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { decimals, price, selectedCurrency } = props
  return (
    <>
      {formatPrice(
        convertDefaultToLocaleCurrency(price, selectedCurrency, decimals),
        selectedCurrency,
        decimals
      )}
    </>
  )
}

export default UserPreferencesConnector(Component)
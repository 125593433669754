// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import * as AccountStyles from 'styles/account'
export const DragItem = styled(AccountStyles.Row)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`
export const DragArea = styled.div`
  position: relative;
  user-select: none;
  width: ${(props) => props.width || `100%`};
  height: ${(props) => props.height || `200px`};
  overflow: hidden;
  border: ${(props) => (props.border ? `1px dashed #ff0099` : 'none')};
  &[data-type='horizontal'] {
    ${DragItem} {
      display: flex;
      height: 100%;
      will-change: transform;
      ${(props) => !props.isDragging && 'transition: 0.8s ease transform'};
    }
  }
  * {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`
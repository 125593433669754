import type { FlowTypes as Action } from 'actions/search'
import { Constants } from 'actions/search'
import type { Search } from 'types/Search'
import 'types/Search'
type State = Search
export default function search(state: State = {}, action: Action): State {
  switch (action.type) {
    case Constants.SET:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
// CONSTANTS
export const Constants = {
  DELETE_ADDRESS_ITEM: 'DELETE_MY_ADDRESS_ITEM',
  ADD_ADDRESS_ITEM: 'ADD_MY_ADDRESS_ITEM',
  SET_USER_ACCOUNT_INFORMATION: 'SET_USER_ACCOUNT_INFORMATION',
  SET_USER_VACATION_STATUS: 'SET_USER_VACATION_STATUS',
  TOGGLE_USER_VACATION_STATUS: 'TOGGLE_USER_VACATION_STATUS',
  UPDATE_USER_CHECKIN_STATUS: 'UPDATE_USER_CHECKIN_STATUS',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.DELETE_ADDRESS_ITEM
      payload: string
    }
  | {
      type: typeof Constants.ADD_ADDRESS_ITEM
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_USER_ACCOUNT_INFORMATION
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_USER_VACATION_STATUS
      payload: boolean
    }
  | {
      type: typeof Constants.TOGGLE_USER_VACATION_STATUS
      payload: null
      meta: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_USER_CHECKIN_STATUS
    }
// ACTION CALLS
export const Actions = {
  DELETE_ADDRESS_ITEM: (payload: string): ActionTypes => ({
    type: Constants.DELETE_ADDRESS_ITEM,
    payload,
  }),
  ADD_ADDRESS_ITEM: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.ADD_ADDRESS_ITEM,
    payload,
  }),
  SET_USER_ACCOUNT_INFORMATION: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_USER_ACCOUNT_INFORMATION,
    payload,
  }),
  SET_USER_VACATION_STATUS: (payload: boolean): ActionTypes => ({
    type: Constants.SET_USER_VACATION_STATUS,
    payload,
  }),
  TOGGLE_USER_VACATION_STATUS: (payload: null, meta: Record<string, any>): ActionTypes => ({
    type: Constants.TOGGLE_USER_VACATION_STATUS,
    payload,
    meta,
  }),
  UPDATE_USER_CHECKIN_STATUS: (): ActionTypes => ({
    type: Constants.UPDATE_USER_CHECKIN_STATUS,
  }),
}
import React, { useState } from 'react'
import Countdown from './Countdown'
import CouponCode from './CouponCode'
import { Promobar } from './styles'
import { PromoCampaign } from './Types'
import CaretIcon from 'svg/caret.svg'
import classNames from 'classnames'
import styles from './Promobar.module.scss'

type Props = {
  addMessage: (...args: Array<any>) => any
  currentPromo: PromoCampaign
}

const PromoCampaignComponent = (props: Props) => {
  const { addMessage, currentPromo } = props
  const [isMobile, setIsMobile] = useState(false)
  const status = isMobile ? 'show' : 'hide'
  const {
    color,
    // : "A50202"
    countdown,
    // : "0"
    mobileTagline,
    // : "Joyful Art for Sunnier Days"
    periodEndUnix,
    // : 1657339200
    tagline,
    // : "Joyful Artwork for Sunnier Days"
    tiers, // : (2) [{…}, {…}]
  } = currentPromo || {}
  return (
    <Promobar data-section='promo_bar' background={color}>
      <div className='wrapper'>
        <div
          className='mobile'
          onClick={() => setIsMobile(!isMobile)}
          onKeyPress={() => setIsMobile(!isMobile)}
          role='button'
          tabIndex='0'
        >
          <p
            className={classNames('defaultTypographyH6', 'mobile-tagline')}
            data-style='paragraph-wp'
          >
            {mobileTagline}
          </p>
          <p className={classNames('offers-toggle')} data-style='paragraph-wp'>
            <span className={`caret ${status}`} />
            <CaretIcon className={`caret ${status}`} />
          </p>
        </div>
        <div className={`layout ${status}`}>
          <div className={styles.promoCampaignTagline}>
            <p data-type='tagline' data-style='paragraph-wp'>
              {tagline}
              {countdown === '1' && <Countdown endTimestamp={periodEndUnix} />}
            </p>
          </div>
          <div data-section='coupon-list'>
            {tiers.map((tier, key) => (
              <CouponCode
                key={key}
                {...tier}
                toggleClose={() => setIsMobile(false)}
                showError={(message) =>
                  addMessage({
                    type: 'error',
                    messages: [message],
                  })
                }
              />
            ))}
          </div>
        </div>
      </div>
    </Promobar>
  )
}

export default PromoCampaignComponent

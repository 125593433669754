import { createSelector } from 'reselect'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import type { FlashMessage } from 'types/Shared/FlashMessage'
import { getShared } from './helpers'
export const getFlashMessage = createSelector(
  [getShared],
  (shared: Shared): FlashMessage => shared.flashMessage || {}
)
export const getMessages = createSelector(
  [getFlashMessage],
  (flashMessage: FlashMessage): Array<string> => flashMessage.messages || []
)
export const hasMessages = createSelector(
  [getMessages],
  (messages: Array<any>): boolean => Boolean(messages && messages.length) || false
)
export const getType = createSelector(
  [getFlashMessage],
  (flashMessage: FlashMessage): string => flashMessage.type || ''
)
import styled from 'styled-components'
import { space, fontSize, color, display, textAlign, width, height } from 'styled-system'
export default styled.div`
  margin: 0;
  ${space};
  ${width}
  ${height}
  ${fontSize};
  ${color};
  ${display};
  ${textAlign};
  ${(props) => props.textCenter && 'text-align: center;'};
  ${(props) => props.textRight && 'text-align: right;'};
  ${(props) => props.inlineBlock && 'display: inline-block'};
  ${(props) => props.inline && 'display: inline'};
  ${(props) => props.bold && 'font-weight: bold'};
`
import React, { ReactElement } from 'react'
import { URLParse } from 'components/GeoLocation/helpers'
import GeoLocationConnector from 'connectors/GeoLocation'
import { ConnectedProps } from "react-redux";

type Props = ConnectedProps<typeof GeoLocationConnector> & React.HTMLProps<HTMLAnchorElement>

const Component: React.FC<Props> = (props): ReactElement => {
  const { children, geoLocation, href: url } = props
  const { storeLocale } = geoLocation
  // TODO - GEO PRICE - remove ternary -> const link =  URLParse(url, storeLocale)
  let link = url

  // Some Components use an <a /> tab but don't have a link, skip this LOGIC
  if (url) {
    const data = URLParse(url, storeLocale)
    const { href } = data
    link = href
  }

  // Copy/Clone All Props
  const anchorProps: Partial<Props> = { ...props }
  // Delete Attributes we DO NOT NEED
  delete anchorProps.children
  delete anchorProps.geoLocation
  delete anchorProps.geoLocationMetaData
  // Update HREF - Leave Log if we need to check Server Side
  // console.log({ link, hasFeatureFlagsGeoPricesForAustralia })
  anchorProps.href = link
  return <a {...{ ...anchorProps }}>{children}</a>
}

export default GeoLocationConnector(Component)

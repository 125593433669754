// CONSTANTS
export const Constants = {
  INSTANA_USER_SETUP: `INSTANA_USER_SETUP`,
  SET_TRACE_ID: 'INSTANA_SET_TRACE_ID',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.INSTANA_USER_SETUP
    }
  | {
      type: typeof Constants.SET_TRACE_ID
      payload: string
    }
// ACTION CALLS
export const Actions = {
  INSTANA_USER_SETUP: (): ActionTypes => ({
    type: Constants.INSTANA_USER_SETUP,
  }),
  SET_TRACE_ID: (payload: string): ActionTypes => ({
    type: Constants.SET_TRACE_ID,
    payload,
  }),
}
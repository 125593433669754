import React from 'react'
import SALink from 'components/SALink'
import { Promobar } from './styles'
import { PromoCta } from './Types'
import classNames from 'classnames'

type Props = {
  currentCTA: PromoCta
}

const PromoCtaComponent = (props: Props) => {
  const { currentCTA } = props
  const { color, dataLayerEvent, linkText, linkURL, mobileTagline, tagline } = currentCTA || {}

  const onClickPromoCta = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: dataLayerEvent || 'cta promo bar',
      })
    }
  }

  return (
    <Promobar data-section='promo_bar' background={color}>
      <div className='wrapper'>
        <div className='mobile'>
          <p
            className={classNames('defaultTypographyH6', 'mobile-tagline')}
            data-style='paragraph-wp'
          >
            {mobileTagline}
          </p>

          <p
            className={classNames('defaultTypographyCtaSmall', 'link', 'defaultTextUppercase')}
            data-style='paragraph-wp'
          >
            <SALink href={linkURL} onClick={onClickPromoCta}>
              {linkText}
            </SALink>
          </p>
        </div>
        <div className='layout promoCta'>
          <p className={classNames('defaultTypographyH6', 'tagline')} data-style='paragraph-wp'>
            {tagline}
          </p>
          <p
            className={classNames('defaultTypographyCtaMedium', 'link', 'defaultTextUppercase')}
            data-style='paragraph-wp'
          >
            <SALink href={linkURL} onClick={onClickPromoCta}>
              {linkText}
            </SALink>
          </p>
        </div>
      </div>
    </Promobar>
  )
}

export default PromoCtaComponent

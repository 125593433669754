import type { ActionTypes as Action } from 'actions/user/collection'
import { Constants } from 'actions/user/collection'
import type { Collection } from 'types/User/Collection'
import 'types/User/Collection'
export const defaultState = {
  byArtworkId: {},
  collections: [],
  collectionsLoaded: false,
  tooltip: {
    currentArtwork: 0,
    isLoaded: false,
    items: [],
    search: '',
  },
}
type State = Collection
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_COLLECTIONS: {
      return { ...state, collections: action.payload || [], collectionsLoaded: true }
    }

    case Constants.UPDATE_COLLECTIONS: {
      // Add New Item to Collections
      const { collections } = state
      return { ...state, collections: [action.payload, ...collections] }
    }

    case Constants.SET_COLLECTION_TOOLTIP: {
      const { tooltip } = state
      return { ...state, tooltip: { ...tooltip, ...action.payload } }
    }

    case Constants.SET_COLLECTIONS_BY_ARTWORKID: {
      const { byArtworkId } = state
      return { ...state, byArtworkId: { ...byArtworkId, ...action.payload } }
    }

    case Constants.SET_COLLECTION_TOOLTIP_CURRENT_ARTWORK: {
      const { tooltip } = state
      return { ...state, tooltip: { ...tooltip, currentArtwork: action.payload } }
    }

    case Constants.UPDATE_COLLECTION_TOOLTIP_SEARCH: {
      const { tooltip } = state
      return { ...state, tooltip: { ...tooltip, search: action.payload } }
    }

    default:
      return state
  }
}
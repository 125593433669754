import type { Modals } from 'types/Authentication'
export const Constants = {
  PROMPT_LOGIN: 'PROMPT_AUTHENTICATION_MODAL',
  SHOW_MODAL: 'SHOW_AUTHENTICATION_MODAL',
}
export type ActionTypes =
  | {
      type: typeof Constants.PROMPT_LOGIN
    }
  | {
      type: typeof Constants.SHOW_MODAL
      payload: Modals
    }
// ACTION CALLS
export const Actions = {
  PROMPT_LOGIN: (): ActionTypes => ({
    type: Constants.PROMPT_LOGIN,
  }),
  SHOW_MODAL: (payload: Modals): ActionTypes => ({
    type: Constants.SHOW_MODAL,
    payload,
  }),
}
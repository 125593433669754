import { createSelector } from 'reselect'
import { getPageData } from 'selectors/page'
import type {
  HeroCarouselItem,
  LargeCarouselItem,
  CarouselSmallItem,
  FeaturedStoriesItem,
} from '../types'
export { getPageData, getPageParams, getPagePath } from 'selectors/page'
export const getLargeCarouselItems = createSelector(
  getPageData,
  (pageData): Array<LargeCarouselItem> => pageData.carouselLarge || []
)
export const getHeroItems = createSelector(
  getPageData,
  (pageData): Array<HeroCarouselItem> => pageData.heroCarousel || []
)
export const getFeaturedArtistItems = createSelector(
  getPageData,
  (pageData): Array<HeroCarouselItem> => pageData.featuredArtists || []
)
export const getTopTilesItems = createSelector(
  getPageData,
  (pageData): Array<HeroCarouselItem> => pageData.topTiles || []
)
export const getCarouselData = createSelector(
  getPageData,
  (pageData): Array<Record<string, any>> => pageData.carouselSmall || []
)
export const getCarouselDataMultiple = createSelector(
  getCarouselData,
  (carousels): Array<CarouselSmallItem> => (carousels || []).filter((carousel, i) => i < 2) || []
)
export const getFeaturedStoriesData = createSelector(
  getPageData,
  (pageData): Array<FeaturedStoriesItem> => pageData.featuredStories || []
)
export const getFeaturedStories = createSelector(
  getFeaturedStoriesData,
  (stories): Array<FeaturedStoriesItem> => stories.filter((story, i) => i < 3) || []
)
export const getKeyword = createSelector(
  [getPageData],
  (pageData): string => pageData.keyword || ''
)
export const getMetaData = createSelector(
  [],
  (): Record<string, any> => {
    const title = 'Artwork: Buy Original Art Online, Paintings & More'
    const description =
      'Saatchi Art is the best place to buy artwork online. Find the perfect original paintings, fine art photographs and more from the largest selection of original art in the world.'
    return {
      description,
      title,
      og: {
        description,
        title,
        type: 'article',
      },
      preloadImages: [],
      robots: true,
      schema: {
        webpage: {
          description,
          headline: title,
        },
      },
    }
  }
)
export const getDataLayer = createSelector(
  [getKeyword],
  (keyword): Record<string, any> => ({
    keyword,
    environment: 'production',
    pagetype: 'homepage',
  })
)
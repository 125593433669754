import React, { useState } from 'react'
import { putCouponCode } from 'apis/cart'
import CartConnector, { ConnectorProps as CartProps } from 'connectors/Cart'
import LocaleConnector, { ConnectorProps as LocaleProps } from 'connectors/Locale'
import { formatPrice, convertDefaultToLocaleCurrency } from 'components/FormatPrice/helpers'
import { CouponCode } from './styles'
import styles from './Promobar.module.scss'
import ContainedCheckIcon from 'svg/contained-check.svg'
import classNames from 'classnames'

const APPLY = 'APPLY CODE:'
const APPLIED = 'COUPON APPLIED!'

type Props = CartProps &
  LocaleProps & {
    code: string
    // "JOYFULSUMMER20"
    minimum: string
    // "5500"
    showError: (...args: Array<any>) => any
    title: string
    // "20% Off Originals of"
    toggleClose: (...args: Array<any>) => any
  }
type State = {
  isBlocked: boolean
  // API Call in progress, do NOT allow multiple calls
  text: string // Button Text
}
const DEFAULT_STATE: State = {
  isBlocked: false,
  text: APPLY,
}

const CouponCodeComponent = (props: Props) => {
  const [state, updateState] = useState(DEFAULT_STATE)
  const { isBlocked, text } = state
  const { applyPromo, code, minimum, selectedCurrency, showError, title, toggleClose } = props
  const amount = formatPrice(
    convertDefaultToLocaleCurrency(Number(minimum), selectedCurrency),
    selectedCurrency
  )

  const applyCoupon = async () => {
    if (isBlocked) return
    updateState((prev) => ({ ...prev, isBlocked: true }))
    const { success } = await putCouponCode(code)

    if (success) {
      updateState((prev) => ({ ...prev, text: APPLIED }))
      setTimeout(function() {
        updateState(DEFAULT_STATE)
        toggleClose()
      }, 5000)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'promobar_coupon',
          message: code,
        })
      }

      // Lets FIRE a Redux Event
      applyPromo()
      return
    }

    // Fail - Reset
    updateState(DEFAULT_STATE)
    showError('Unable to apply coupon.')
  }

  return (
    <CouponCode onClick={applyCoupon} data-type='item'>
      <div data-type='item-container' className={styles.couponCodeWrapper}>
        <div
          className={classNames(styles.couponCodeContainer, {
            [styles.couponCodeContainerApplied]: text === APPLIED,
          })}
        >
          <div data-type='info'>
            <p className={styles.couponCodeTitle} data-type='title' data-style='paragraph-wp'>
              {title} {amount !== '$0' ? `${amount}+` : ''}
            </p>
          </div>
          <div className={styles.couponCodeApplyBox}>
            <p className={styles.couponCodeText} data-type='apply' data-style='paragraph-wp'>
              {text}
            </p>
            {text !== APPLIED && (
              <p className={styles.couponCodeCode} data-type='code' data-style='paragraph-wp'>
                {code}
              </p>
            )}
          </div>
        </div>
      </div>
      {text === APPLIED && (
        <div className={styles.couponCodeAppliedBox} data-type='item-container'>
          <ContainedCheckIcon />
          <p data-style='paragraph-wp'>{code}</p>
        </div>
      )}
    </CouponCode>
  )
}

export default CartConnector(LocaleConnector(CouponCodeComponent))

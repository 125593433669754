import React from 'react'
import type { ConnectorProps as LayoutProps } from 'containers/Layout'
import LayoutConnector from 'containers/Layout'
import { LoaderOverlay, Loader } from './styles'

const Component = ({
  isReady,
}: LayoutProps): React.ReactElement<React.ComponentProps<any>, any> | null => {
  if (isReady) {
    return null
  }

  return (
    <LoaderOverlay>
      <Loader />
    </LoaderOverlay>
  )
}

export default LayoutConnector(Component)
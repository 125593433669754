// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import { HorizontalContainer } from 'components/Carousel/DraggableHorizontalScroller/styles'
import { DragArea } from 'components/Draggable/styles'
import { MOBILE_WIDTH, TABLET_WIDTH, MAX_WIDTH_NARROW } from '../../styles'
// Artwork item
export const ArtworkInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: ${(props) => props.theme.fontSizes[2]}px;
  width: 100%;
  margin-top: 5px;
  text-align: left;
  [data-type='artworkPrice'] {
    cursor: default;
  }
`
export const ArtworkItem = styled.div`
  width: 100%;
  flex-direction: column;
  margin-right: 14px;
  &:last-child {
    margin-right: 0;
  }
  display: flex;
  a {
    color: ${(props) => props.theme.colors.lightGray};
    text-decoration: none;
    transition: 0.2s ease color;
    &:hover {
      color: ${(props) => props.theme.colors.default};
    }
  }
  img {
    width: 100%;
    height: auto;
    ::after {
      content: '';
      display: block;
      padding-bottom: 90%;
    }
    display: inline-block;
    background: ${(props) => props.theme.colors.backgroundGray};
    @media (max-width: ${MOBILE_WIDTH}px) {
      width: 207px;
      height: auto;
    }
  }
  [data-type='artworkTitle'],
  [data-type='artistName'],
  [data-type='artworkPrice'] {
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.colors.default};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    font-size: 16px;
  }
  [data-type='artworkTitle'],
  [data-type='artistName'] {
    max-width: 207px;
    :hover {
      color: ${(props) => props.theme.colors.black};
    }
    @media (max-width: ${MAX_WIDTH_NARROW}px) {
      max-width: 147px;
    }
    @media (max-width: ${TABLET_WIDTH}px) {
      max-width: 90px;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      max-width: 207px;
    }
  }
  [data-type='artistName'] {
    font-size: 12px;
    margin: 4px 0;
  }
`
export const CarouselSmall = styled.div`
  width: 100%;
  ${HorizontalContainer} {
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${MOBILE_WIDTH}px) {
      /* !important needed to override drag on desktop/tablet */
      transform: none !important;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
      width: auto;
    }
  }
  > [data-type='carousel-core'] {
    padding: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  [data-type='carousel-header'] {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 55px 0 15px;
    @media (max-width: 550px) {
      align-items: flex-start;
    }
    h2 {
      color: ${(props) => props.theme.colors.default};
      margin: 15px 40px 15px 0;
      font-size: 40px;
      display: flex;
      align-self: flex-start;
      margin: 0;
      font-weight: normal;
      text-align: left;
      @media (max-width: ${MOBILE_WIDTH}px) {
        font-size: 24px;
      }
    }
    a {
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.secondary};
      display: flex;
      align-self: flex-start;
      @media (max-width: ${MOBILE_WIDTH}px) {
        margin: 7px 0 5px;
      }
    }
  }

  ${DragArea} {
    border: none;
    height: 360px;
    @media (max-width: ${TABLET_WIDTH}px) {
      height: 320px;
    }
  }
`
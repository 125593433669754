import 'types/Shared/Locale'
import type { LargeCarouselItem } from '../types'
import '../types'
export const SplitArray = (tiles: Array<LargeCarouselItem>): Array<any> => {
  const arrs = [[], []]

  for (let i = 0; i < tiles.length; i++) arrs[i % 2].push(tiles[i])

  // const [a, b] = arrs
  return arrs
}
export const getShortcutsMenuList = (): Array<Record<string, any>> => {
  const menuList = [
    {
      title: 'Paintings',
      link: '/paintings',
    },
    {
      title: 'Abstract Art',
      link: '/all/abstract',
    },
    {
      title: 'Oil Paintings',
      link: '/paintings/oil',
    },
    {
      title: 'Landscapes',
      link: '/all/landscape',
    },
    {
      title: 'Large Works',
      link: '/all?size=large',
    },
    {
      title: 'New This Week',
      link: '/collections/new-this-week',
    },
    {
      title: 'Curated Collections',
      link: '/collections/featured',
    },
    {
      title: 'Featured',
      link: '/art-collection/Featured-Paintings/1754780/657827/view',
    },
    {
      title: 'Sculpture',
      link: '/sculpture',
    },
  ]
  return menuList
}
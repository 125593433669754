// This is are DEFAULT `saatchi` theme
import React, { PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'
import { saatchiart } from 'lib/theme'

function Theme(props: PropsWithChildren): React.ReactElement<React.ComponentProps<any>, any> {
  const { children } = props
  return <ThemeProvider theme={saatchiart}>{children}</ThemeProvider>
}

export default Theme

export default [
  {
    min: 0,
    max: 500,
  },
  {
    min: 500,
    max: 1000,
  },
  {
    min: 1000,
    max: 2000,
  },
  {
    min: 2000,
    max: 5000,
  },
]
// CONSTANTS
export const Constants = {
  SET_STORE_LOCALE: 'SET_STORE_LOCALE',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_STORE_LOCALE
  payload: string
}
// ACTION CALLS
export const Actions = {
  SET_STORE_LOCALE: (payload: string): ActionTypes => ({
    type: Constants.SET_STORE_LOCALE,
    payload,
  }),
}
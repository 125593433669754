import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getTopTilesItems } from '../selectors'
import type { TopTilesItem } from '../types'
const mapStateToProps = createStructuredSelector({
  topTilesItems: getTopTilesItems,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  topTilesItems: Array<TopTilesItem>
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect
import type { FlashMessage } from 'types/Shared/FlashMessage'
import 'types/Shared/FlashMessage'
// CONSTANTS
export const Constants = {
  ADD: 'ADD_FLASH_MESSAGE',
  CLEAR: 'CLEAR_FLASH_MESSAGE',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.ADD
      payload: FlashMessage
    }
  | {
      type: typeof Constants.CLEAR
    }
// ACTION CALLS
export const Actions = {
  ADD: (payload: FlashMessage): ActionTypes => ({
    type: Constants.ADD,
    payload,
  }),
  CLEAR: (): ActionTypes => ({
    type: Constants.CLEAR,
  }),
}
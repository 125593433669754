import { createSelector } from 'reselect'
import type { Address, Customer } from 'types/Customer'
export const getCustomer = (state: Record<string, any>): Customer => state.customer || {}
export const getCustomerEmail = createSelector([getCustomer], ({ email }): string => email || '')
export const getCustomerId = createSelector(
  [getCustomer],
  ({ customerId }): string => customerId || ''
)
export const getCustomerAddressBook = createSelector(
  [getCustomer],
  ({ addressBook }): Array<Address> => addressBook || []
)
export const getCustomerWallet = createSelector(
  [getCustomer],
  ({ wallet }): Array<Record<string, any>> => wallet || []
)
export const getCustomerWalletItemsCount = createSelector(
  [getCustomerWallet],
  (wallet): number => wallet.length
)
export const getCustomerAddressBookItemByIdOrNull = (addressBookItemId: string) =>
  createSelector(
    [getCustomerAddressBook],
    (addressBook) => {
      return addressBook.find((item) => item.addressBookItemId === addressBookItemId) || null
    }
  )
export const getCustomerAddressBookItemById = (addressBookItemId: string): Address | object =>
  createSelector(
    [getCustomerAddressBook],
    (addressBook): Record<string, any> => {
      return addressBook.find((item) => item.addressBookItemId === addressBookItemId) || {}
    }
  )
export const getCustomerWalletItemById = (paymentMethodId: string) =>
  createSelector(
    [getCustomerWallet],
    (wallet): Record<string, any> => {
      return wallet.find((item) => item.paymentMethodId === paymentMethodId) || {}
    }
  )

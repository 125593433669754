import type { ActionTypes as Action } from 'actions/shared/disableEpics'
import { Constants } from 'actions/shared/disableEpics'
export const defaultState = false
type State = boolean
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.DISABLE_EPICS:
      return action.payload

    default:
      return state
  }
}
import { createSelector } from 'reselect'
import type { SiteContext } from 'types/SiteContext'
import 'types/SiteContext'
export const getSiteContext = (state: Record<string, any>): string =>
  state.siteContext || 'saatchiart'
export const isLimitedContext = createSelector(
  [getSiteContext],
  (siteContext: SiteContext): boolean => siteContext === 'limited'
)
export const isSaatchiContext = createSelector(
  [getSiteContext],
  (siteContext: SiteContext): boolean => siteContext === 'saatchiart'
)
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getFeaturedStories } from '../selectors'
import type { FeaturedStoriesItem } from '../types'
import '../types'
const mapStateToProps = createStructuredSelector({
  featuredStories: getFeaturedStories,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  featuredStories: Array<FeaturedStoriesItem>
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect
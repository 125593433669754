import { Constants } from 'actions/iOSWebview'
import type { FlowTypes as Action } from 'actions/iOSWebview'
type State = boolean
export default function iOSWebview(state: State = false, action: Action): State {
  switch (action.type) {
    case Constants.SET:
      return action.payload

    default:
      return state
  }
}
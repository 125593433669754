const dateToPST = () => {
  const date = new Date()
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60000
  return Math.floor(utcTime / 1000) // Convert to Unix Timestamp
}

export const getCurrentPromoCampaignData = ({ localeCountryCode, promoCampaigns }) => {
  const pstTimestamp = dateToPST()
  let promoBar = null
  let currentCampaign = []
  const campaigns = promoCampaigns.filter(
    (campaign) => campaign.periodStartUnix < pstTimestamp && campaign.periodEndUnix > pstTimestamp
  )

  // Filter by Date Period
  if (campaigns && campaigns.length) {
    // Use .join to handle multiple countries in an array
    const campaignsInCurrentCountry = campaigns.filter(
      (campaign) =>
        campaign.countries &&
        campaign.countries.length &&
        campaign.countries.join().includes(localeCountryCode)
    )

    if (campaignsInCurrentCountry.length) {
      currentCampaign = campaignsInCurrentCountry[0] // Pull by ISO Code
    } else {
      // Null arrays show length, so we check to see that there's no letter in the string
      currentCampaign = campaigns.filter(
        (campaign) => !/^[a-zA-Z]/.test(campaign.countries.join())
      )[0] // First Available Campaign not Country Enabled
    }

    promoBar = {
      ...currentCampaign,
      // Filter Out by DEVICE
      tagline: currentCampaign && currentCampaign.tagline ? currentCampaign.tagline : '',
      mobileTagline:
        currentCampaign && currentCampaign.mobileTagline ? currentCampaign.mobileTagline : '',
      countdown: (currentCampaign && currentCampaign.countdown) || false,
      tiers: currentCampaign ? currentCampaign.tiers : [],
      showToNonAuthenticatedUser:
        (currentCampaign && currentCampaign.showToNonAuthenticatedUser) || false,
    }
  }

  return promoBar
}
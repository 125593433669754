/*
 * Building a component that will check if it is in VIEW
 * This component is for a 1-TIME use, if you need to keep measuring look at `onScreen` hook
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * https://developers.google.com/web/updates/2016/04/intersectionobserver
 * Need a Polyfill for IE -> https://caniuse.com/#feat=intersectionobserver
 */
import { useEffect, useState } from 'react'
const OBSERVER_PARAMS = {
  threshold: 0,
  root: null,
  rootMargin: '0%',
}
let observer

function useIntersectionObserver(refElement: Record<string, any>): Record<string, any> {
  const [isSetup, setIsSetup] = useState(false)
  const [hasViewed, setHasViewed] = useState(false)

  function handleIntersect(entries: Array<any>) {
    entries.forEach((entry: Record<string, any>) => {
      if (entry && entry.isIntersecting) {
        setHasViewed(true)
      }
    })
  }

  useEffect(() => {
    const hasIOSupport = typeof IntersectionObserver !== 'undefined'
    const element = refElement ? refElement.current : null

    if (element && hasIOSupport) {
      if (!isSetup) {
        observer = new IntersectionObserver(handleIntersect, OBSERVER_PARAMS)
        observer.observe(element)
        setIsSetup(true)
      }
    }

    // Destroy Listener - Unmount
    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [refElement])
  return {
    hasViewed,
    isSetup,
    setIsSetup,
    reset: () => setHasViewed(false),
  }
}

export default useIntersectionObserver
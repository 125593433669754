import type { MeasurementUnitParams } from 'types/User/Session'
import 'types/User/Session'
// CONSTANTS
export const Constants = {
  FETCH_SESSION: 'FETCH_SESSION',
  SESSION_DATALAYER: 'SETUP_SESSION_DATALAYER',
  SET_SESSION: 'SET_SESSION',
  UPDATE_SESSION: 'UPDATE_SESSION',
  UPDATE_MEASUREMENT: 'UPDATE_MEASUREMENT_UNIT_API',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.FETCH_SESSION
    }
  | {
      type: typeof Constants.SESSION_DATALAYER
    }
  | {
      type: typeof Constants.SET_SESSION
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_SESSION
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_MEASUREMENT
      payload: MeasurementUnitParams
    }
// ACTION CALLS
export const Actions = {
  FETCH_SESSION: (): ActionTypes => ({
    type: Constants.FETCH_SESSION,
  }),
  SESSION_DATALAYER: (): ActionTypes => ({
    type: Constants.SESSION_DATALAYER,
  }),
  SET_SESSION: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_SESSION,
    payload,
  }),
  UPDATE_SESSION: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_SESSION,
    payload,
  }),
  UPDATE_MEASUREMENT: (payload: MeasurementUnitParams): ActionTypes => ({
    type: Constants.UPDATE_MEASUREMENT,
    payload,
  }),
}
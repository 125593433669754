import type { ActionTypes as Action } from 'actions/shared/storeLocale'
import { Constants } from 'actions/shared/storeLocale'
// This is used for GEO PRICING
// Passed in from XGateway - Prefix for URLS
export const defaultState = 'en-us'
// storeCountry: 'US' // Redux => user.cookie.userPreferences.country
// storeDestinationZone: 'US' // Redux => user.cookie.userPreferences.destinationZone
type State = string
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_STORE_LOCALE:
      return action.payload || defaultState

    default:
      return state
  }
}
import { useEffect, useState } from 'react'
type Props = {
  endTimestamp: number // 1657339200
}
type State = {
  totalMilliSeconds?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
  expired: boolean
}
const DEFAULT_STATE: State = {
  expired: true,
}
let timer = null

const useTimer = (props: Props): Record<string, any> => {
  const { endTimestamp } = props
  const [state, handleState] = useState(DEFAULT_STATE)

  const stopTimer = function () {
    clearInterval(timer)
    timer = null
  }

  const getTimeRemaining = (): Record<string, any> => {
    // Flow Complains - Wrap Date in String => Date.parse(String(new Date()))
    const totalMilliSeconds =
      Date.parse(String(new Date(endTimestamp * 1000))) - Date.parse(String(new Date()))
    const seconds = Math.floor((totalMilliSeconds / 1000) % 60)
    const minutes = Math.floor((totalMilliSeconds / 1000 / 60) % 60)
    const hours = Math.floor(totalMilliSeconds / (1000 * 60 * 60))

    /* % 24 */
    const days = Math.floor(totalMilliSeconds / (1000 * 60 * 60 * 24))
    return {
      totalMilliSeconds,
      days,
      hours,
      minutes,
      seconds,
      expired: totalMilliSeconds < 0,
    }
  }

  const startTimerCountdown = () => {
    const remainingTime = getTimeRemaining()

    if (remainingTime.expired) {
      stopTimer()
    }

    handleState(remainingTime)
  }

  useEffect(() => {
    timer = setInterval(() => {
      startTimerCountdown()
    }, 1000)
    return stopTimer
  }, [])
  // Return
  return { ...state, stopTimer }
}

export default useTimer
import { combineReducers } from 'redux'
import type { ActionTypes as Action } from 'actions/user/favorite'
import { Constants } from 'actions/user/favorite'
import type { FavoriteItems, FavoriteMessage, SelectedItem } from 'types/User/Favorite'
export const defaultState = {
  items: [],
  message: {},
  selected: null,
}

const items = (state: FavoriteItems = [], action: Action): FavoriteItems => {
  switch (action.type) {
    case Constants.UPDATE:
      // Add Item or Remove
      if (action.payload.favorite) {
        return (state || []).filter(
          (val) => action.payload && action.payload.id && action.payload.id !== val
        )
      }

      return [...(state || []), action.payload.id]

    case Constants.FETCH_COMPLETE:
      return action.payload

    default:
      return state
  }
}

const message = (state: FavoriteMessage = {}, action: Action): FavoriteMessage => {
  // Show ID and Favorite after Favoriting Artwork
  switch (action.type) {
    case Constants.SHOW_MESSAGE:
      return action.payload

    case Constants.CLEAR_MESSAGE:
      return {}

    default:
      return state
  }
}

const selected = (state: SelectedItem = null, action: Action): SelectedItem => {
  // Show ID and Favorite after Favoriting Artwork
  switch (action.type) {
    case Constants.SELECT_ARTWORK:
      // Set artwork for Favorite hook to complete
      return action.payload

    default:
      return state
  }
}

export default combineReducers({
  items,
  message,
  selected,
})
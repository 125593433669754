import { combineReducers } from 'redux'
import algolia from './algolia'
import authentication from './authentication'
import iOSWebview from './iOSWebview'
import { cms, curate, shipmentDetails } from './ops'
import page from './page'
import search from './search'
import shared from './shared'
import user from './user'
import screen from './screen'
import siteContext from './siteContext'
// Export ALL Reducers
export { algolia, iOSWebview, page, search, shared, user }
// Ops Reducers
export const opsCurationReducer = {
  ops: combineReducers({
    curate,
  }),
  page,
  screen,
  search,
  shared,
  siteContext,
  user,
}
export const opsCMSReducer = {
  ops: combineReducers({
    cms,
  }),
  page,
  screen,
  search,
  shared,
  siteContext,
  user,
}
export const opsShipmentReducer = {
  ops: combineReducers({
    shipmentDetails,
  }),
  page,
  screen,
  search,
  shared,
  siteContext,
  user,
} // Export Defaults

const rootReducer = {
  authentication,
  page,
  screen,
  search,
  shared,
  siteContext,
  user,
}

export type ReduxState = {
  authentication: ReturnType<typeof authentication>
  page: ReturnType<typeof page>
  screen: ReturnType<typeof screen>
  search: ReturnType<typeof search>
  shared: ReturnType<typeof shared>
  siteContext: ReturnType<typeof siteContext>
  user: ReturnType<typeof user>
}

export default rootReducer

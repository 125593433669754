import React from 'react'
import SALink from 'components/SALink'
import FeaturedStoriesConnector, {
  ConnectorProps as FeaturedStoriesProps,
} from '../../connectors/FeaturedStories'

const FeaturedStories = ({
  featuredStories,
}: FeaturedStoriesProps): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <div data-type='featured-stories'>
      <div data-type='featured-stories-header'>
        <h3 className='defaultTypographyH3' data-type='stories-header'>
          More to Explore
        </h3>
        <SALink href='/stories'>
          <p className='defaultTypographyCtaSmallOrMedium' data-type='view-all'>
            View All
          </p>
        </SALink>
      </div>
      <div data-section='stories'>
        <div data-type='scroll'>
          {featuredStories.map((tileData, key) => {
            const { linkText, linkUrl, thumbnailImageUrl, title } = tileData
            return (
              <div data-type='story' key={key}>
                <SALink href={linkUrl} title={title}>
                  <img
                    className='lazyload'
                    data-src={thumbnailImageUrl}
                    alt={title}
                    width='400'
                    height='228'
                    loading='lazy'
                  />
                </SALink>

                <div data-type='featuredStoriesStoryInfo'>
                  <h6 className='defaultTypographyH6' data-type='story-title'>
                    {title}
                  </h6>
                  <SALink href={linkUrl} alt={`${title} Collection`}>
                    <p className='defaultTypographyCtaSmallOrMedium' data-type='story-description'>
                      {linkText}
                    </p>
                  </SALink>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FeaturedStoriesConnector(FeaturedStories)

// CONSTANTS
export const Constants = {
  SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_FEATURE_FLAGS
  payload: Record<string, boolean>
}
// ACTION CALLS
export const Actions = {
  SET_FEATURE_FLAGS: (payload: Record<string, boolean>): ActionTypes => ({
    type: Constants.SET_FEATURE_FLAGS,
    payload,
  }),
}
import type { ActionTypes as Action } from 'actions/shared/instana'
import { Constants } from 'actions/shared/instana'
import type { Instana } from 'types/Shared/Instana'
import 'types/Shared/Instana'
export const defaultState = {
  traceId: '',
}
export default (state: Instana = defaultState, action: Action): Instana => {
  switch (action.type) {
    case Constants.SET_TRACE_ID:
      return { ...state, traceId: action.payload }

    default:
      return state
  }
}
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'

const mapStateToProps = (state) => ({
  store: state,
})

const Connector = connect(mapStateToProps)

const Component = ({ store }: Record<string, any>) => {
  const [update, handleUpdate] = useState(0)
  useEffect(() => {
    handleUpdate(update + 1)
  }, [store])
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: 'rgba(255, 255, 0, 0.5)',
        minHeight: '50px',
        padding: '10px 50px',
        color: '#333333',
      }}
    >
      Updates / Rerenders via Redux Store: {update || '0'}
    </div>
  )
}

export default Connector(Component)
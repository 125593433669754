"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.frameIdDisplayNameDictionary = exports.frameColorDictionary = exports.frameIdColorStringDictionary = void 0;
exports.frameIdColorStringDictionary = {
    F9: 'black',
    F10: 'white',
    F11: 'natural',
};
exports.frameColorDictionary = {
    black: '#000',
    white: '#f0f0f0',
    natural: '#efe3d2',
};
exports.frameIdDisplayNameDictionary = {
    F9: 'Black',
    F10: 'White',
    F11: 'Natural Wood',
};

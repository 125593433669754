import type { ActionTypes as Action } from 'actions/user/follow'
import { Constants } from 'actions/user/follow'
import type { Follow } from 'types/User/Follow'
import 'types/User/Follow'
export const defaultState = []
type State = Follow
export default (state: State = [], action: Action): State => {
  switch (action.type) {
    case Constants.UPDATE:
      // Add Item or Remove
      if (!action.payload.following) {
        return (state || []).filter(
          (val) =>
            action.payload && action.payload.targetUserId && action.payload.targetUserId !== val
        )
      }

      return [...(state || []), action.payload.targetUserId]

    case Constants.FETCH_COMPLETE:
      return action.payload || defaultState

    default:
      return state
  }
}
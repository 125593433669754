import styled from 'styled-components'
import { fontSize, space, width } from 'styled-system'
import SALink from 'components/SALink'
import { CarouselItemContainerInner } from '../CarouselControls'
export const ArtworkContainer = styled.div`
  ${fontSize};
  ${space};
  ${width};
  line-height: 1.5;
  overflow: hidden;
  ${CarouselItemContainerInner} & {
    vertical-align: top;
  }

  a[data-type='visii-button'] {
    display: block;
    padding: 8px 16px;
    margin: 8px 0px;
    background-color: ${(props) => props.theme.colors.borderMedium};
    text-align: center;
    text-decoration: none;
    color: #000000;
  }
`
export const CTA = styled(SALink)`
  display: block;
  padding: 1px 16px;
  background-color: ${(props) => props.theme.colors.borderMedium};
  text-align: center;
  text-decoration: none;
  color: #000000;
  ${space};
`
import { $Shape } from 'utility-types'
import type { AlgoliaEvent, AlgoliaState } from 'types/Algolia'
// CONSTANTS
export const Constants = {
  EVENT: 'FIRE_ALGOLIA_EVENT',
  UPDATE: 'UPDATE_ALGOLIA_STORE',
  RESET: 'RESET_ALGOLIA_STORE', // CLEAR DATA USED FOR TRACKING EVENTS
}
// ACTION TYPES
export type FlowTypes =
  | {
      type: typeof Constants.EVENT
      payload: $Shape<AlgoliaEvent>
    }
  | {
      type: typeof Constants.UPDATE
      payload: AlgoliaState
    }
// ACTION CALLS
export const Actions = {
  EVENT: (payload: $Shape<AlgoliaEvent>): FlowTypes => ({
    type: Constants.EVENT,
    payload,
  }),
  UPDATE: (payload: $Shape<AlgoliaState>): FlowTypes => ({
    type: Constants.UPDATE,
    payload,
  }),
}
import React from 'react'
import SALink from 'components/SALink'

const CATEGORIES = [
  {
    categoryName: 'Paintings',
    categoryUrl: 'paintings',
  },
  {
    categoryName: 'Photography',
    categoryUrl: 'photography',
  },
  {
    categoryName: 'Fine Art Prints',
    categoryUrl: 'prints',
  },
  {
    categoryName: 'Sculpture',
    categoryUrl: 'sculpture',
  },
]

const ShopByCategory = (): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <div data-type='shop-by-category-container'>
      <div data-type='shop-by-category'>
        <h3 className='defaultTypographyH3' data-type='shop-category-title'>
          Shop by Category
        </h3>
        <div data-type='shop-row'>
          {CATEGORIES.map((category, index) => {
            const { categoryName, categoryUrl } = category
            return (
              <SALink href={`/${categoryUrl}`} key={index} data-type='category-cta'>
                <p className='defaultTypographyCtaSmallOrMedium'>{categoryName}</p>
              </SALink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ShopByCategory

import type { ActionTypes as Action } from 'actions/ops/cms'
import { Constants } from 'actions/ops/cms'
export const defaultState = {
  version: 1,
  homepage: {
    status: '',
    local: '',
    server: '',
  },
  promos: {
    status: '',
    local: '',
    server: '',
  },
  shows: {
    status: '',
    local: '',
    server: '',
  },
}
type State = Record<string, any>
export default (state: State = defaultState, action: Action): State => {
  const version = state.version + 1 // Increment Every Change

  switch (action.type) {
    case Constants.FETCH_HOMEPAGE_ERROR: {
      const { homepage } = state
      return { ...state, homepage: { ...homepage, status: 'error' }, version }
    }

    case Constants.SET_HOMEPAGE_LOCAL: {
      const { homepage } = state
      return {
        ...state,
        homepage: { ...homepage, local: action.payload, status: 'success' },
        version,
      }
    }

    case Constants.UPDATE_HOMEPAGE_LOCAL: {
      const { homepage } = state
      const { local } = homepage
      // We NEED to parse the string back to JSON, merge data, and set back to STRING
      const localJSON = local ? JSON.parse(local) : {}
      const mergeData = { ...(localJSON || {}), ...action.payload }
      const localString = JSON.stringify(mergeData)
      return { ...state, homepage: { ...homepage, local: localString, status: 'success' }, version }
    }

    case Constants.SET_HOMEPAGE_SERVER:
      return {
        ...state,
        homepage: {
          server: action.payload,
          local: action.payload,
          status: 'success',
        },
        version,
      }

    case Constants.FETCH_PROMOS_ERROR: {
      const { promos } = state
      return { ...state, promos: { ...promos, status: 'error' }, version }
    }

    case Constants.SET_PROMOS_LOCAL: {
      const { promos } = state
      return { ...state, promos: { ...promos, local: action.payload, status: 'success' }, version }
    }

    case Constants.SET_PROMOS_SERVER:
      return {
        ...state,
        promos: {
          server: action.payload,
          local: action.payload,
          status: 'success',
        },
        version,
      }

    case Constants.FETCH_SHOWS_ERROR: {
      const { shows } = state
      return { ...state, shows: { ...shows, status: 'error' }, version }
    }

    case Constants.SET_SHOWS_LOCAL: {
      const { shows } = state
      return { ...state, shows: { ...shows, local: action.payload, status: 'success' }, version }
    }

    case Constants.SET_SHOWS_SERVER:
      return {
        ...state,
        shows: {
          server: action.payload,
          local: action.payload,
          status: 'success',
        },
        version,
      }

    default:
      return state
  }
}
import React from 'react'
import styled from 'styled-components'
import invariant from 'invariant'
import SALink from 'components/SALink'
import type { ImageProps } from 'types/Artwork'
export const Wrapper = styled(SALink)`
  display: inline-block;
`
export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  display: block;
  pointer-events: none;
`
export type Props = ImageProps & {
  alt?: string
  artworkId: string
  aspectRatio?: number
  dataApi?: string
  dataList?: string
  dataPosition?: number
  onClick?: (...args: Array<any>) => any
  productPage?: string
  rel: string | null | undefined
  sku?: string
  src: string
  title?: string
}
export function computeRenderDimensions(props: Props): [number, number] {
  const { autoScale, fixedDimensionSize, aspectRatio } = props
  let width
  let height
  invariant(
    typeof aspectRatio === 'number',
    'Aspect ratio must be defined and a number to use autoScale'
  )
  invariant(
    typeof fixedDimensionSize === 'number',
    'A single fixed dimension size must be given to use autoScale'
  )

  switch (autoScale) {
    case 'x':
      height = fixedDimensionSize
      width = height * aspectRatio
      break

    case 'y':
      width = fixedDimensionSize
      height = width / aspectRatio
      break

    case 'square':
      width = fixedDimensionSize
      height = fixedDimensionSize
      break

    default:
      throw new Error('autoScale must be either x or y axis')
  }

  return [width, height]
}
export default function Image(props: Props): React.ReactElement<React.ComponentProps<any>, any> {
  const {
    artworkId,
    autoScale,
    dataApi,
    dataList,
    dataPosition,
    onClick,
    productPage,
    rel,
    sku,
    src,
    title,
  } = props
  let style = {}

  if (autoScale) {
    const [width, height] = computeRenderDimensions(props)
    style = {
      width,
      height,
    }
  }

  return (
    <Wrapper
      aria-label={title}
      data-api={dataApi}
      data-id={artworkId}
      data-list={dataList}
      data-position={dataPosition}
      href={productPage}
      onClick={onClick}
      rel={rel}
      title={title}
    >
      <Img
        className='lazyload'
        data-src={src}
        data-sku={sku}
        role='presentation'
        style={style}
        alt={`${title || 'artwork'} thumb`}
      />
    </Wrapper>
  )
}
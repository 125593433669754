import styled from 'styled-components'
import { space, fontSize, color, fontWeight, width } from 'styled-system'

const BASE = (props) => `
  font-weight: normal;
  margin: 0;
  ${space};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${width};
  ${props.italic && 'font-style: italic'};
`

export const Serif = styled.div`
  ${(props) => BASE(props)};
`
export const Span = styled('span')`
  ${(props) => BASE(props)};
`
export const H1 = styled('h1')`
  ${(props) => BASE(props)};
`
H1.defaultProps = {
  fontSize: [5, 6],
}
export const H2 = styled('h2')`
  ${(props) => BASE(props)};
`
H2.defaultProps = {
  fontSize: 5,
}
export const H3 = styled('h3')`
  ${(props) => BASE(props)};
`
H3.defaultProps = {
  fontSize: 4,
}
export const H4 = styled('h4')`
  ${(props) => BASE(props)};
`
H4.defaultProps = {
  fontSize: 3,
}
export const H5 = styled('h5')`
  ${(props) => BASE(props)};
`
H5.defaultProps = {
  fontSize: 1,
}
export const H6 = styled('h6')`
  ${(props) => BASE(props)};
`
H6.defaultProps = {
  fontSize: 0,
}
export default Serif
// CONSTANTS
export const Constants = {
  SSR_COMPLETE: 'SSR_SETUP_COMPLETE',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SSR_COMPLETE
  payload: Record<string, any>
}
// ACTION CALLS
export const Actions = {
  SSR_COMPLETE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SSR_COMPLETE,
    payload,
  }),
}
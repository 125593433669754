import styled from 'styled-components'
import { space, display } from 'styled-system'
import CaretLeft from 'svg/caret-left.svg'
import CaretRight from 'svg/caret-right.svg'
export const TABLET_WIDTH = 1200
export const MOBILE_WIDTH = 980
export const LRControl = styled.button`
  ${display};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease opacity;
  will-change: transform, opacity;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  :hover {
    transform: translateY(-50%) scale(1.1);
  }
  :focus {
    outline: none;
  }
  opacity: ${(props) => (props.visible === false ? 0 : 1)};
  pointer-events: ${(props) => (props.visible === false ? 'none' : 'auto')};
`
LRControl.defaultProps = {
  display: ['none', 'none', 'block'],
}
export const ICON_STYLE = `
  height: 40px;
  width: auto;
   path {
          stroke: #fff;
        }
`
export const NextIcon = styled(CaretRight)`
  ${ICON_STYLE};
`
export const PrevIcon = styled(CaretLeft)`
  ${ICON_STYLE};
`
export const Next = styled(LRControl)`
  right: 20px;
  z-index: 2;
`
export const Prev = styled(LRControl)`
  left: 20px;
  z-index: 2;
`
export const CarouselItemContainer = styled.div`
  ${space};
  margin: 0 auto;
  overflow: hidden;
  height: 640px;
`
export const Container = styled.div`
  @media (min-width: ${MOBILE_WIDTH}) {
    ${CarouselItemContainer} {
      width: 90%;
    }
  }
`
export const CarouselItemContainerInner = styled.div`
  will-change: transform;
  white-space: nowrap;
  height: 600px;
  max-width: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  ${(props) => props.transition && 'transition: 0.2s ease transform'};
  > * {
    user-select: none;
    display: inline-block;
    vertical-align: middle;
  }
`
export const CarouselContainer = styled.div`
  position: relative;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: #0000;
  width: 100%;
  ${CarouselItemContainerInner} > * {
    width: 100%;
  }
  ${(props) => props.dragging && '> * {pointer-events: none;}'};
`
import type { ActionTypes as Action } from 'actions/authentication'
import { Constants } from 'actions/authentication'
import type { Authentication } from 'types/Authentication'
export const defaultState = {
  authenticationModal: '',
}
type State = Authentication

const Reducer = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.PROMPT_LOGIN:
      return { ...state, authenticationModal: 'prompt' }

    case Constants.SHOW_MODAL:
      return { ...state, authenticationModal: action.payload || '' }

    default:
      return state
  }
}

export default Reducer
// CONSTANTS
export const Constants = {
  SET_BOT_TRAFFIC: 'SET_BOT_TRAFFIC',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_BOT_TRAFFIC
  payload: boolean
}
// ACTION CALLS
export const Actions = {
  SET_BOT_TRAFFIC: (payload: boolean): ActionTypes => ({
    type: Constants.SET_BOT_TRAFFIC,
    payload,
  }),
}
export const FrameShadow = `
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);

  ::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }
`
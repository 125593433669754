// CONSTANTS
export const Constants = {
  DISABLE_EPICS: 'DISABLE_EPICS',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.DISABLE_EPICS
  payload: boolean
}
// ACTION CALLS
export const Actions = {
  DISABLE_EPICS: (payload: boolean): ActionTypes => ({
    type: Constants.DISABLE_EPICS,
    payload,
  }),
}
import type { ActionTypes as Action } from 'actions/user/session'
import { Constants } from 'actions/user/session'
import type { Session } from 'types/User/Session'
import 'types/User/Session'
export const defaultState = {
  favorites: [],
  guestId: '0',
  isAuthenticated: false,
  isKnownUser: false,
  isSet: false,
  localizationCountry: 'US',
  measurementUnit: 'in',
  userRole: 'guest',
}
type State = Session
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_SESSION:
      return { ...action.payload, isSet: true }

    case Constants.UPDATE_SESSION:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
import { combineReducers } from 'redux'
import client, { defaultState as clientState } from './client'
import disableEpics, { defaultState as disableEpicsState } from './disableEpics'
import featureFlags, { defaultState as featureFlagsState } from './featureFlags'
import flashMessage, { defaultState as flashMessageState } from './flashMessage'
import form, { defaultState as formState } from './form'
import storeLocale, { defaultState as storeLocaleState } from './storeLocale'
import instana, { defaultState as instanaState } from './instana'
import isBotTraffic, { defaultState as botTrafficState } from './botTraffic'
import locale, { defaultState as localeState } from './locale'
import location, { defaultState as locationState } from './location'
import isMobileTraffic, { defaultState as mobileTrafficState } from './mobileTraffic'
import modal, { defaultState as modalState } from './modal'
import navigation, { defaultState as navigationState } from './navigation'
export const defaultState = {
  client: clientState,
  disableEpics: disableEpicsState,
  featureFlags: featureFlagsState,
  flashMessage: flashMessageState,
  form: formState,
  instana: instanaState,
  isBotTraffic: botTrafficState,
  isMobileTraffic: mobileTrafficState,
  locale: localeState,
  location: locationState,
  modal: modalState,
  navigation: navigationState,
  storeLocale: storeLocaleState,
}
export { client, featureFlags, isBotTraffic, isMobileTraffic, navigation, storeLocale }
export default combineReducers({
  client,
  disableEpics,
  featureFlags,
  flashMessage,
  form,
  instana,
  isBotTraffic,
  isMobileTraffic,
  locale,
  location,
  modal,
  navigation,
  storeLocale,
})
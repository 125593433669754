import type { ActionTypes as Action } from 'actions/user/account'
import { Constants } from 'actions/user/account'
import type { Account } from 'types/User/Account'
import 'types/User/Account'
export const defaultState = {
  addresses: [],
  hasLoaded: false, // Flag to indicate if USER DATA is Loaded
}
type State = Account
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.DELETE_ADDRESS_ITEM: {
      const { addresses } = { ...state }
      const addressId = action.payload
      return {
        ...state,
        addresses: addresses.filter(({ addressBookItemId }) => addressBookItemId !== addressId),
      }
    }

    case Constants.ADD_ADDRESS_ITEM: {
      const { addresses } = { ...state }
      const addressItem = action.payload
      return { ...state, addresses: [...addresses, addressItem] }
    }

    case Constants.SET_USER_ACCOUNT_INFORMATION:
      return { ...state, ...(action.payload || {}), hasLoaded: true }

    case Constants.SET_USER_VACATION_STATUS:
      return { ...state, isOnVacation: action.payload || false }

    case Constants.UPDATE_USER_CHECKIN_STATUS:
      return { ...state, isCheckInOverdue: false, isCheckInDue: false }

    default:
      return state
  }
}
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as ClientActions } from 'actions/shared/client'
import { isAuthenticated } from 'selectors/user/session'
import {
  getUserPreferences,
  getUserPreferencesCountry,
  getUserPreferencesCurrency,
  getUserPreferencesMeasurementUnit,
  getSelectedCurrency,
} from 'selectors/user/cookie'
import {
  getAvailableCountries,
  getAvailableCurrenciesData,
  getCurrenciesDropdown,
  getLocaleIsReady,
} from 'selectors/shared/locale'
import { Locale, LocaleCurrency } from 'types/Shared/Locale'

const mapStateToProps = createStructuredSelector({
  availableCountries: getAvailableCountries,
  availableCurrencies: getAvailableCurrenciesData,
  currenciesDropdownData: getCurrenciesDropdown,
  isAuthenticated,
  localeIsReady: getLocaleIsReady,
  selectedCurrency: getSelectedCurrency,
  userPreferences: getUserPreferences,
  userPreferencesCountry: getUserPreferencesCountry,
  userPreferencesCurrency: getUserPreferencesCurrency,
  userPreferencesMeasurementUnit: getUserPreferencesMeasurementUnit,
})

const mapDispatchToProps = (dispatch) => ({
  updateUserPreferences: (clientData: Record<string, any>) =>
    dispatch([ClientActions.NEW_CLIENT_COMPLETE(clientData)]),
})

export type ConnectorProps = {
  availableCountries: Locale['availableCountries']
  availableCurrencies: Array<LocaleCurrency>
  currenciesDropdownData: Record<string, any>
  isAuthenticated: boolean
  localeIsReady: boolean
  selectedCurrency: Record<string, any>
  updateUserPreferences: (...args: Array<any>) => any
  userPreferences: Record<string, any>
  userPreferencesCountry: string
  userPreferencesCurrency: string
  userPreferencesMeasurementUnit: 'in' | 'cm'
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })

import styled from 'styled-components'
const StyleBase = styled.div`
  color: ${(props) => props.theme.colors.default};

  /* Padding for mobile navigation */
  padding-top: ${(props) => (props.isIOSWebview ? '0' : 'var(--promo-bar-height)')};
  --mobile-header-height: 56px;
  --promo-bar-height: 56px;

  @media (min-width: 1170px) {
    --promo-bar-height: 40px;
  }

  /* Zero out top padding for pages that have no promo bar */
  body[data-promo='hidden'] & {
    padding-top: 0;
  }

  @media (min-width: 1000px) {
    padding: 0;
  }
`
export default StyleBase

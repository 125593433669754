import type { ValidationRuleset } from 'lib/validation'
// CONSTANTS
export const Constants = {
  CLEAR_DATA: 'CLEAR_FORM_DATA',
  CLEAR_ERRORS: 'CLEAR_ERRORS_DATA',
  CLEAR_FORM: 'CLEAR_ALL_FORM_DATA',
  GET_STORAGE: 'GET_FORM_DATA_FROM_STORAGE',
  IS_SUBMITTING: 'IS_SUBMITTING_FORM',
  MERGE_ERRORS: 'MERGE_FORM_ERRORS',
  REMOVE_ERRORS: 'REMOVE_FORM_ERRORS',
  SET_DATA: 'SET_FORM_DATA',
  SET_ERRORS: 'SET_FORM_ERRORS',
  SET_NAME: 'SET_FORM_NAME',
  SET_RULES: 'SET_FORM_VALIDATION_RULES',
  SET_STORAGE: 'SET_FORM_DATA_TO_STORAGE',
  UPDATE: 'UPDATE_FORM_STORE',
  VALIDATE: 'VALIDATE_FORM_DATA',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CLEAR_DATA
    }
  | {
      type: typeof Constants.CLEAR_ERRORS
    }
  | {
      type: typeof Constants.CLEAR_FORM
    }
  | {
      type: typeof Constants.GET_STORAGE
    }
  | {
      type: typeof Constants.IS_SUBMITTING
      payload: boolean
    }
  | {
      type: typeof Constants.MERGE_ERRORS
      payload: Array<Record<string, any>>
    }
  | {
      type: typeof Constants.REMOVE_ERRORS
      payload: Array<string>
    }
  | {
      type: typeof Constants.SET_DATA
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_ERRORS
      payload: Array<Record<string, any>>
    }
  | {
      type: typeof Constants.SET_NAME
      payload: string
    }
  | {
      type: typeof Constants.SET_RULES
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_STORAGE
      payload: ValidationRuleset
    }
  | {
      type: typeof Constants.UPDATE
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.VALIDATE
    }
// ACTION CALLS
export const Actions = {
  CLEAR_DATA: (): ActionTypes => ({
    type: Constants.CLEAR_DATA,
  }),
  CLEAR_ERRORS: (): ActionTypes => ({
    type: Constants.CLEAR_ERRORS,
  }),
  CLEAR_FORM: (): ActionTypes => ({
    type: Constants.CLEAR_FORM,
  }),
  GET_STORAGE: (): ActionTypes => ({
    type: Constants.GET_STORAGE,
  }),
  IS_SUBMITTING: (payload: boolean): ActionTypes => ({
    type: Constants.IS_SUBMITTING,
    payload,
  }),
  MERGE_ERRORS: (payload: Array<Record<string, any>>): ActionTypes => ({
    type: Constants.MERGE_ERRORS,
    payload,
  }),
  REMOVE_ERRORS: (payload: Array<string>): ActionTypes => ({
    type: Constants.REMOVE_ERRORS,
    payload,
  }),
  SET_DATA: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_DATA,
    payload,
  }),
  SET_ERRORS: (payload: Array<Record<string, any>>): ActionTypes => ({
    type: Constants.SET_ERRORS,
    payload,
  }),
  SET_NAME: (payload: string): ActionTypes => ({
    type: Constants.SET_NAME,
    payload,
  }),
  SET_RULES: (payload: ValidationRuleset): ActionTypes => ({
    type: Constants.SET_RULES,
    payload,
  }),
  SET_STORAGE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_STORAGE,
    payload,
  }),
  UPDATE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE,
    payload,
  }),
  VALIDATE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.VALIDATE,
  }),
}
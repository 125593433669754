import type { ActionTypes as Action } from 'actions/shared/location'
import { Constants } from 'actions/shared/location'
export const defaultState = ''
type State = string
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_LOCATION:
      return action.payload

    default:
      return state
  }
}
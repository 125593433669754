import React from 'react'
import styled from 'styled-components'
import { display, space } from 'styled-system'
const Container = styled.div`
  display: inline-block;
  ${space};
  ${display};
`
export const Dot = styled.div`
  border-radius: 100%;
  border: 1px solid #000;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  ::after {
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    content: '';
    transform: scale(${(props) => (props.active ? 1 : 0)});
    transition: 0.3s ease-in-out transform;
    transform-origin: center center;
    will-change: transform;
  }
  &[data-style='active'] {
    ::after {
      transform: scale(1);
    }
  }
`
type PropTypes = {
  index: number
  length: number
  setIndex: (index: number) => void
  navIndex: number
}

function createDotIndexArray(length) {
  const dots = []

  for (let i = 0; i < length; i++) {
    dots.push(i)
  }

  return dots
}

export default function DotsIndexIndicator(props: PropTypes) {
  const { index, length, navIndex, setIndex } = props
  const dotIndexArray = createDotIndexArray(length)
  const safeIndex = navIndex && navIndex !== null ? parseInt(navIndex, 10) : parseInt(index, 10)
  return (
    <Container {...props}>
      {dotIndexArray.map((dotIndex) => {
        return (
          <Dot
            tabIndex={0}
            role='button'
            key={dotIndex}
            onClick={() => setIndex(dotIndex)}
            data-style={dotIndex === safeIndex ? 'active' : null}
            aria-label={`Slide ${dotIndex + 1}`}
            data-type='navDot'
          />
        )
      })}
    </Container>
  )
}
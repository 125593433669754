import styled from 'styled-components'
import { space, fontSize, color, display } from 'styled-system'
import { lighten } from 'polished'
import SALink from 'components/SALink'
export const A = styled(SALink)`
  ${color};
  ${space};
  ${fontSize};
  ${display};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  transition: 0.2s ease color;
  will-change: color;
  text-decoration: underline;
  cursor: pointer;
  font-style: normal;

  :focus {
    outline: none;
  }

  :hover {
    color: ${(props) => lighten(0.2, props.theme.colors.lightGray)};
  }

  ${(props) => props.inlineBlock && 'display: inline-block'};
  ${(props) => props.block && 'display: block'};
`
A.defaultProps = {
  fontSize: 0,
  color: 'lightGray',
}
export const PlainLink = styled(SALink)`
  text-decoration: none;
  transition: 0.2s ease color;
  ${(props) => props.block && 'display: block;'};
  ${color};
  ${fontSize};
  ${space};
  ${(props) => props.bold && 'font-weight: bold;'};

  :hover {
    color: ${(props) => props.theme.colors.blue};
  }
`
PlainLink.defaultProps = {
  color: 'default',
}
export default A
// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import { space, width, fontSize, display } from 'styled-system'
import { A } from 'components/A'
import { H2, Serif, Span } from 'components/Serif'
import Caret from '../svg/caret-right.svg'

const flexParams = (props) => `
  ${props.justify && `justify-content: ${props.justify || 'flex-start'};`};
  ${props.align && `align-items: ${props.align || 'flex-start'};`};
  ${props.flex && `flex: ${props.flex};`};
`

export const Row = styled.div`
  width: 100%;
  ${width};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => flexParams(props)};
  ${space};
  ${(props) => props.click && `cursor: pointer;`}
  ${(props) =>
    props.radios &&
    `
      position: relative;
      justify-content: flex-start;
      label {
        form & {
          min-width: 125px;
        }
      }
  `}
  span {
    transition: 0.2s ease-in-out color;
    ${(props) =>
      props.disabled &&
      `
        color: ${props.theme.colors.grayDark};
    `}
  }
  ${(props) =>
    props.stackOnMobile &&
    `
    @media (max-width: ${props.theme.breakpoints[0]}) {
      flex-direction: column;
    }
  `}
  ${(props) =>
    props.mobileWidth &&
    `
    @media (max-width: ${props.theme.breakpoints[0]}) {
      width: ${props.mobileWidth};
    }
  `}
  ${(props) =>
    props.maxWidth &&
    `max-width: ${props.maxWidth}
  `}
`
export const Column = styled.div`
  width: 100%;
  ${width};
  display: flex;
  flex-direction: column;
  ${(props) => flexParams(props)};
  ${width};
  ${space};
  ${(props) =>
    props.artwork &&
    `
    width: 450px;
    min-width: 450px;
    @media (max-width: ${props.theme.breakpoints[0]}) {
      width: 100%;
      min-width: 100%;
    }
  `}

  ${(props) =>
    props.borderBox &&
    `
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid #dddddd;
    padding: 40px;
    margin: 20px 0;
    @media (max-width: ${props.theme.breakpoints[0]}) {
      width: 100%;
      min-width: 100%;
    }
  `}
  &[data-type='quantity'] {
    &[data-disabled='true'] {
      opacity: 0.6;
    }
  }
  &[data-type='limited-product'] {
    [data-disabled='true'] {
      opacity: 0.6;
    }
  }
  &[data-type='price'] {
    opacity: 1;
  }
`
export const FormSectionColumn = styled(Column)`
  padding-left: ${(props) => props.theme.space[5]}px;

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-left: 0;
  }
`
export const Spacer = styled.div`
  flex: 1;
`
export const ProfileImage = styled(Column)`
  ${display};
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #eeeeee;
  ${space};
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    height: auto;
    width: 100%;
    background: none;
    display: flex;
    align-items: center;
    img {
      max-width: 30px;
    }
  }
`
export const AccountWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${space}

  @media (max-width: ${(props) => props.theme.maxWidth}) {
    padding: 0 15px;
  }
`
export const Arrow = styled(Caret)`
  transition: 0.2s ease-in-out transform;
  ${(props) => props.open && 'transform: rotate(90deg)'};
  ${(props) => props.down && 'transform: rotate(90deg)'};
  ${(props) => props.left && 'transform: rotate(-180deg)'};
  width: 20px;
  height: 20px;
  path {
    stroke: ${(props) => props.theme.colors.lightGray};
  }
`
export const Title = styled(H2)`
  display: block;
  width: 100%;
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  color: ${(props) => props.theme.colors.default};
`
export const P = styled(Serif)`
  ${display};
  font-size: ${(props) => (props.fontSize ? fontSize : props.theme.fontSizes[3])}px;
  line-height: 1.5em;
  color: ${(props) => props.theme.colors.default};
  ${(props) => props.bold && `font-weight: bold;`};
  ${(props) => props.underline && `cursor: pointer; text-decoration: underline;`}
  ${(props) => props.italic && `font-style: italic;`}
  ${space};
  ${width};
  ${fontSize};
`
export const InlineText = styled(Span)`
  ${(props) => props.bold && `font-weight: bold;`}
  ${display};
  ${width};
  ${fontSize};
  ${(props) => props.flex && `display: flex;`};
  ${(props) => flexParams(props)};
`
export const Bold = styled.p`
  margin: 0;
  ${space};
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  line-height: 1.3em;
  color: ${(props) => props.theme.colors.default};
  font-weight: bold;
`
export const Link = styled(A)`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  ${fontSize};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'capitalize')};
  color: ${(props) => props.theme.colors.default};
  display: flex;
  align-items: center;
  transition: 0.2s ease color, opacity 0.3s ease;
  ${(props) =>
    props.button &&
    `
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.default};
    padding: 15px 40px;
    text-decoration: none;
    justify-content: center;
    font-size: ${props.theme.fontSizes[3]}px;
    font-weight: bold;
    padding: 15px 0;
    width: 200px;
  `}

  ${(props) =>
    props.borderButton &&
    `
    border: 1px solid #333333;
    padding: 15px;
    text-decoration: none;
  `}
  ${(props) =>
    props.cancelButton &&
    `
    width: 118px;
    height: 50px;
    font-size: ${props.theme.fontSizes[3]}px;
    justify-content: center;
  `}
  ${(props) =>
    props.disabled &&
    `
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
  `}
  ${(props) =>
    props.artworkBtn &&
    `
    font-weight: bold;
    height: 50px;
    @media (max-width: ${props.theme.breakpoints[0]}) {
      width: 95px;
      padding: 0 ${props.theme.space[2]}px;
      height: 30px;
    }
  `}
  ${width};
`
export const Button = styled.button`
  ${space};
  width: 125px;
  height: 50px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.default};
  text-align: center;
  align-self: flex-end;
  font-weight: 700;
  cursor: pointer;
  will-change: background-color;
  text-decoration: none;
  padding: ${(props) => props.theme.space[2]}px;
  transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
  outline: none;
  ${(props) => props.disabled && `background-color: ${props.theme.colors.backgroundMediumGray};`};
`
// General Navigation (componets/AccountNavigation)
export const Navigation = styled.nav`
  justify-content: flex-start;
  margin: 20px 0;
  ${Link} {
    text-decoration: none;
    + ${Link} {
      margin-left: 10px;
    }
  }
`
// Dashboard Actions (Links)
export const Actions = styled.nav`
  ${Link} {
    + ${Link} {
      margin-left: 5px;
    }
  }
`
// Dashboard
export const Dashboard = styled(Column)`
  align-items: stretch;
  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  ${Row} [artwork] {
    border: 1px solid black;
  }
`
export const DashboardContentWrapper = styled(Column)``
export const SerifList = styled.ul`
  li + li {
    margin-top: 3px;
  }
`
export const VacationRowTop = styled.div`
  display: flex;
  margin-bottom: 10px;
`
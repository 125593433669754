import type { Cookie } from 'types/User/Cookie'
import 'types/User/Cookie'
// CONSTANTS
export const Constants = {
  SET_COOKIE: 'SET_COOKIE',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_COOKIE
  payload: Cookie
}
// ACTION CALLS
export const Actions = {
  SET_COOKIE: (payload: Cookie): ActionTypes => ({
    type: Constants.SET_COOKIE,
    payload,
  }),
}
import React from 'react'
import SALink from 'components/SALink'
import type { ConnectorProps as LocaleConnectorProps } from 'connectors/Locale'
import LocaleConnector from 'connectors/Locale'
import { ShortcutsSubMenu } from './styles'
import { getShortcutsMenuList } from '../helpers'

const Menu = (props: LocaleConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => {
  return (
    <ShortcutsSubMenu data-type='shortcuts'>
      {getShortcutsMenuList().map((item, index) => (
        <button
          type='button'
          key={index}
          onClick={() =>
            window &&
            window.dataLayer.push({
              event: 'shortcuts_menu',
              label: item.title,
            })
          }
        >
          <SALink href={item.link} title={item.title}>
            <p className='defaultTypographyCtaSmallOrMedium'>{item.title}</p>
          </SALink>
        </button>
      ))}
    </ShortcutsSubMenu>
  )
}

export default LocaleConnector(Menu)
import { createSelector } from 'reselect'
const MOBILE_WIDTH = 999
export const getScreen = (state: Record<string, any>): Record<string, any> => state.screen || {}
// Please don't use SCREEN Ready size unless necessary
export const getScreenIsReady = createSelector(
  [getScreen],
  ({ isReady }): boolean => isReady || false
)
export const getScreenWidth = createSelector([getScreen], ({ width }): number => width || 0)
export const getScreenHeight = createSelector([getScreen], ({ height }): number => height || 0)
export const getScreenOrientation = createSelector(
  [getScreen],
  ({ orientation }): string => orientation || 'portrait'
)
export const getIsMobileScreenWidth = createSelector(
  [getScreen],
  ({ width }): boolean => width <= MOBILE_WIDTH
)
// We want the ability to pass in a CUSTOM width, Apps differ
// isMobileScreenWidth: (store, props) => getIsMobileScreenWidth(1024 || null)(store)
export const getCheckIsMobileScreenWidth = (screenWidth: number = MOBILE_WIDTH) =>
  createSelector([getScreen], ({ width }): boolean => width <= screenWidth)

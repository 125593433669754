import React, { PureComponent } from 'react'
import styled from 'styled-components'
import CarouselControls, { CarouselItemContainer } from './CarouselControls'
const Container = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    ${CarouselItemContainer} {
      width: 90%;
    }
  }
`
type Props = {
  children: Array<React.ReactElement<React.ComponentProps<any>, any>>
}
type State = {
  index: number
}
export default class Carousel extends PureComponent<Props, State> {
  state = {
    index: 0,
  }
  setIndex = (index: number) => {
    if (index < 0) {
      index = 0
    } else if (index > this.props.children.length - 1) {
      index = this.props.children.length - 1
    }

    this.setState({
      index,
    })
  }
  next = () => {
    const nextIndex = this.state.index + 1

    if (nextIndex > this.props.children.length - 1) {
      return
    }

    this.setState((prevState) => ({
      index: prevState.index + 1,
    }))
  }
  prev = () => {
    const nextIndex = this.state.index - 1

    if (nextIndex < 0) {
      return
    }

    this.setState((prevState) => ({
      index: prevState.index - 1,
    }))
  }
  props: Props

  render() {
    const { children } = this.props
    const { index } = this.state
    return (
      <Container width={[1, 9 / 10]}>
        <CarouselControls
          index={index}
          setIndex={this.setIndex}
          onNext={this.next}
          onPrev={this.prev}
          controls
          limitIndex
        >
          {children}
        </CarouselControls>
      </Container>
    )
  }
}
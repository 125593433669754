import type { ActionTypes as BotTrafficActionTypes } from './botTraffic'
import { Constants as BotTrafficConstants, Actions as BotTrafficActions } from './botTraffic'
import type { ActionTypes as ClientActionTypes } from './client'
import { Constants as ClientConstants, Actions as ClientActions } from './client'
import type { ActionTypes as DataLayerActionTypes } from './dataLayer'
import { Constants as DataLayerConstants, Actions as DataLayerActions } from './dataLayer'
import type { ActionTypes as DisableEpicsActionTypes } from './disableEpics'
import { Constants as DisableEpicsConstants, Actions as DisableEpicsActions } from './disableEpics'
import type { ActionTypes as FeatureFlagsActionTypes } from './featureFlags'
import { Constants as FeatureFlagsConstants, Actions as FeatureFlagsActions } from './featureFlags'
import type { ActionTypes as FlashMessageActionTypes } from './flashMessage'
import { Constants as FlashMessageConstants, Actions as FlashMessageActions } from './flashMessage'
import type { ActionTypes as FormActionTypes } from './form'
import { Constants as FormConstants, Actions as FormActions } from './form'
import type { ActionTypes as InstanaActionTypes } from './instana'
import { Constants as InstanaConstants, Actions as InstanaActions } from './instana'
import type { ActionTypes as LocaleActionTypes } from './locale'
import { Constants as LocaleConstants, Actions as LocaleActions } from './locale'
import type { ActionTypes as LocationActionTypes } from './location'
import { Constants as LocationConstants, Actions as LocationActions } from './location'
import type { ActionTypes as MobileTrafficActionTypes } from './mobileTraffic'
import {
  Constants as MobileTrafficConstants,
  Actions as MobileTrafficActions,
} from './mobileTraffic'
import type { ActionTypes as ModalActionTypes } from './modal'
import { Constants as ModalConstants, Actions as ModalActions } from './modal'
import type { ActionTypes as NavigationActionTypes } from './navigation'
import { Constants as NavigationConstants, Actions as NavigationActions } from './navigation'
import type { ActionTypes as StoreLocaleActionTypes } from './storeLocale'
import { Constants as StoreLocaleConstants, Actions as StoreLocaleActions } from './storeLocale'
// CONSTANTS
export const Constants = {
  ...BotTrafficConstants,
  ...ClientConstants,
  ...DataLayerConstants,
  ...DisableEpicsConstants,
  ...FeatureFlagsConstants,
  ...FlashMessageConstants,
  ...FormConstants,
  ...InstanaConstants,
  ...LocaleConstants,
  ...LocationConstants,
  ...MobileTrafficConstants,
  ...ModalConstants,
  ...NavigationConstants,
  ...StoreLocaleConstants,
}
// ACTION TYPES
export type ActionTypes =
  | BotTrafficActionTypes
  | ClientActionTypes
  | DataLayerActionTypes
  | DisableEpicsActionTypes
  | FeatureFlagsActionTypes
  | FlashMessageActionTypes
  | FormActionTypes
  | InstanaActionTypes
  | LocationActionTypes
  | LocaleActionTypes
  | MobileTrafficActionTypes
  | ModalActionTypes
  | NavigationActionTypes
  | StoreLocaleActionTypes
// ACTION CALLS
export const Actions = {
  ...BotTrafficActions,
  ...ClientActions,
  ...DataLayerActions,
  ...DisableEpicsActions,
  ...FeatureFlagsActions,
  ...FlashMessageActions,
  ...FormActions,
  ...InstanaActions,
  ...LocationActions,
  ...LocaleActions,
  ...MobileTrafficActions,
  ...ModalActions,
  ...NavigationActions,
  ...StoreLocaleActions,
}
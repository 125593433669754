import type { Follow, UpdatePayload } from 'types/User/Follow'
// CONSTANTS
export const Constants = {
  FETCH: 'FETCH_FOLLOW',
  FETCH_COMPLETE: 'FETCH_FOLLOW_COMPLETE',
  UPDATE: 'UPDATE_FOLLOW',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.FETCH
    }
  | {
      type: typeof Constants.FETCH_COMPLETE
      payload: Follow | null | undefined
    }
  | {
      type: typeof Constants.UPDATE
      payload: UpdatePayload
    }
// ACTION CALLS
export const Actions = {
  FETCH: (): ActionTypes => ({
    type: Constants.FETCH,
  }),
  FETCH_COMPLETE: (payload: Follow | null | undefined): ActionTypes => ({
    type: Constants.FETCH_COMPLETE,
    payload,
  }),
  UPDATE: (payload: UpdatePayload): ActionTypes => ({
    type: Constants.UPDATE,
    payload,
  }),
}
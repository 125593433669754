import type { ActionTypes as Action } from 'actions/shared/botTraffic'
import { Constants } from 'actions/shared/botTraffic'
export const defaultState = false
type State = boolean
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_BOT_TRAFFIC:
      return action.payload || false

    default:
      return state
  }
}
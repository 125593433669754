import type { SearchItem } from 'types'
// CONSTANTS
export const Constants = {
  SET: 'SET_SEARCH_ITEM',
}
// ACTION TYPES
export type FlowTypes = {
  type: typeof Constants.SET
  payload: SearchItem
}
// ACTION CALLS
export const Actions = {
  SET: (payload: SearchItem): FlowTypes => ({
    type: Constants.SET,
    payload,
  }),
}
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as ClientActions } from 'actions/shared/client'
import { getUserRole } from 'selectors/user/session'
import { getLocaleCountryCode } from 'selectors/shared/locale'
import { getPromoCampaigns, getPromoCta, getPagePath } from 'selectors/user/promos'
const mapStateToProps = createStructuredSelector({
  promoCampaigns: getPromoCampaigns,
  promoCta: getPromoCta,
  localeCountryCode: getLocaleCountryCode,
  userRole: getUserRole,
  pageType: getPagePath,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  promoCampaigns: Array<Record<string, any>>
  promoCta: Array<Record<string, any>>
  localeCountryCode: string
  userRole: string
  pageType: string
}
export default connect(mapStateToProps, mapDispatchToProps)
import type { ActionTypes as Action } from 'actions/shared/form'
import { Constants } from 'actions/shared/form'
import type { Form } from 'types/Shared/Form'
import 'types/Shared/Form'
export const defaultState = {
  name: '',
  data: {},
  errors: [],
  isValid: false,
  isSubmitting: false,
}
type State = Form

// Pass in ARRAY<string> => [ 'title', 'name', ...]
// Remove Errors containing these values
const removeErrors = ({ state, errors }) => {
  if (errors && Array.isArray(errors)) {
    return [...state.errors].filter(({ prop }) => !errors.includes(prop))
  }

  return [...state.errors]
}

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.CLEAR_DATA:
      return { ...(state || {}), data: {} }

    case Constants.CLEAR_ERRORS:
      return { ...(state || {}), errors: [], isValid: true }

    case Constants.CLEAR_FORM:
      return { ...(state || {}), data: {}, errors: [], isValid: true }

    case Constants.IS_SUBMITTING:
      return { ...(state || {}), isSubmitting: action.payload || false }

    case Constants.MERGE_ERRORS: {
      // Pull Out New Errors by PROP, Copy Errors and Remove Existing, Merge and Overwrite old Errors ( NO Duplicates )
      const newErrorNames: Array<string> = action.payload.map(({ prop }) => prop)
      const cleanErrors = removeErrors({
        state,
        errors: newErrorNames,
      })
      return { ...(state || {}), errors: [...cleanErrors, ...action.payload], isValid: true }
    }

    case Constants.REMOVE_ERRORS: {
      const newErrorNames: Array<string> = action.payload
      const cleanErrors = removeErrors({
        state,
        errors: newErrorNames,
      })
      return { ...(state || {}), errors: [...cleanErrors], isValid: true }
    }

    case Constants.SET_DATA:
      return { ...(state || {}), data: { ...(state?.data || {}), ...(action.payload || {}) } }
    case Constants.SET_ERRORS:
      return { ...(state || {}), errors: [...action.payload], isValid: true }

    case Constants.SET_NAME:
      return { ...(state || {}), name: action.payload }

    case Constants.SET_RULES:
      return { ...(state || {}), rules: action.payload }

    case Constants.UPDATE:
      return { ...(state || {}), ...action.payload }

    default:
      return state
  }
}
// CONSTANTS
export const Constants = {
  SWITCH_SHIPMENT_DESTINATION: 'SWITCH_SHIPMENT_DESTINATION',
  FETCH_SHIPMENT_SERVER: 'FETCH_SHIPMENT_SERVER',
  SET_SHIPMENT_LOCAL: 'SET_SHIPMENT_LOCAL',
  SET_SHIPMENT_SERVER: 'SET_SHIPMENT_SERVER',
  RESET_SHIPMENT_FORM: 'RESET_SHIPMENT_FORM',
  POST_SHIPMENT_TO_FETCH_RATES_SERVER: 'POST_SHIPMENT_TO_FETCH_RATES_SERVER',
  POST_SHIPMENT_FOR_CONFIRMATION_SERVER: 'POST_SHIPMENT_FOR_CONFIRMATION_SERVER',
  SET_SHIPMENT_AFTER_CONFIRMATION: 'SET_SHIPMENT_AFTER_CONFIRMATION',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.SWITCH_SHIPMENT_DESTINATION
    }
  | {
      type: typeof Constants.FETCH_SHIPMENT_SERVER
    }
  | {
      type: typeof Constants.SET_SHIPMENT_LOCAL
      payload: string
    }
  | {
      type: typeof Constants.SET_SHIPMENT_SERVER
      payload: string
    }
  | {
      type: typeof Constants.RESET_SHIPMENT_FORM
    }
  | {
      type: typeof Constants.POST_SHIPMENT_TO_FETCH_RATES_SERVER
    }
  | {
      type: typeof Constants.POST_SHIPMENT_FOR_CONFIRMATION_SERVER
    }
  | {
      type: typeof Constants.SET_SHIPMENT_AFTER_CONFIRMATION
      payload: string
    }
// ACTION CALLS
export const Actions = {
  SWITCH_SHIPMENT_DESTINATION: (): ActionTypes => ({
    type: Constants.SWITCH_SHIPMENT_DESTINATION,
  }),
  FETCH_SHIPMENT_SERVER: (): ActionTypes => ({
    type: Constants.FETCH_SHIPMENT_SERVER,
  }),
  SET_SHIPMENT_LOCAL: (payload: string): ActionTypes => ({
    type: Constants.SET_SHIPMENT_LOCAL,
    payload,
  }),
  SET_SHIPMENT_SERVER: (payload: string): ActionTypes => ({
    type: Constants.SET_SHIPMENT_SERVER,
    payload,
  }),
  SET_SHIPMENT_AFTER_CONFIRMATION: (payload: string): ActionTypes => ({
    type: Constants.SET_SHIPMENT_AFTER_CONFIRMATION,
    payload,
  }),
  RESET_SHIPMENT_FORM: (): ActionTypes => ({
    type: Constants.RESET_SHIPMENT_FORM,
  }),
  POST_SHIPMENT_TO_FETCH_RATES_SERVER: (): ActionTypes => ({
    type: Constants.POST_SHIPMENT_TO_FETCH_RATES_SERVER,
  }),
  POST_SHIPMENT_FOR_CONFIRMATION_SERVER: (): ActionTypes => ({
    type: Constants.POST_SHIPMENT_FOR_CONFIRMATION_SERVER,
  }),
}
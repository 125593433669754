import { createStore, applyMiddleware, compose } from 'redux'
import { batch } from 'react-redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import { BehaviorSubject } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import hydrateHOC from 'reducers/hydrate'
import ssrHOC from 'reducers/ssrHoc'
import debounce from 'lib/debounce'
type Params = {
  rootEpic?: Array<(...args: Array<any>) => any>
  initialState?: Record<string, any>
  rootReducer: Record<string, any>
}
const { APP_ENV } = process.env
const isProduction = APP_ENV === 'production'
const storeDebounce = debounce()

// function logger({ getState }) {
//   return (next) => (action) => {
//     console.log('will dispatch', action)
//     // Call the next dispatch method in the middleware chain.
//     const returnValue = next(action)
//     console.log('state after dispatch', getState())
//     // This will likely be the action itself, unless
//     // a middleware further in chain changed it.
//     return returnValue
//   }
// }
// Middleware that will prevent Multiple Rerenders of Components when you want to update
// multiple attributes/keys in the REDUX Store, https://react-redux.js.org/api/batch
const batchMultipleActionMiddleware = (store) => (next) => (action) => {
  if (!Array.isArray(action)) return next(action)
  return batch(() => {
    action.map((a) => store.dispatch(a))
  })
}

// Store ENHANCER ( HOC -> Higher Order Component ) that lets you debounce subscriber calls for multiple dispatches
// specifically meant to prevent SELECTORS from reevaluating until all ACTIONS are complete
const batchSubscribeMiddleware = batchedSubscribe((notify) => storeDebounce(() => notify(), 1))

// Based on ENV lets add Redux DevTools
// You can wrap more middlewares if we need them
const composeEnhancers = (middleware: Record<string, any>) => {
  // Issue with JENKINS builds trying to find `redux-devtools`, wrapping in TRY/CATCH
  try {
    if (!isProduction) {
      if (APP_ENV === 'development') {
        const { composeWithDevTools } = require('redux-devtools-extension/developmentOnly')

        return composeWithDevTools(middleware, batchSubscribeMiddleware) // DEV
      } else {
        const { composeWithDevTools } = require('redux-devtools-extension/logOnlyInProduction')

        return composeWithDevTools(middleware, batchSubscribeMiddleware) // QA
      }
    }

    return compose(middleware, batchSubscribeMiddleware) // Production
  } catch (error) {
    return compose(middleware, batchSubscribeMiddleware)
  }
}

const setupStore = (params: Params) => {
  const { initialState, rootReducer, rootEpic } = params
  let store

  if (typeof window !== 'undefined') {
    // On client, and start side-effect thread (epics)
    const epicMiddleware = createEpicMiddleware()
    const {
      shared: { disableEpics },
    } = initialState || {
      shared: {
        disableEpics: false,
      },
    }
    const epic$ = rootEpic
      ? new BehaviorSubject(combineEpics(rootEpic))
      : new BehaviorSubject(combineEpics())

    // Creating a Epic Loader for passing in NEW Epics on the FLY
    // Adding a error catcher
    const rootEpicLoader = (action$, store$) =>
      epic$.pipe(mergeMap((epic) => epic(action$, store$))).pipe(
        catchError((error, source) => {
          console.error({
            epicError: error,
          })
          return source
        })
      )

    // Redux STORE Creation
    store = createStore(
      hydrateHOC(rootReducer),
      initialState,
      disableEpics
        ? composeEnhancers(applyMiddleware(batchMultipleActionMiddleware))
        : composeEnhancers(applyMiddleware(batchMultipleActionMiddleware, epicMiddleware))
    )

    // Adding New Epics Asynchronously/Lazily -> https://redux-observable.js.org/docs/recipes/AddingNewEpicsAsynchronously.html
    store.addEpics = (
      epics: Array<(...args: Array<any>) => any> | ((...args: Array<any>) => any)
    ) => {
      if (Array.isArray(epics)) {
        epics.forEach((epic) => epic$.next(epic))
      } else {
        epic$.next(epics)
      }
    }

    // redux/configureStore.js -> https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
    epicMiddleware.run(rootEpicLoader)
  } else {
    // We need `ssrHOC` to use for Checkout / Private pages
    store = createStore(ssrHOC(rootReducer), initialState)
  }

  return store
}

// initial state is created in NEXT.JS ( React )
// we need to return a function that can pass in STATE into APP
// we merge everything together to create or `setupStore`
const Store = (params: Params): ((...args: Array<any>) => any) => (
  initialState: Record<string, any> = {}
) => setupStore({ ...params, initialState })

export default Store

//@ts-nocheck
import fetch from 'isomorphic-fetch'
import type { PromisePayload } from 'apis/helpers'
import {
  checkAuthenticatedUserHeaders,
  deleteOptions,
  getOptions,
  parseJSON,
  postOptions,
  putOptions,
} from 'apis/helpers'
export const fetchCart = async (): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/cart`, getOptions)
  const json = await parseJSON(response, 'fetchCart')
  return json
}
export const fetchCartCount = async (): Promise<number> => {
  const response = await fetch(`/easel_api/cart/count`, getOptions)
  const json = await parseJSON(response, 'fetchCartCount')

  if (typeof json.payload === 'number') {
    return json.payload
  }

  return 0
}
export const postCartItem = async (sku: string): Promise<PromisePayload> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      sku,
    }),
  }
  const response = await fetch(`/easel_api/cart/items`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'postCartItem')
  return {
    isAuthenticated,
    ...json,
  }
}
export const deleteCartItem = async (sku: string): Promise<PromisePayload> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      sku,
    }),
  }
  const response = await fetch(`/easel_api/cart/items`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'deleteCartItem')
  return {
    isAuthenticated,
    ...json,
  }
}
export const updateCartItemQuantity = async (payload: {
  sku: string
  quantity: number
}): Promise<PromisePayload> => {
  const { sku, quantity } = payload
  const options = {
    ...putOptions,
    body: JSON.stringify({
      quantity,
    }),
  }
  const response = await fetch(`/easel_api/cart/items/${sku}`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'updateCartItemQuantity')
  return {
    isAuthenticated,
    ...json,
  }
}
export const saveForLaterCartItem = async (sku: string): Promise<PromisePayload> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      sku,
    }),
  }
  const response = await fetch(`/easel_api/cart/items/save-for-later`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'saveForLaterCartItem')
  return {
    isAuthenticated,
    ...json,
  }
}
export const putGiftCardCode = async (giftCard: string): Promise<PromisePayload> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      giftCard,
    }),
  }
  const response = await fetch(`/easel_api/cart/gift-card`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'putGiftCard')
  return {
    isAuthenticated,
    ...json,
  }
}
export const deleteGiftCardCode = async (giftCard: string): Promise<PromisePayload> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      giftCard,
    }),
  }
  const response = await fetch(`/easel_api/cart/gift-card`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'deleteGiftCardCode')
  return {
    isAuthenticated,
    ...json,
  }
}
export const putCouponCode = async (couponCode: string): Promise<PromisePayload> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      couponCode,
    }),
  }
  const response = await fetch(`/easel_api/cart/coupon`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'putCouponCode')
  return {
    isAuthenticated,
    ...json,
  }
}
export const deleteCouponCode = async (couponCode: string): Promise<PromisePayload> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      couponCode,
    }),
  }
  const response = await fetch(`/easel_api/cart/coupon`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'deleteCouponCode')
  return {
    isAuthenticated,
    ...json,
  }
}
export const updateShippingAddress = async (addressBookItemId: string): Promise<PromisePayload> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      addressBookItemId,
    }),
  }
  const response = await fetch(`/easel_api/cart/shipping`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'updateShippingAddress')
  return {
    isAuthenticated,
    ...json,
  }
}
export const updatePaymentMethod = async (paymentMethodId: string): Promise<PromisePayload> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      paymentMethodId,
    }),
  }
  const response = await fetch(`/easel_api/cart/payment`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'updateMaoDraftOfferPaymentMethod')
  return {
    isAuthenticated,
    ...json,
  }
}
export const postCartOrder = async (payload: Record<string, any>): Promise<PromisePayload> => {
  const options = { ...postOptions, body: JSON.stringify(payload) }
  const response = await fetch(`/easel_api/cart/order`, options)
  const isAuthenticated = checkAuthenticatedUserHeaders(response)
  const json = await parseJSON(response, 'postCartOrder')
  return {
    isAuthenticated,
    ...json,
  }
}
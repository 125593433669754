import type { ActionTypes as Action } from 'actions/shared/featureFlags'
import { Constants } from 'actions/shared/featureFlags'
export const defaultState = {}
type State = Record<string, boolean>
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_FEATURE_FLAGS:
      return action.payload || defaultState

    default:
      return state
  }
}
import React from 'react'
import styled from 'styled-components'
import SALink from 'components/SALink'
import { FrameShadow } from 'components/Frame'
import { frameColorDictionary } from 'lib/frame'
import type { FrameColorString } from 'types'
import 'types'
import type { Props as DefaultProps } from './Image'
import { Img, computeRenderDimensions } from './Image'
export const Wrapper = styled(SALink)`
  border-style: solid;
  border-width: 7px;
  border-color: ${(props) => frameColorDictionary[props.frameColor]};
  padding: 10px;
  transition: 0.1s ease border-color;
  will-change: border-color;
  position: relative;

  ${FrameShadow};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    border-width: 8px;
    padding: 8px;
  }
`
type Props = DefaultProps & {
  frameColor?: FrameColorString
  href: string
}
export default function FramedImage(
  props: Props
): React.ReactElement<React.ComponentProps<any>, any> {
  const { src, alt, href, onClick, autoScale, sku, frameColor } = props
  let style = {}

  if (autoScale) {
    const [width, height] = computeRenderDimensions(props)
    style = {
      width,
      height,
    }
  }

  return (
    <Wrapper alt={alt} href={href} onClick={onClick} frameColor={frameColor || 'white'}>
      <Img src={src} data-sku={sku} role='presentation' style={style} />
    </Wrapper>
  )
}
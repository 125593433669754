import React from 'react'
import GlobeOutlined from 'svg/globe-outlined.svg'
import SatisfactionValueProps from 'svg/smile-outlined.svg'
import ArtAdvisoryService from 'svg/art-advisory-service-outlined.svg'

export default [
  {
    icon: <GlobeOutlined />,
    title: 'Global Selection',
    description:
      'Explore an unparalleled selection of paintings, photography, sculpture, and more by thousands of artists from around the world.',
  },
  {
    icon: <SatisfactionValueProps />,
    title: 'Satisfaction Guaranteed',
    description:
      'Our 14-day satisfaction guarantee allows you to buy with confidence. If you’re not satisfied with your purchase, return it and we’ll help you find a work you love.',
  },
  {
    icon: <ArtAdvisoryService />,
    title: 'Complimentary Art Advisory Services',
    description:
      'Our personalized art advisory service gives you access to your own expert curator, free of charge.',
  },
]
// eslint-disable-next-line
import React from 'react' // Needed for styled() Extends

import styled from 'styled-components'
import CaretLeft from '../../../svg/caret-left.svg'
import CaretRight from '../../../svg/caret-right.svg'

// Icons
const IconStyle = (props) => `
height: 40px;
width: auto;
`

export const Icon = styled.svg`
  height: 40px;
  width: auto;
`
export const NextIcon = styled(CaretRight)`
  ${(props) => IconStyle(props)};
`
export const PrevIcon = styled(CaretLeft)`
  ${(props) => IconStyle(props)};
`
// Controls
export const Control = styled.button`
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease opacity;
  will-change: transform, opacity;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;

  :hover {
    transform: translateY(-50%) scale(1.1);
  }

  :focus {
    outline: none;
  }

  opacity: ${(props) => (props.visible === false ? 0 : 1)};
  pointer-events: ${(props) => (props.visible === false ? 'none' : 'auto')};
`
export const Next = styled(Control)`
  right: 0;
  ${NextIcon} {
  }
`
export const Prev = styled(Control)`
  left: 0;
  ${PrevIcon} {
  }
`
// Slider
export const HorizontalContainer = styled.div`
  display: flex;
  height: 100%;
  will-change: transform;
  ${(props) => !props.isDragging && 'transition: 0.2s ease transform'};
`
// Horizontal Scroller
export const List = styled.ul`
  display: block;
  width: 100%;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  list-style: outside none none;
  margin: 0px;
  padding: 0px 3px;
  white-space: nowrap;
  transform: translate3d(0px, 0px, 0px);
  transition: 0.2s ease transform;
  will-change: transform;
  li {
    display: inline;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    list-style: outside none none;
    margin: 0px;
    padding: 0px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  ${(props) => props.mobile && `transform: translateX(0px);`}
  ${(props) => props.mobile && `transform: translateX(0px);`}
`
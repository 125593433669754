import type { ActionTypes as Action } from 'actions/shared/flashMessage'
import { Constants } from 'actions/shared/flashMessage'
import type { FlashMessage } from 'types/Shared/FlashMessage'
import 'types/Shared/FlashMessage'
export const defaultState = {
  messages: [],
  type: 'success',
}
type State = FlashMessage
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.ADD:
      return { ...action.payload, messages: [...state.messages, ...action.payload.messages] }

    case Constants.CLEAR:
      return defaultState

    default:
      return state
  }
}
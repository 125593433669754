import type { ActionTypes as Action } from 'actions/shared/navigation'
import { Constants } from 'actions/shared/navigation'
import type { Navigation } from 'types/Shared/Navigation'
import 'types/Shared/Navigation'
export const defaultState = {
  search: {
    type: 'art',
    value: '',
    custom: false,
  },
}
type State = Navigation
export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.NAVIGATION_UPDATE_SEARCH: {
      const search: Record<string, any> = { ...state.search, ...action.payload }
      return { ...state, search }
    }

    case Constants.NAVIGATION_CUSTOM_SEARCH: {
      const search: Record<string, any> = { ...state.search, custom: action.payload || false }
      return { ...state, search }
    }

    default: {
      return state
    }
  }
}
"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.iosHelp = exports.saatchiart = exports.limited = void 0;
exports.limited = {
    maxWidth: '1256px',
    breakpoints: ['768px', '992px', '1256px'],
    space: [0, 5, 10, 15, 25, 50],
    defaultFontSize: 16,
    fontSizes: [10, 12, 14, 16, 18, 24, 30, 48, 64],
    colors: {
        black: '#000',
        default: '#333',
        secondary: '#666',
        gray: '#666',
        lightGray: '#999',
        linkHover: '#39c',
        grayDark: '#ccc',
        white: '#fff',
        blue: '#39c',
        pink: '#f09',
        gold: '#77c054',
        borderDark: '#ccc',
        borderMedium: '#f0f0f0',
        borderLight: '#eee',
        borderBlue: '#00aeef',
        backgroundGray: '#f9f9f9',
        red: '#ef542c',
        footerBackground: '#333',
        browseBackground: '#fff',
        // Social Medaia Colors
        fb: '#3c5a98',
        twitter: '#41c6f2',
        pinterest: '#f51421',
        tumblr: '#32506a',
        instagram: '#486f96',
        google: '#32506a',
        youtube: '#cd201f',
    },
    fontFamilySerif: 'Playfair Display, serif',
    fontFamilySans: 'Helvetica, sans-serif',
    borderRadius: 4,
    linkSeparator: '/',
};
exports.saatchiart = {
    maxWidth: '1256px',
    breakpoints: ['768px', '992px', '1256px'],
    space: [0, 5, 10, 15, 25, 50],
    defaultFontSize: 16,
    fontSizes: [10, 12, 14, 16, 18, 24, 30, 48, 64],
    colors: {
        // TODO grayDark is lighter then ligtDark, need to refactor Code
        // TODO secondary & gray | borderDark & grayDark are the same, maybe use only one color definition
        black: '#000',
        default: '#333',
        secondary: '#666',
        gray: '#666',
        lightGray: '#999',
        linkHover: '#000',
        grayDark: '#ccc',
        white: '#fff',
        blue: '#39c',
        pink: '#f09',
        green: '#77c054',
        greenDark: '#80bc31',
        borderDark: '#ccc',
        borderMedium: '#f0f0f0',
        borderLight: '#eee',
        borderBlue: '#00aeef',
        backgroundGray: '#f3f3f3',
        backgroundGrayLight: '#f9f9f9',
        backgroundMediumGray: '#dcdcdc',
        red: '#ef542c',
        darkRed: '#E04100',
        darkerRed: '#DC3A0F',
        footerBackground: '#e9e9e9',
        // Change name
        footerBackgroundWhite: '#fff',
        browseBackground: '#fff',
        // Social Medaia Colors
        fb: '#3c5a98',
        twitter: '#41c6f2',
        pinterest: '#f51421',
        tumblr: '#32506a',
        instagram: '#486f96',
        google: '#32506a',
        youtube: '#cd201f',
    },
    fontFamilySerif: 'Crimson Text, serif',
    fontFamilySans: 'Helvetica, sans-serif',
    fontFamilyMonospace: 'Menlo, Bitstream Vera Sans, Monaco, Andale Mono, Lucida Console, Droid Mono, monospace',
    borderRadius: 4,
    linkSeparator: '|',
};
exports.iosHelp = {
    maxWidth: '1256px',
    breakpoints: [],
    space: [-0.3, 0, 0.8, 1.3],
    defaultFontSize: 12,
    fontSizes: [0.8, 1.3],
    colors: {
        default: '#5e5e5e',
        blue: '#56aad5',
    },
    fontFamilySerif: 'Playfair Display, serif',
    fontFamilySans: 'Arial, Helvetica, sans-serif',
    fontFamilyMonospace: 'Menlo, Bitstream Vera Sans, Monaco, Andale Mono, Lucida Console, Droid Mono, monospace',
    borderRadius: 4,
};

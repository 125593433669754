/*
Handles rudimentary carousel functionality...
- next: proceed to next slide
- prev: proceed to previous slide
- index: current slide position
- setIndex: updates index value

Accepts: Array
Returns: Object
*/
import { useState, useEffect } from 'react'
import debounce from 'lib/debounce'
export const useCarousel = (length: number): Record<string, any> => {
  const [index, setIndex] = useState(0)

  const prev = () => {
    let nextIndex = index - 1

    if (nextIndex < 0) {
      nextIndex = length - 1
    }

    setIndex(nextIndex)
  }

  const next = () => {
    let nextIndex = index + 1

    if (nextIndex > length - 1) {
      nextIndex = 0
    }

    setIndex(nextIndex)
  }

  return {
    prev,
    next,
    index,
    setIndex,
  }
}
export const useCarouselSwipe = (
  length: number,
  screenWidth: number,
  carouselName?: string
): Record<string, any> => {
  // Todo: rework state organization to accomodate
  // multiple carousels without redundancy
  const [index, setIndex] = useState(0)
  const [swipeIndex, setSwipeIndex] = useState(0)
  const [navIndex, setNavIndex] = useState(0)
  const [productIndex, setProductIndex] = useState(0)
  const [valPropIndex, setValPropIndex] = useState(0)
  const updateDebounce = debounce()
  useEffect(() => {
    // If SwipeIndex has changed from a NavDot click,
    // update NavIndex to the same value
    if (swipeIndex !== navIndex) {
      setNavIndex(swipeIndex)
    }
  }, [swipeIndex])

  if (typeof window !== 'undefined') {
    // UseEffect to ensure we have a clientside window.document
    const elementArray = [
      ...document.querySelectorAll(
        "[data-name='hero'], [data-section='carousel'], [data-name='productCarousel']"
      ),
    ]

    if (elementArray) {
      elementArray.map((slides) => {
        // Get the carousel items, container and controls
        const carouselItems = [
          ...slides.querySelectorAll("[data-type='carouselItem'], [data-type='slide']"),
        ]

        const updatePagination = (entries, observer) => {
          entries.forEach((entry) => {
            updateDebounce(() => {
              if (entry.isIntersecting) {
                // Identify the carousel items that we're intersecting
                const intersectingItemId = entry.target.getAttribute('data-item')
                  ? parseInt(entry.target.getAttribute('data-item'), 10)
                  : null

                // Style the appropriate nav dot
                if (intersectingItemId !== null) {
                  // Isolate the dot nav active items by using separate state values
                  if (carouselName === 'productCarousel') {
                    setProductIndex(intersectingItemId)
                  } else if (carouselName === 'valuePropsCarousel') {
                    setValPropIndex(intersectingItemId)
                  } else {
                    setNavIndex(intersectingItemId)
                  }
                }
              }
            }, 100)
          })
        }

        const hasIOSupport = typeof IntersectionObserver !== 'undefined'
        if (hasIOSupport) {
          const observerAll = new IntersectionObserver(updatePagination, {
            root: null,
            rootMargin: '0px',
            threshold: 0.8,
          })

          // Observe each carousel item
          carouselItems.map((item) => {
            observerAll.observe(item)
            return null
          })
        }

        return null
      })
    }
  }

  if (screenWidth > 780) {
    const prev = () => {
      let nextIndex = index - 1

      if (nextIndex < 0) {
        nextIndex = length - 1
      }

      setIndex(nextIndex)
    }

    const next = () => {
      let nextIndex = index + 1

      if (nextIndex > length - 1) {
        nextIndex = 0
      }

      setIndex(nextIndex)
    }

    return {
      prev,
      next,
      index,
      setIndex,
    }
  } else {
    const next = 0
    const prev = 0
    return {
      index,
      next,
      prev,
      navIndex,
      productIndex,
      setIndex,
      setSwipeIndex,
      swipeIndex,
      valPropIndex,
    }
  }
}
